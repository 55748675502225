import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {
    SNACKBAR_DURATION,
    SOCKET_STATUS_COLORS,
    SOCKET_STATUSES,
    SOCKET_STATUSES_V201,
    WS_TOPIC
} from "../../../shared/utils/rest-utils.constants";
import {Socket} from "../../../shared/models/socket.model";
import {Subscription} from "rxjs";
import {TransactionService} from "../../../core/services/transaction.service";
import {StationService} from "../../../core/services/station.service";
import {IdTag} from "../../../shared/models/id-tag";
import {MatSnackBar} from "@angular/material/snack-bar";
import {environment} from "../../../../environments/environment";
import * as SockJS from "sockjs-client";
import * as Stomp from "stompjs";
import {MaxCurrent} from "../../../shared/models/max-power";
import {Transaction} from "../../../shared/models/transaction.model";


@Component({
    selector: 'app-socket',
    templateUrl: './socket.component.html',
    styleUrls: ['./socket.component.css', '../../stations-dashboard/stations-log.component.css']
})
export class SocketComponent implements OnInit {

    /**
     * Subscriptions used by this instance
     */
    startNewTransactionSubscription: Subscription;
    stopTransactionSubscription: Subscription;
    getTransactionIdSubscription: Subscription;
    sendAuthorizeRequestSubscription: Subscription;
    getLatestSocketTransactionSubscription: Subscription;

    /**
     * Received from parent
     */
    @Input() stationId: string;
    @Input() stationIdentityKey: string;
    @Input() socket: Socket;
    @Input() idTags: IdTag[];
    @Input() stationAccepted: boolean;
    @Input() stationVersion: string;
    /**
     * Event used to notify the station-page component that the socket status has been changed
     */
    @Output()
    socketStatusChanged = new EventEmitter<Socket>();

    /**
     * Event used to notify the station-page component on which socket a transaction was started/stopped
     */
    @Output()
    startStopTransactionEmitter = new EventEmitter<Socket>();

    /**
     * Used for WS connection
     */
    stompClient: any;
    webSocketEndPoint: string = environment.apiURL + '/ws';

    transactionStarted: boolean = false;
    isStartTransactionButtonDisabled: boolean = false;
    transactionId: number;
    statuses: string[] = [];
    selectedIdTag: string;
    activeSpinner: boolean = false;

    constructor(private transactionService: TransactionService, private stationService: StationService, private snackBar: MatSnackBar) {
    }

    ngOnInit(): void {
        if (this.startNewTransactionSubscription) {
            this.startNewTransactionSubscription.unsubscribe();
        }
        if (this.stopTransactionSubscription) {
            this.stopTransactionSubscription.unsubscribe();
        }
        if (this.getTransactionIdSubscription) {
            this.getTransactionIdSubscription.unsubscribe();
        }
        if (this.sendAuthorizeRequestSubscription) {
            this.sendAuthorizeRequestSubscription.unsubscribe();
        }
        if (this.stationVersion.startsWith('V16'))
            this.statuses = SOCKET_STATUSES;
        else
            this.statuses = SOCKET_STATUSES_V201;

        if (this.socket.status === 'CHARGING' || this.socket.status === 'OCCUPIED') {
            this.getLatestSocketTransactionInfo();
        }

        this._connect();
    }

    getLatestSocketTransactionInfo(): void {
        if (this.getLatestSocketTransactionSubscription) {
            this.getLatestSocketTransactionSubscription.unsubscribe();
        }
        this.getLatestSocketTransactionSubscription = this.transactionService.getLatestSocketTransaction(this.stationIdentityKey, Number(this.socket.connectorId)).subscribe((res: Transaction) => {
            this.transactionId = res.transactionId;
            this.selectedIdTag = res.idTag;
            if (this.stationVersion.startsWith('V16'))
                this.socket.status = 'CHARGING';
            else
                this.socket.status = 'OCCUPIED';
        }, error => {
            this.snackBar.open('Could not load latest transaction!', 'Dismiss', {
                duration: SNACKBAR_DURATION,
                panelClass: ['failure-snackbar']
            });
        });
        this.transactionStarted = true;
        this.isStartTransactionButtonDisabled = false;
    }

    stoppedLatestSocketTransaction(): void {
        this.transactionStarted = false;
        this.isStartTransactionButtonDisabled = false;
        this.socket.status = 'AVAILABLE';
        this.transactionId = 0;
    }

    getStatusColor(): string {
        if (this.socket.status in SOCKET_STATUS_COLORS) {
            return SOCKET_STATUS_COLORS[this.socket.status];
        } else {
            return '#000000';
        }
    }

    changeActiveSpinner(active: boolean): void {
        this.activeSpinner = active;
    }

    public startStopTransaction(): void {
        this.activeSpinner = true;
        if (!this.transactionStarted) {

            if (this.startNewTransactionSubscription) {
                this.startNewTransactionSubscription.unsubscribe();
            }
            this.startNewTransactionSubscription = this.transactionService.startNewTransaction(this.socket.connectorId, this.stationIdentityKey, this.selectedIdTag).subscribe(() => {
                    this.startStopTransactionEmitter.emit(this.socket);
                },
                error => {
                    this.snackBar.open(error.error.message, "Dismiss", {
                        duration: SNACKBAR_DURATION,
                        panelClass: ['failure-snackbar']
                    });
                    this.activeSpinner = false;
                });
        } else {
            if (this.stopTransactionSubscription) {
                this.stopTransactionSubscription.unsubscribe();
            }
            this.stopTransactionSubscription = this.transactionService.stopTransaction(this.transactionId, this.selectedIdTag).subscribe();
            this.startStopTransactionEmitter.emit(this.socket);
        }
    }

    changeStatus(): void {
        this.socketStatusChanged.emit(this.socket);
    }

    _connect() {
        let ws = new SockJS(this.webSocketEndPoint);
        this.stompClient = Stomp.over(ws);
        this.stompClient.debug = null;
        this.stompClient.connect({}, (frame: any) => {
            this.stompClient.subscribe(WS_TOPIC + '/' + this.stationId + "/socket/" + this.socket.id + "/meterIncremented", (sdkEvent: any) => {
                this.handleMeterValueIncremented(sdkEvent);
            });
            this.stompClient.subscribe(WS_TOPIC + '/' + this.stationId + "/socket/" + this.socket.id + "/statusChanged", (sdkEvent: any) => {
                this.handleStatusChanged(sdkEvent);
            });
            this.stompClient.subscribe(WS_TOPIC + '/' + this.stationId + "/socket/" + this.socket.id + "/maxCurrentChanged", (sdkEvent: any) => {
                this.handleMaxCurrentChanged(sdkEvent);
            });
        }, this.errorCallBack);
    }

    handleMeterValueIncremented(message: any) {
        if (message.body !== '') {
            this.socket.meter = message.body;
        }
    }

    handleStatusChanged(message: any) {
        if (message.body !== '') {
            this.socket.status = message.body;
        }
    }

    handleMaxCurrentChanged(message: any) {
        if (message.body !== '') {
            const msg: MaxCurrent = JSON.parse(message.body);
            this.socket.maxCurrent = msg['maxCurrent'];
            this.socket.isCustomMaxCurrentUsed = msg['isCustom'];
        }
    }

    errorCallBack(error: any) {
        console.log("error: " + error);
        setTimeout(() => {
            this._connect();
        }, 5000);
    }

    ngOnDestroy() {
        if (this.getTransactionIdSubscription) {
            this.getTransactionIdSubscription.unsubscribe();
        }
        if (this.startNewTransactionSubscription) {
            this.startNewTransactionSubscription.unsubscribe();
        }
        if (this.getLatestSocketTransactionSubscription) {
            this.getLatestSocketTransactionSubscription.unsubscribe();
        }
        if (this.stopTransactionSubscription) {
            this.stopTransactionSubscription.unsubscribe();
        }
        if (this.sendAuthorizeRequestSubscription) {
            this.sendAuthorizeRequestSubscription.unsubscribe();
        }
    }
}
