<h1 class="dialog-title">{{dialogData.dialogHeader}}</h1>

<div mat-dialog-content>
    <p class="dialog-header">
        {{dialogData.dialogContent}}
    </p>
    <form (ngSubmit)="dialogData.confirmButtonLabel === 'Add' ? createIdTag(): useIdTag()">
        <div>
            <mat-form-field class="id-tag-input">
                <mat-label>Id Tag</mat-label>
                <input type="text"
                       matInput
                       [formControl]="idTag"
                       [matAutocomplete]="auto">
                <mat-autocomplete #auto="matAutocomplete">
                    <mat-option *ngFor="let option of filteredOptions | async" [value]="option.idTag">
                        {{option.idTag}}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
        </div>
        <div class="btn-holder">
            <button mat-button [mat-dialog-close]="false"
                    class="cancel-button">{{dialogData.cancelButtonLabel}}</button>
            <button mat-flat-button [mat-dialog-close]="true" class="action-button" type="submit"
                    [disabled]="!idTag.valid">
                {{dialogData.confirmButtonLabel}}
            </button>
        </div>
    </form>
</div>
