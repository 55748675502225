<div class="socket-components">
    <mat-card class="dashboard-card" [ngStyle]="{'border': 'solid 1px ' + getStatusColor()}">
        <mat-card-header class="dashboard-card-header" [ngStyle]="{'background-color': getStatusColor()}">
            <mat-card-title>
                Socket #{{socket.connectorId}}:
            </mat-card-title>
            <mat-form-field class="example-full-width status-picker">
                <mat-label>Status</mat-label>
                <mat-select [(value)]="socket.status" (selectionChange)="changeStatus()"
                            [disabled]="!stationAccepted">
                    <mat-option *ngFor="let status of statuses" value="{{status}}">
                        {{status}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </mat-card-header>

        <mat-card-content class="dashboard-card-content">

            <form class="example-form select-socket">
                <mat-form-field class="example-full-width" appearance="fill">
                    <mat-label>Select Card</mat-label>
                    <mat-select [(value)]="selectedIdTag">
                        <mat-option *ngFor="let idTag of idTags" [value]="idTag.idTag">
                            {{idTag.idTag}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </form>

            <button mat-flat-button
                    *ngIf="!activeSpinner"
                    [ngClass]="!transactionStarted?'start-transaction-button':'stop-transaction-button'"
                    (click)="startStopTransaction()"
                    [disabled]="!stationAccepted || isStartTransactionButtonDisabled || socket.status === 'UNAVAILABLE'">
                {{!transactionStarted ? 'Start' : 'Stop'}} Transaction
            </button>
            <mat-spinner *ngIf="activeSpinner" [diameter]="30" class="transaction-spinner"></mat-spinner>


            <div class="socket-status">

                <img class="charging-image" src="assets/img/e-mobility.svg">
                <mat-form-field class="meter-value-input" appearance="fill">
                    <mat-label>Meter value</mat-label>
                    <input matInput disabled value="{{socket.meter}} Wh">
                </mat-form-field>

                <br>

                <img class="charging-image" src="assets/img/speedometer.svg">
                <mat-form-field class="meter-value-input"
                                [matTooltip]="socket.isCustomMaxCurrentUsed ? 'This value is only valid for the current transaction!' : ''"
                                [matTooltipPosition]="'after'" appearance="fill">
                    <mat-label>Max power
                        <a class="asterisk" *ngIf="socket.isCustomMaxCurrentUsed"> * </a>
                    </mat-label>
                    <div>
                        <input matInput disabled value="{{socket.maxCurrent}} Amp ">
                    </div>
                </mat-form-field>

            </div>


        </mat-card-content>
    </mat-card>

</div>
