<div>
    <h1 class="dialog-title">Logging out...</h1>
    <div mat-dialog-content class="modal-body">
        <div class="message-body">
            <pre>Your stations are still booted, logging out will not disconnect your stations.
             Do you want your stations to be closed?</pre>
        </div>

    </div>

    <div class="btn-holder">
        <button mat-button mat-dialog-close class="cancel-button" (click)="simpleLogout()">No</button>
        <button mat-button mat-dialog-close class="action-button" (click)="extendedLogout()">Yes</button>
    </div>

</div>
