import {Component, Inject, OnInit} from '@angular/core';
import {FormControl, Validators} from "@angular/forms";
import {Observable, Subscription} from "rxjs";
import {MatSnackBar} from "@angular/material/snack-bar";
import {SNACKBAR_DURATION} from "../../../../shared/utils/rest-utils.constants";
import {IdTag} from "../../../../shared/models/id-tag";
import {UserService} from "../../../../core/services/user.service";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {DialogInterface} from "../../../../shared/interfaces/dialog-interface";
import {map, startWith} from "rxjs/operators";

@Component({
    selector: 'app-new-idtag-dialog',
    templateUrl: './id-tag-dialog.component.html',
    styleUrls: ['./id-tag-dialog.component.css']
})
export class IdTagDialogComponent implements OnInit {

    /**
     * Subscriptions used by this instance
     */
    createIdTagSubscription: Subscription;
    createIdTagRequestPayload: IdTag;
    idTag: FormControl;
    idTags: IdTag[];
    filteredOptions: Observable<IdTag[]>;
    disabledInput: boolean = true;

    constructor(
        private dialogRef: MatDialogRef<IdTagDialogComponent>,
        public dialog: MatDialog,
        public userService: UserService,
        public createIdTagSnackBar: MatSnackBar,
        @Inject(MAT_DIALOG_DATA)
        public dialogData: DialogInterface,
    ) {
        this.createIdTagRequestPayload = {
            id: 0,
            idTag: '',
        }
    }

    ngOnInit(): void {
        this.idTag = new FormControl('', Validators.required);

        this.userService.getCurrentUserIdTags().subscribe(idTagResponse => {
            this.idTags = idTagResponse;
            this.showFilteredIdTags();
        });

    }

    showFilteredIdTags(): void {
        this.filteredOptions = this.idTag.valueChanges.pipe(
            startWith(''),
            map(value => this._filter(value)),
        );
    }

    createIdTag(): void {
        this.createIdTagRequestPayload.idTag = this.idTag.value;

        if (this.createIdTagSubscription) {
            this.createIdTagSubscription.unsubscribe();
        }
        this.createIdTagSubscription = this.userService.createIdTag(this.createIdTagRequestPayload).subscribe({
            next: () => {
                this.createIdTagSnackBar.open('IdTag was successfully created!', 'Dismiss', {
                    duration: SNACKBAR_DURATION,
                    panelClass: ['successful-snackbar']
                });
            },
            error: () => {
                this.createIdTagSnackBar.open('IdTag could not be created!', 'Dismiss', {
                    duration: SNACKBAR_DURATION,
                    panelClass: ['failure-snackbar']
                });
            },
            complete: () => {
                this.dialogRef.close(true);
            }
        })
    }

    useIdTag(): void {
        this.dialogRef.close({idTag: this.idTag.value});
    }

    private _filter(idTag: string): IdTag[] {
        const filterValue = idTag.toLowerCase();
        return this.idTags.filter(option => option.idTag.toLowerCase().includes(filterValue));
    }

    ngOnDestroy(): void {
        if (this.createIdTagSubscription != null) {
            this.createIdTagSubscription.unsubscribe();
        }
    }
}
