import {Component} from '@angular/core';

@Component({
    selector: 'id-tag-table.component',
    styleUrls: ['admin-page.component.css'],
    templateUrl: 'admin-page.component.html',
})
export class AdminPageComponent {

    getPrincipalName(): string | null {
        return localStorage.getItem('principalName');
    }
}
