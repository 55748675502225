import {ChangeDetectionStrategy, Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {DialogInterface} from "../../shared/interfaces/dialog-interface";
import {MatSnackBar} from "@angular/material/snack-bar";
import {StationService} from "../../core/services/station.service";
import {Subscription} from "rxjs";
import {SNACKBAR_DURATION} from "../../shared/utils/rest-utils.constants";
import {UserService} from "../../core/services/user.service";
import {Router} from "@angular/router";

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-confirmation-dialog',
    templateUrl: './confirmation-dialog.component.html',
    styleUrls: ['./confirmation-dialog.component.css']
})
export class ConfirmationDialogComponent implements OnInit {

    /**
     * Subscriptions used by this instance
     */
    deleteStationSubscription: Subscription;
    deleteAllStationsSubscription: Subscription;
    updateStationSubscription: Subscription;
    deleteIdTagSubscription: Subscription;
    deleteUserSubscription: Subscription;
    stationIdentityKey: string | undefined;
    dropLogEventsCollection: boolean = false;

    constructor(
        public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
        @Inject(MAT_DIALOG_DATA)
        public dialogData: DialogInterface,
        public snackBar: MatSnackBar,
        public stationService: StationService,
        public userService: UserService,
        public router: Router
    ) {
    }

    ngOnInit(): void {
        this.stationIdentityKey = this.dialogData.station?.chargingStationIdentityKey;
        if (this.dialogData.dropLogEvents) {
            this.dropLogEventsCollection = this.dialogData.dropLogEvents;
        }
    }

    confirmAction(): void {
        if (this.dialogData.deleteIdTag) {
            this.deleteIdTag();
        } else if (this.dialogData.deleteAllStations) {
            this.deleteAllStations();
        } else if (this.dialogData.deleteStation) {
            this.deleteStation();
        } else if (this.dialogData.updateStation) {
            this.updateStation();
        } else if (this.dialogData.deleteUser) {
            this.deleteUser();
        } else if (this.dialogData.dropLogEvents) {
            this.deleteLogEvents();
        }
    }

    deleteStation(): void {
        if (this.deleteStationSubscription != null) {
            this.deleteStationSubscription.unsubscribe();
        }
        this.deleteStationSubscription = this.stationService.deleteStation(this.stationIdentityKey!).subscribe((response) => {
                this.snackBar.open(response.message, 'OK', {
                    duration: SNACKBAR_DURATION,
                    panelClass: ['successful-snackbar']
                });
            },
            responseError => {
                this.snackBar.open(responseError.error.message ? responseError.error.message : "Failed connecting to the server!", 'Dismiss', {
                    duration: SNACKBAR_DURATION,
                    panelClass: ['failure-snackbar']
                });
            },
            () => {
                setTimeout(() => this.dialogRef.close(true), 500);
            });

    }

    deleteAllStations(): void {
        if (this.deleteAllStationsSubscription) {
            this.deleteAllStationsSubscription.unsubscribe();
        }

        this.deleteAllStationsSubscription = this.stationService.deleteAllStations().subscribe(data => {
                this.snackBar.open('All the stations have been successfully deleted!', 'Dismiss', {
                    duration: SNACKBAR_DURATION,
                    panelClass: ['successful-snackbar']
                });
                this.dialogRef.close(true);
            }, error => {
                this.snackBar.open('Something went wrong!', 'Dismiss', {
                    duration: SNACKBAR_DURATION,
                    panelClass: ['failure-snackbar']
                })
            },
            () => {
                this.dialogRef.close(true);
            });
    }

    deleteIdTag(): void {
        if (this.deleteIdTagSubscription) {
            this.deleteIdTagSubscription.unsubscribe();
        }

        this.deleteIdTagSubscription = this.userService.deleteIdTag(+this.dialogData.idTagId!).subscribe({
            next: (response) => {
                this.snackBar.open(response.message, 'OK', {
                    duration: SNACKBAR_DURATION,
                    panelClass: ['successful-snackbar']
                });
            },
            error: responseError => {
                this.snackBar.open(responseError.error.message ? responseError.error.meaning : "Failed to delete Id Tag", 'Dismiss', {
                    duration: SNACKBAR_DURATION,
                    panelClass: ['failure-snackbar']
                })
            },
            complete: () => {
                this.dialogRef.close(true);
            }
        });
    }

    updateStation(): void {
        if (this.updateStationSubscription != null) {
            this.updateStationSubscription.unsubscribe();
        }

        this.updateStationSubscription = this.stationService.updateStation(this.dialogData.station!).subscribe({
            next: () => {
                this.snackBar.open('Station was successfully updated!', 'Dismiss', {
                    duration: SNACKBAR_DURATION,
                    panelClass: ['successful-snackbar']
                });
            },
            error: () => {
                this.snackBar.open('Something went wrong when updating station!', 'Dismiss', {
                    duration: SNACKBAR_DURATION,
                    panelClass: ['failure-snackbar']
                });
            },
            complete: () => {
                this.dialogRef.close(true);
            }
        });
    }

    deleteLogEvents(): void {
        this.stationService.isDeleteLogEventsRunning = true;
        this.dialogRef.close(true);
    }

    deleteUser(): void {
        if (this.deleteUserSubscription != null) {
            this.deleteUserSubscription.unsubscribe();
        }

        this.deleteUserSubscription = this.userService.deleteUser(this.dialogData.username!).subscribe({
            next: () => {
                this.snackBar.open('User was successfully deleted!', 'Dismiss', {
                    duration: SNACKBAR_DURATION,
                    panelClass: ['successful-snackbar']
                });
            },
            error: () => {
                this.snackBar.open('Failed to delete user!', 'Dismiss', {
                    duration: SNACKBAR_DURATION,
                    panelClass: ['failure-snackbar']
                });
            },
            complete: () => {
                this.dialogRef.close(true);
            }
        });
    }

    closeDialog(): void {
        this.dialogRef.close(false);
    }

    ngOnDestroy(): void {
        if (this.deleteStationSubscription) {
            this.deleteStationSubscription.unsubscribe();
        }
        if (this.deleteIdTagSubscription) {
            this.deleteIdTagSubscription.unsubscribe();
        }
        if (this.deleteAllStationsSubscription) {
            this.deleteAllStationsSubscription.unsubscribe();
        }
        if (this.updateStationSubscription) {
            this.updateStationSubscription.unsubscribe();
        }
        if (this.deleteUserSubscription) {
            this.deleteUserSubscription.unsubscribe();
        }
    }
}
