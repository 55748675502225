import {AfterViewInit, Component, Input, ViewChild} from '@angular/core';
import {Subscription} from "rxjs";
import {MatTableDataSource} from "@angular/material/table";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {MatDialog} from "@angular/material/dialog";
import {Certificate} from "../../../shared/models/certificate";
import {StationService} from "../../../core/services/station.service";


@Component({
    selector: 'app-certificates-tab',
    templateUrl: './certificates-tab.component.html',
    styleUrls: ['./certificates-tab.component.css']
})
export class CertificatesTabComponent implements AfterViewInit {

    @Input() stationId: string;

    /**
     * Subscriptions used by this instance
     */
    getAllCertificatesSubscription: Subscription;

    /**
     * Table settings
     */
    displayedColumns: string[] = ['id', 'certificateType', 'validFrom', 'validTo'];
    dataSource: MatTableDataSource<Certificate>;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;

    constructor(public dialog: MatDialog, private stationService: StationService) {
    }

    ngAfterViewInit() {
        if (this.getAllCertificatesSubscription) {
            this.getAllCertificatesSubscription.unsubscribe();
        }
        this.getAllCertificatesSubscription = this.stationService.getCertificates(this.stationId).subscribe((response: Certificate[]) => {
                this.dataSource = new MatTableDataSource(response);
                this.dataSource.paginator = this.paginator;
                this.dataSource.sort = this.sort;
            }
        );
    }

    applyFilter(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.dataSource.filter = filterValue.trim().toLowerCase();

        if (this.dataSource.paginator) {
            this.dataSource.paginator.firstPage();
        }
    }

    ngOnDestroy() {
        if (this.getAllCertificatesSubscription) {
            this.getAllCertificatesSubscription.unsubscribe();
        }
    }

}
