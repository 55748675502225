import {Component, OnInit, ViewChild} from '@angular/core';
import {MatTable, MatTableDataSource} from "@angular/material/table";
import {Subscription} from "rxjs";
import {MatPaginator} from "@angular/material/paginator";
import {FormControl} from "@angular/forms";
import {MatDialog} from "@angular/material/dialog";
import {MessageDialogInterface} from "../../../../interfaces/message-dialog";
import {MatSort} from "@angular/material/sort";
import {debounceTime} from "rxjs/operators";
import {DataExchangeService} from "../../../../core/services/data-exchange.service";
import {StationService} from "../../../../core/services/station.service";
import {DialogInterface} from "../../../../shared/interfaces/dialog-interface";
import {ConfirmationDialogComponent} from "../../../confirmation-dialog/confirmation-dialog.component";
import {LogEvent} from "../../../../shared/models/log-event";
import {MatSnackBar} from "@angular/material/snack-bar";
import {SNACKBAR_DURATION} from "../../../../shared/utils/rest-utils.constants";
import {OperationMessageComponent} from "../../../../shared/modals/operation-message/operation-message.component";

@Component({
    selector: 'app-monitoring-tab',
    templateUrl: './monitoring-tab.component.html',
    styleUrls: ['./monitoring-tab.component.css']
})

export class MonitoringTabComponent implements OnInit {

    /**
     * Subscriptions used by this instance
     */
    logsSubscription: Subscription;
    getLogEventsPageSubscription: Subscription;
    dropLogEventCollectionSubscription: Subscription;

    /**
     * Table settings
     */
    displayedColumns: string[] = ["responseTimestamp", "chargingStationIdentityKey", "operation", "response"];
    dataSource = new MatTableDataSource<LogEvent>();
    pageIndex: number = 0;
    pageSize: number = 10;
    nextPageExists: boolean;

    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort, {static: true}) sort: MatSort;
    @ViewChild('monitoringTable') table: MatTable<LogEvent>;

    /**
     * Used for filtering LogEvents
     */
    operationFilter = new FormControl();
    requestFilter = new FormControl();
    responseTimestampFilter = new FormControl();
    chargingStationIdentityKeyFilter = new FormControl();

    filteredValues = {responseTimestamp: '', chargingStationIdentityKey: '', operation: '', request: '', response: ''};

    constructor(private dataExchangeService: DataExchangeService, public dialog: MatDialog, private stationService: StationService, public snackBar: MatSnackBar) {
    }

    ngAfterViewInit(): void {
        this.paginator.pageSize = this.pageSize;
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
    }

    ngOnInit(): void {
        this.getLogEventsPage();
        this.listenForReceivedLogEvents();
        this.addColumnFilters();
    }

    addColumnFilters(): void {

        this.responseTimestampFilter.valueChanges
        .pipe(debounceTime(2500))
        .subscribe((responseTimestampValue) => {
            this.filteredValues['responseTimestamp'] = responseTimestampValue;
            this.filterData();
        });

        this.chargingStationIdentityKeyFilter.valueChanges
        .pipe(debounceTime(2500))
        .subscribe((chargingStationIdentityKeyFilterValue) => {
            this.filteredValues['chargingStationIdentityKey'] = chargingStationIdentityKeyFilterValue;
            this.filterData();
        });

        this.operationFilter.valueChanges
        .pipe(debounceTime(2500))
        .subscribe((operationFilterValue) => {
            this.filteredValues['operation'] = operationFilterValue;
            this.filterData();
        });

        this.requestFilter.valueChanges
        .pipe(debounceTime(2500))
        .subscribe((messageFilterValue) => {
            this.filteredValues['request'] = messageFilterValue;
            this.filteredValues['response'] = messageFilterValue;
            this.filterData();
        });
    }

    listenForReceivedLogEvents(): void {
        if (this.logsSubscription) {
            this.logsSubscription.unsubscribe();
        }

        this.logsSubscription = this.dataExchangeService.monitoringLogAnnounced$.subscribe(message => {
            let messageReceived = JSON.parse(message);

            const responseLog: LogEvent = {
                responseTimestamp: messageReceived.responseTimestamp,
                chargingStationIdentityKey: messageReceived.chargingStationIdentityKey,
                operation: messageReceived.operation,
                request: messageReceived.request,
                response: messageReceived.response
            };

            this.dataSource.data.unshift(responseLog);
            this.paginator.length = this.pageSize;
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
            this.nextPageExists = this.dataSource.data.length >= this.pageSize;
            this.table.renderRows();
        });
    }

    openMessageInfoDialog(rowInfo: any, dialogHeader: string): void {
        const messageDialog: MessageDialogInterface = {
            dialogHeader: dialogHeader,
            dialogContent: rowInfo,
            cancelButtonLabel: ''
        };

        this.dialog.open(OperationMessageComponent, {
            width: '400px',
            data: messageDialog
        });
    }

    getLogEventsPage(): void {
        if (this.getLogEventsPageSubscription) {
            this.getLogEventsPageSubscription.unsubscribe();
        }
        this.getLogEventsPageSubscription = this.stationService.getLogEvents(this.pageIndex, this.pageSize).subscribe(pageLogEvent => {
            this.nextPageExists = pageLogEvent.numberOfElements === this.pageSize;
            this.dataSource.data = pageLogEvent.content;
            this.dataSource.sort = this.sort;
            this.table.renderRows();
        });
        this.nextPageExists = this.dataSource.data.length === this.pageSize;
    }

    previousPage() {
        if (this.pageIndex >= 1) {
            this.pageIndex--;
            if (Object.values(this.filteredValues).some(x => x !== '')) {
                this.filterData();
            } else {
                this.getLogEventsPage();
            }
        }
    }

    nextPage() {
        if (this.nextPageExists) {
            this.pageIndex++;
            if (Object.values(this.filteredValues).some(x => x !== '')) {
                this.filterData();
            } else {
                this.getLogEventsPage();
            }
        }
    }


    filterData(): void {

        if (Object.values(this.filteredValues).some(x => x !== '')) {

            const logEventsFilter: LogEvent = {
                responseTimestamp: this.filteredValues["responseTimestamp"],
                chargingStationIdentityKey: this.filteredValues["chargingStationIdentityKey"],
                operation: this.filteredValues["operation"],
                request: this.filteredValues["request"],
                response: this.filteredValues["response"]
            };

            if (this.getLogEventsPageSubscription) {
                this.getLogEventsPageSubscription.unsubscribe();
            }

            this.getLogEventsPageSubscription = this.stationService.getFilteredLogEvents(this.pageIndex, this.pageSize, logEventsFilter).subscribe(pageLogEvent => {
                this.nextPageExists = pageLogEvent.numberOfElements === this.pageSize;
                this.dataSource.data = pageLogEvent.content;
                this.dataSource.sort = this.sort;
                this.table.renderRows();
            })
        } else {
            this.pageIndex = 0;
            this.getLogEventsPage();
        }
    }

    openDeleteLogEventsDialog() {
        const dialogInterface: DialogInterface = {
            dialogHeader: 'Delete monitoring logs',
            dialogContent: 'Are you sure you want to delete all monitoring logs ',
            cancelButtonLabel: 'Cancel',
            confirmButtonLabel: 'Yes',
            idTagId: '',
            dropLogEvents: true
        };
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
            width: '400px',
            data: dialogInterface,
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.pageIndex = 0;
                this.dropLogEvents();
            }
        });
    }

    dropLogEvents(): void {
        if (this.dropLogEventCollectionSubscription) {
            this.dropLogEventCollectionSubscription.unsubscribe();
        }

        this.dropLogEventCollectionSubscription = this.stationService.dropLogEventCollection().subscribe({
            next: (response) => {
                this.snackBar.open(response.message, "Dismiss", {
                    duration: SNACKBAR_DURATION,
                    panelClass: ['successful-snackbar']
                });
            },
            error: () => {
                this.snackBar.open("Failed to delete log events", 'Dismiss', {
                    duration: SNACKBAR_DURATION,
                    panelClass: ['failure-snackbar']
                })
            },
            complete: () => {
                this.stationService.isDeleteLogEventsRunning = false;
                this.getLogEventsPage();
            }
        });
    }

    ngOnDestroy(): void {
        if (this.getLogEventsPageSubscription) {
            this.getLogEventsPageSubscription.unsubscribe();
        }
        if (this.logsSubscription) {
            this.logsSubscription.unsubscribe();
        }
        if (this.dropLogEventCollectionSubscription) {
            this.dropLogEventCollectionSubscription.unsubscribe();
        }
    }

}
