import {Injectable} from "@angular/core";
import {setBaseURL, setEnvironmentUrl} from "../../shared/utils/rest-utils.constants";
import {EnvDev} from "./env.dev";
import {EnvQa} from "./env.qa";
import {EnvProd} from "./env.prod";

export enum Environment {
    Development = 'development',
    Local = 'local',
    Staging = 'staging',
    Prod = 'prod'
}

@Injectable({providedIn: 'root'})
export class EnvService {
    public _env: Environment;
    private _apiUrl: string;
    private _centralSystemUrl: string;
    private _envDev = new EnvDev();
    private _envQa = new EnvQa();
    private _envProd = new EnvProd();

    get env(): Environment {
        return this._env;
    }

    get apiUrl(): string {
        return this._apiUrl;
    }

    get centralSystemUrl(): string {
        return this._centralSystemUrl;
    }

    constructor() {
    }

    init(): Promise<void> {
        return new Promise(resolve => {
            this.setEnvVariables();
            resolve();
            setBaseURL(this._apiUrl + '/api');
        });
    }

    private setEnvVariables(): void {
        const origin = window && window.location && window.location.origin;
        console.log('---------------------------------------------------')
        console.log(origin);
        console.log('---------------------------------------------------')
        //origin = 'https://simulator.qa.cpms.bosch.bosch-emobility.com/'
        // if (/^.*localhost:8082/.test(origin)) {
        //     this._env = Environment.Staging;
        //     this._apiUrl = 'http://localhost:8082';
        //     this._centralSystemUrl = 'ws://host.docker.internal:1006/ocpp/json/1.6/oC676z_uP-OXf-svfD6mpPhiI4EfxpRGduAjzCxY8Zw=';
        //     setEnvironmentUrl(this._apiUrl);
        // } else
        //
        //     if (/^.*localhost.*/.test(origin)) {
        //     this._env = Environment.Local;
        //     this._apiUrl = 'http://localhost:8081';
        //     this._centralSystemUrl = 'ws://localhost:1006/ocpp/json/1.6/oC676z_uP-OXf-svfD6mpPhiI4EfxpRGduAjzCxY8Zw=';
        //     setEnvironmentUrl(this._apiUrl);
        // } else

        if (origin.indexOf(this._envDev.environmentSignature) > -1) {
            console.log('DEV ENVIRONMENT');
            console.log('---------------------------------------------------')
            this._env = Environment.Development;
            this._apiUrl = this._envDev.apiUrl;
            this._centralSystemUrl = this._envDev.centralSystemUrl;
        } else if (origin.indexOf(this._envQa.environmentSignature) > -1) {
            console.log('QA ENVIRONMENT');
            console.log('---------------------------------------------------')
            this._env = Environment.Staging;
            this._apiUrl = this._envQa.apiUrl;
            this._centralSystemUrl = this._envQa.centralSystemUrl;
        } else if (origin.indexOf(this._envProd.environmentSignature) > -1) {
            console.log('PROD ENVIRONMENT');
            console.log('---------------------------------------------------')
            this._env = Environment.Prod;
            this._apiUrl = this._envProd.apiUrl;
            this._centralSystemUrl = this._envProd.centralSystemUrl;
        } else {
            console.warn(`Cannot find environment for ${origin}`);
            console.warn(`Defaulting to local`);
            console.log('---------------------------------------------------')
            this._env = Environment.Local;
            this._apiUrl = 'http://localhost:8081';
            this._centralSystemUrl = 'ws://localhost:1006/ocpp/json/1.6/oC676z_uP-OXf-svfD6mpPhiI4EfxpRGduAjzCxY8Zw=';
            setEnvironmentUrl(this._apiUrl);
        }
    }
}
