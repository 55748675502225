<div class="rf-table">
    <mat-form-field appearance="standard">
        <mat-label>Filter</mat-label>
        <input matInput (keyup)="applyFilter($event)" placeholder="Ex. 0480ABCA704880" #input>
    </mat-form-field>

    <div class="mat-elevation-z8">
        <table mat-table [dataSource]="dataSource" matSort>
            <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> ID</th>
                <td mat-cell *matCellDef="let row"> {{row.id}} </td>

            </ng-container>
            <ng-container matColumnDef="idTag">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Id Tag</th>
                <td mat-cell *matCellDef="let row"> {{row.idTag}} </td>
            </ng-container>

            <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef>Actions</th>
                <td mat-cell *matCellDef="let element" class="action-link">
                    <i matTooltip="Delete Id Tag" (click)="openDeleteIdTagDialog(element.id)"
                       class="fa fa-times delete-icon"></i>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

            <!-- Row shown when there is no matching data. -->
            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
            </tr>
        </table>

        <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"
                       aria-label="Select page of users"></mat-paginator>
    </div>
    <button mat-flat-button class="add-new-rfid-button" (click)="openCreateIdTagDialog()">Add new RFID</button>
</div>
