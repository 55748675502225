<div class="content">
    <div class="page-content">
        <div class="station-header">
            <h1 class="page-title">
                <img src="/assets/img/settings.png" class="gear-icon" alt="">
                Station #{{chargingStationIdentityKey}}
                <button mat-flat-button matTooltip="Send DataTransfer" onclick="this.blur()"
                        class="data-transfer-button" (click)="openDataTransferDialog()"></button>
                <button *ngIf="station.plugAndCharge" mat-icon-button matTooltip="Get Certificate Status" onclick="this.blur()"
                    (click)="getCertificateStatus()">
                    <mat-icon>check_circle</mat-icon>
                </button>
            </h1>

            <div *ngIf="station.protocolVersion != 'V201'" class="calibration-law-ready-container ">
                <i><img class="icon-b" src="../../../assets/img/pnc.png" width="40"/></i>

                <div class="calibration-law-ready">
                    <span class="calibration-law-ready-span">Plug and Charge</span>
                    <mat-slide-toggle class="calibration-law-toggle"
                                      (change)=enablePlugAndCharge($event)
                                      [checked]="plugAndCharge"
                                      [color]="'primary'">
                    </mat-slide-toggle>
                </div>
            </div>

            <div class="calibration-law-ready-container">
                <i><img src="../../../assets/img/security-check.png" width="30"/></i>

                <div class="calibration-law-ready">
                    <span class="calibration-law-ready-span">Calibration Law <br>External Ready</span>
                    <mat-slide-toggle class="calibration-law-toggle"
                                      (change)=changeCalibrationLawReadyForStation($event)
                                      [checked]="isCalibrationLawReady"
                                      [color]="'primary'">
                    </mat-slide-toggle>
                </div>
            </div>
        </div>

        <mat-tab-group>
            <mat-tab label="Info">
                <mat-grid-list class="info-tables-grid" cols="2" rowHeight="360px">
                    <mat-grid-tile>
                        <table class="mat-table info-table">
                            <tr class="mat-row">
                                <td class="mat-cell">Identity key</td>
                                <td class="value">{{station?.chargingStationIdentityKey}}</td>
                            </tr>
                            <tr class="mat-row">
                                <td class="mat-cell ">Charge point model</td>
                                <td class="mat-cell value">{{station?.chargePointModel}}</td>
                            </tr>
                            <tr class="mat-row">
                                <td class="mat-cell">Charge point vendor</td>
                                <td class="mat-cell value">{{station?.chargePointVendor}}</td>
                            </tr>
                            <tr class="mat-row">
                                <td class="mat-cell">Central system URL</td>
                                <td class="value">
                                    <p class="system-url-cell">{{station?.centralSystemUrl}}</p>
                                    <button
                                        (click)="copyToClipboard()"
                                        [cdkCopyToClipboard]="station?.centralSystemUrl ? station.centralSystemUrl : ''"
                                        matTooltip="Copy URL" mat-icon-button class="copy-to-clipboard"
                                        onclick="this.blur()">
                                    </button>
                                </td>
                            </tr>
                            <tr class="mat-row">
                                <td class="mat-cell">OCPP version</td>

                                <td *ngIf="station?.protocolVersion" class="value"> {{ versions_map.get(
                                    station.protocolVersion)}}</td>
                            </tr>
                        </table>
                    </mat-grid-tile>
                    <mat-grid-tile>
                        <table class="mat-table info-table">
                            <tr class="mat-row">
                                <td class="mat-cell">Number of sockets</td>
                                <td class="mat-cell value">{{station?.numberOfSockets}}</td>
                            </tr>
                            <tr class="mat-row">
                                <td class="mat-cell ">Wrong RFID
                                    <mat-icon class="wrong-rfid-icon-info"
                                              matTooltip="When this field is enabled, remote transactions will use a different RFID than the one specified in the request"
                                              matTooltipPosition="after"
                                              matTooltipClass="wrong-rfid-tooltip">
                                        info
                                    </mat-icon>
                                </td>
                                <td class="mat-cell value">{{station?.wrongRFID}}</td>
                            </tr>
                            <tr class="mat-row">
                                <td class="mat-cell">Signed metering values behaviour</td>
                                <td class="mat-cell value">{{station?.signedMeteringValuesType}}</td>
                            </tr>
                            <tr class="mat-row">
                                <td class="mat-cell ">Calibration law ready</td>
                                <td class="mat-cell value">{{station?.calibrationLawReady}}</td>
                            </tr>
                        </table>

                    </mat-grid-tile>
                </mat-grid-list>

                <button mat-flat-button class="delete-button" (click)="openDeleteDialog()">Delete station</button>
                <button mat-flat-button class="edit-button" (click)="openEditDialog()">Edit info</button>
            </mat-tab>

            <mat-tab label="Certificates">
                <app-certificates-tab [stationId]="chargingStationIdentityKey"></app-certificates-tab>
            </mat-tab>
            <mat-tab label="EV Certificates">
                <app-ev-certificates-tab [stationId]="chargingStationIdentityKey"></app-ev-certificates-tab>
            </mat-tab>

            <mat-tab label="Sockets" *ngIf="station">
                <div class="content grid-container">
                    <mat-grid-list rowHeight="250px" cols="2">
                        <mat-grid-tile *ngFor="let socket of station.socketList">
                            <app-socket (startStopTransactionEmitter)="updatedLastUsedSocket($event)" [idTags]="idTags"
                                        [stationIdentityKey]="station.chargingStationIdentityKey"
                                        [stationId]="station.id"
                                        [stationVersion]="station.protocolVersion"
                                        [socket]="socket" [stationAccepted]="station.accepted"
                                        (socketStatusChanged)="onSocketStatusChanged($event)"></app-socket>
                        </mat-grid-tile>
                    </mat-grid-list>
                </div>
            </mat-tab>
        </mat-tab-group>
    </div>
</div>

