import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Observable} from "rxjs";
import {User} from "../../shared/models/user.model";
import {BASE_URL, HTTP_METHOD_HEADERS} from "../../shared/utils/rest-utils.constants";
import {IdTag} from "../../shared/models/id-tag";
import {RegistrationInfo} from "../../shared/models/registration-info";

@Injectable({
    providedIn: 'root'
})
export class UserService {

       constructor(private httpClient: HttpClient) {
    }

    register(registerPayload: RegistrationInfo): Observable<any> {
        return this.httpClient.post(BASE_URL + '/users/register', registerPayload, HTTP_METHOD_HEADERS);
    }

    login(username: string, password: string): Observable<User> {
        const headers = new HttpHeaders({Authorization: 'Basic ' + btoa(username + ':' + password)});
        return this.httpClient.get<User>(BASE_URL + '/users/login', {headers: headers});
    }

    logout(): Observable<any> {
        return this.httpClient.get(BASE_URL + '/logout');
    }

    createIdTag(newIdTagRequestPayload: IdTag): Observable<any> {
        return this.httpClient.post(BASE_URL + '/users/idTag', newIdTagRequestPayload, HTTP_METHOD_HEADERS);
    }

    getCurrentUserIdTags(): Observable<any> {
        return this.httpClient.get(BASE_URL + '/users/idTag', HTTP_METHOD_HEADERS);
    }

    deleteIdTag(idTagId: number): Observable<any> {
        return this.httpClient.delete(BASE_URL + '/users/idTag/' + idTagId, HTTP_METHOD_HEADERS);
    }

    getAllUsers(): Observable<any> {
        return this.httpClient.get(BASE_URL + '/users', HTTP_METHOD_HEADERS);
    }

    deleteUser(username: string): Observable<any> {
        return this.httpClient.delete(BASE_URL + '/users/' + username, HTTP_METHOD_HEADERS);
    }
}


