import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {Station} from "../../shared/models/station.model";
import {AutoPilotTableData} from "../../shared/interfaces/auto-pilot-table-data";

@Injectable({providedIn: 'root'})
export class DataExchangeService {

    private endOfScheduleAnnouncedSource = new Subject<any>();
    private stationChangedAnnouncedSource = new Subject<Station>();
    private monitoringLogAnnouncedSource = new Subject<any>();
    private startAutopilotAnnouncedSource = new Subject<boolean>();
    private stationDeletedAnnouncedSource = new Subject<any>();
    private idTagStatusAnnouncedSource = new Subject<any>();
    private startedMultipleTransactionsAnnouncedSource = new Subject<any>();
    private stationListSizeSource = new Subject<number>();
    private updatedAutoPilotDataSource = new Subject<AutoPilotTableData>();

    stationChangedAnnounced$ = this.stationChangedAnnouncedSource.asObservable();
    endOfScheduleAnnounced$ = this.endOfScheduleAnnouncedSource.asObservable();
    monitoringLogAnnounced$ = this.monitoringLogAnnouncedSource.asObservable();
    startAutopilotAnnounced$ = this.startAutopilotAnnouncedSource.asObservable();
    stationDeletedAnnounced$ = this.stationDeletedAnnouncedSource.asObservable();
    idTagStatusAnnounced$ = this.idTagStatusAnnouncedSource.asObservable();
    startedMultipleTransactionsAnnounced$ = this.startedMultipleTransactionsAnnouncedSource.asObservable();
    stationListSizeAnnounced$ = this.stationListSizeSource.asObservable();
    updatedAutoPilotDataSourceAnnounced$ = this.updatedAutoPilotDataSource.asObservable();

    /**
     * Announces the AutoPilot flow has finished
     *
     * @param {any} message
     */
    sendEndOfScheduleNotification(message: any) {
        this.endOfScheduleAnnouncedSource.next({message});
    }

    sendIdTagStatusNotification(message: any) {
        this.idTagStatusAnnouncedSource.next({message});
    }

    /**
     * Announces a station has changed
     *
     * @param {Station} station
     */
    announceStationChanged(station: Station) {
        this.stationChangedAnnouncedSource.next(station);
    }

    /**
     * Announced a new LogEvent object was received
     *
     * @param {any} log
     */
    announceMonitoringLogReceived(log: any) {
        this.monitoringLogAnnouncedSource.next(log);
    }

    /**
     * Announces a station was deleted
     */
    stationDeleted() {
        this.stationDeletedAnnouncedSource.next();
    }

    /**
     * Announces multiple transactions have been started
     */
    announceStartedMultipleTransactionsStatus(message: any) {
        this.startedMultipleTransactionsAnnouncedSource.next({message});
    }

    /**
     * Announces the AutoPilotTable component that the table's data source has changed
     */
    announceUpdatedAutoPilotDataSource() {
        this.updatedAutoPilotDataSource.next();
    }

}
