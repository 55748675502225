import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {Station} from "../../shared/models/station.model";
import {Observable} from "rxjs";
import {BASE_URL, HTTP_METHOD_HEADERS} from "../../shared/utils/rest-utils.constants";
import {Socket} from "../../shared/models/socket.model";
import {Environment, EnvService} from "./env.service";
import {AutoPilotData} from "../../shared/interfaces/auto-pilot-data";
import {LogEventFilter} from "../../shared/models/log-event-filter";

@Injectable({
    providedIn: 'root'
})
export class StationService {

    private _stationsURL = BASE_URL + '/stations';
    private _defaultStationsURL = BASE_URL + '/stations/defaultStation';
    private _getFirstStationURL = BASE_URL + '/stations/first';
    private _startStationURL = BASE_URL + '/stations/start';
    private _stopStationURL = BASE_URL + '/stations/stop';
    private _autoPilotURL = BASE_URL + '/stations/autoPilot';
    private _logEventsUrl = BASE_URL + '/stations/logEvents';
    private _filteredLogEventsUrl = BASE_URL + '/stations/logEvents/filter';
    private _getCertificatesURL = BASE_URL + '/stations/certificates';
    private _send15118ISOCertificate = BASE_URL + '/stations/evCertificates/sendISO'; //For install
    private _get15118ISOCertificate = BASE_URL + '/stations/evCertificates/getISO'; //For displaying the certificate
    private _get15118ISOCertificates = BASE_URL + '/stations/evCertificates/getISOs'; //For getting all the certificates
    private _put15118ISOCertificate = BASE_URL + '/stations/evCertificates'; //For updating the certificate

    public isDeleteLogEventsRunning: boolean = false;

    constructor(private httpClient: HttpClient, private envService: EnvService) {
    }

    /**
     * Creates a station
     *
     * @param {Station} newStationRequestPayload
     * @returns {Observable<Station>}
     */
    addNewStation(newStationRequestPayload: Station): Observable<Station> {
        newStationRequestPayload = this.replaceCentralSystemUrlForLocalDockerImage(newStationRequestPayload);
        return this.httpClient.post<Station>(this._stationsURL, newStationRequestPayload, HTTP_METHOD_HEADERS);
    }

    /**
     * Creates a given number of stations with predefined values
     *
     * @param {number} numberOfStations - number of default stations to be created
     * @param {Station} defaultStation - payload of the default stations to be created
     * @returns {Observable<Station[]>}
     */
    createDefaultStation(numberOfStations: number, defaultStation: Station): Observable<Station[]> {
        defaultStation = this.replaceCentralSystemUrlForLocalDockerImage(defaultStation);
        let clientInput = new HttpParams().set("numberOfStations", numberOfStations);
        return this.httpClient.post<Station[]>(this._defaultStationsURL, defaultStation, {params: clientInput});
    }

    /**
     * Fetches the station with a given identity key
     *
     * @param {string} identityKey
     * @returns {Observable<Station>}
     */
    getStationByChargingStationIdentityKey(identityKey: string): Observable<Station> {
        return this.httpClient.get<Station>(this._stationsURL + "/" + identityKey, HTTP_METHOD_HEADERS);
    }

    /**
     * Fetches the first station of the current user
     *
     * @returns {Observable<Station>}
     */
    getFirstStation(): Observable<Station> {
        return this.httpClient.get<Station>(this._getFirstStationURL, HTTP_METHOD_HEADERS);
    }

    /**
     * Fetches the list of all stations
     *
     * @returns {Observable<Station[]>}
     */
    getAllStations(): Observable<Station[]> {
        return this.httpClient.get<Station[]>(this._stationsURL, HTTP_METHOD_HEADERS);
    }

    /**
     * Fetches the page at index "number" of size "size" from the list of all stations and applies the specified filters
     *
     * @param {number} page
     * @param {number} size
     * @param {boolean} isSortingAscending
     * @param {string} keyword
     * @param {Station} criteria
     * @returns {Observable<any>}
     */
    getAllPaginatedStations(page: number, size: number, isSortingAscending: boolean, keyword: string, criteria: Station): Observable<any> {
        const params = new HttpParams().set("page", page).append("size", size).append("isSortingAscending", isSortingAscending)
        .append("keyword", keyword);
        return this.httpClient.post(this._stationsURL + "/page", criteria, {params: params});
    }

    /**
     * Updates a station
     *
     * @param {Station} updateStationRequestPayload
     * @returns {Observable<Station>}
     */
    updateStation(updateStationRequestPayload: Station): Observable<Station> {
        updateStationRequestPayload = this.replaceCentralSystemUrlForLocalDockerImage(updateStationRequestPayload);
        return this.httpClient.put<Station>(this._stationsURL, updateStationRequestPayload, HTTP_METHOD_HEADERS);
    }

    /**
     * Deletes a station
     *
     * @param {string} identityKey
     */
    deleteStation(identityKey: string): Observable<any> {
        return this.httpClient.delete(this._stationsURL + "/" + identityKey, HTTP_METHOD_HEADERS);
    }

    /**
     * Deletes the current user's stations
     */
    deleteAllStations(): Observable<any> {
        return this.httpClient.delete(this._stationsURL, HTTP_METHOD_HEADERS);
    }

    /**
     * Sends a boot notification request
     *
     * @param {string} stationIdentityKey
     */
    sendBootNotification(stationIdentityKey: string): Observable<any> {
        return this.httpClient.post(this._stationsURL + '/' + stationIdentityKey + '/boot', HTTP_METHOD_HEADERS)
    }

    /**
     * Turns off a station
     *
     * @param {string} chargingStationIdentityKey
     */
    stopStation(chargingStationIdentityKey: string): Observable<any> {
        return this.httpClient.post(this._stopStationURL + "/" + chargingStationIdentityKey, HTTP_METHOD_HEADERS);
    }

    /**
     * Boots multiple stations
     */
    startAllStations(): Observable<any> {
        return this.httpClient.post(this._startStationURL, HTTP_METHOD_HEADERS);
    }

    /**
     * Turns off all stations
     */
    stopAllStations(): Observable<any> {
        return this.httpClient.post(this._stopStationURL, HTTP_METHOD_HEADERS);
    }

    /**
     * Updates a socket status
     *
     * @param {string} stationIdentityKey
     * @param {number} connectorId
     * @param {string} status
     * @returns {Observable<Socket>}
     */
    changeSocketStatus(stationIdentityKey: string, connectorId: number, status: string): Observable<Socket> {
        let params = new HttpParams().set("status", status);
        return this.httpClient.put<Socket>(this._stationsURL + "/" + stationIdentityKey + "/sockets/" + connectorId, {},
            {params: params});
    }

    /**
     * Request to run multiple given operations
     *
     * @param {AutoPilot[]} autoPilotList
     * @param {number} delay
     */
    runMultipleOperations(autoPilotList: AutoPilotData[], delay: number): Observable<any> {
        let clientInput = new HttpParams().set("delay", delay);
        return this.httpClient.post(this._autoPilotURL, autoPilotList, {params: clientInput});
    }

    /**
     * Fetches a paged list of LogEvent objects
     *
     * @param {number} page
     * @param {number} size
     */
    getLogEvents(page: number, size: number): Observable<any> {
        let params = this.getHttpParams(page, size);
        return this.httpClient.get(this._logEventsUrl, {params: params});
    }

    /**
     * Fetches a paged list of LogEvent objects with given filters
     *
     * @param {number} page
     * @param {number} size
     * @param {LogEventFilter} logEventFilter
     */
    getFilteredLogEvents(page: number, size: number, logEventFilter: LogEventFilter): Observable<any> {
        let params = this.getHttpParams(page, size);
        return this.httpClient.post(this._filteredLogEventsUrl, logEventFilter, {params: params});
    }

    /**
     * Drops entire LogEvent collection
     */
    dropLogEventCollection(): Observable<any> {
        return this.httpClient.delete(this._logEventsUrl, HTTP_METHOD_HEADERS);
    }

    /**
     * Sends a DataTransfer message
     * @param {object} dataTransfer
     * @param {string} stationIdentityKey
     */
    sendDataTransferMessage(dataTransfer: any, stationIdentityKey: string): Observable<any> {
        return this.httpClient.post(this._stationsURL + "/" + stationIdentityKey + "/dataTransfer", dataTransfer);
    }

    /**
     * Fetches all the certificates installed on a given station
     * @param {string} chargingStationIdentityKey
     * @returns {Observable<Certificate[]>}
     */
    getCertificates(chargingStationIdentityKey: string): Observable<any> {
        return this.httpClient.get(this._getCertificatesURL + "/" + chargingStationIdentityKey, HTTP_METHOD_HEADERS);
    }

    /**
     * Request to install the ISO certificate for a station
     *
     * @param {string} stationId
     */
    send15118ISOCertificates(stationId: string): Observable<any> {
        return this.httpClient.get(this._send15118ISOCertificate + "/" + stationId);
    }

    /**
     * Request to get all the ISO certificate ids for a station in order to be displayed in a table
     *
     * @param {string} stationId
     */
    get15118ISOCertificatesIds(stationId: string): Observable<any> {
        return this.httpClient.get(this._get15118ISOCertificates + "/" + stationId);
    }


    /**
     * Request to get the ISO certificate in order to be displayed
     *
     * @param {string} certificateID
     */
    get15118ISOCertificateToDisplay(certificateID: string): Observable<any> {
        return this.httpClient.get(this._get15118ISOCertificate + "/" + certificateID);
    }

    /**
     * Request to update the ISO Certificate
     *
     * @param {string} stationId
     * @param {string} certificateID
     */
    update15118ISOCertificates(stationId: string, certificateID: string): Observable<any> {
        return this.httpClient.put(this._put15118ISOCertificate + "/" + certificateID + "/getISO/" + stationId, {});
    }

    /**
     * Request to create and send a getCertificateStatus message to CPMS
     *
     * @param {string} chargingStationIdentityKey
     */
    getCertificateStatus(chargingStationIdentityKey: string): Observable<any> {
        return this.httpClient.post(this._stationsURL + "/" + chargingStationIdentityKey + "/getCertificateStatus", {});
    }

    getHttpParams(page: number, size: number) {
        return new HttpParams().set("page", page).append("size", size);
    }

    replaceCentralSystemUrlForLocalDockerImage(station: Station): Station {
        if (Environment.Staging === this.envService.env) {
            let to_be_replaced = /localhost/gi;
            station.centralSystemUrl = station.centralSystemUrl.replace(to_be_replaced, 'host.docker.internal');
        }
        return station;
    }
}
