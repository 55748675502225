import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Station} from "../../../shared/models/station.model";
import {StationService} from "../../../core/services/station.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {Subscription} from "rxjs";
import {
    KEYS_FOR_CREATE,
    OCPP_VERSIONS,
    SIGNED_METERING_VALUES_TYPES,
    SNACKBAR_DURATION,
    VERSIONS_MAP
} from "../../../shared/utils/rest-utils.constants";
import {MatDialogRef} from "@angular/material/dialog";
import {MatSlideToggleChange} from "@angular/material/slide-toggle";
import {SecurityProfile} from "../../../shared/models/security.profile";

@Component({
    selector: 'app-new-station-form',
    templateUrl: './new-station-form.component.html',
    styleUrls: ['./new-station-form.component.css']
})
export class NewStationFormComponent implements OnInit {

    /**
     * Subscriptions used by this instance
     */
    addNewStationSubscription: Subscription;

    /**
     * Used for hiding/showing the password
     */
    hide = true;

    newStationForm: FormGroup;
    newStationRequestPayload: Station;
    isCalibrationLawReady = false;
    plugAndCharge = false;
    behaviours: string[] = [];
    selectedBehaviour: string = '';
    versions: string[] = [];
    selectedOcppVersion: string = '';
    versions_map = VERSIONS_MAP;
    versions_map_keys = KEYS_FOR_CREATE;
    password: string = '';

    constructor(public stationService: StationService,
                public dialog: MatDialogRef<NewStationFormComponent>, public snackBar: MatSnackBar) {
        this.newStationRequestPayload = {
            id: '',
            chargingStationIdentityKey: '',
            chargePointModel: '',
            chargePointVendor: '',
            centralSystemUrl: '',
            numberOfSockets: 0,
            pending: false,
            accepted: false,
            wrongRFID: false,
            socketList: [],
            calibrationLawReady: false,
            plugAndCharge: false,
            signedMeteringValuesType: '',
            protocolVersion: '',
            securityProfile: SecurityProfile.NONE,
            securityKey: ''
        }
    }

    ngOnInit(): void {
        this.behaviours = SIGNED_METERING_VALUES_TYPES;
        this.selectedBehaviour = SIGNED_METERING_VALUES_TYPES[0];
        this.versions = OCPP_VERSIONS;
        this.selectedOcppVersion = '';

        this.newStationForm = new FormGroup({
            chargingStationIdentityKey: new FormControl('', Validators.required),
            chargePointModel: new FormControl('', Validators.required),
            chargePointVendor: new FormControl('', Validators.required),
            centralSystemUrl: new FormControl('', Validators.required),
            numberOfSockets: new FormControl('', [Validators.required, Validators.min(1)]),
            wrongRFID: new FormControl(false, Validators.required),
            calibrationLawReady: new FormControl(this.isCalibrationLawReady, Validators.required),
            plugAndCharge: new FormControl(this.plugAndCharge),
            signedMeteringValues: new FormControl({
                value: this.selectedBehaviour,
                disabled: !this.isCalibrationLawReady
            }, Validators.required),
            ocppVersion: new FormControl({value: this.selectedOcppVersion}, Validators.required),
            password: new FormControl('')
        });
        if (!this.hide) {
            // @ts-ignore
            this.newStationForm.get('password').setValidators([
                Validators.required
            ]);
        }
    }

    addNewStation(): void {
        this.newStationRequestPayload.chargingStationIdentityKey = this.newStationForm.get('chargingStationIdentityKey')?.value;
        this.newStationRequestPayload.chargePointModel = this.newStationForm.get('chargePointModel')?.value;
        this.newStationRequestPayload.chargePointVendor = this.newStationForm.get('chargePointVendor')?.value;
        this.newStationRequestPayload.centralSystemUrl = this.newStationForm.get('centralSystemUrl')?.value;
        this.newStationRequestPayload.numberOfSockets = this.newStationForm.get('numberOfSockets')?.value;
        this.newStationRequestPayload.wrongRFID = this.newStationForm.get('wrongRFID')?.value;
        this.newStationRequestPayload.calibrationLawReady = this.newStationForm.get('calibrationLawReady')?.value;
        this.newStationRequestPayload.plugAndCharge = this.newStationForm.get('plugAndCharge')?.value;
        this.newStationRequestPayload.signedMeteringValuesType = this.newStationForm.get('signedMeteringValues')?.value;
        this.newStationRequestPayload.protocolVersion = this.newStationForm.get('ocppVersion')?.value;
        this.newStationRequestPayload.securityKey = this.newStationForm.get('password')?.value;

        if (this.addNewStationSubscription) {
            this.addNewStationSubscription.unsubscribe();
        }
        this.addNewStationSubscription = this.stationService.addNewStation(this.newStationRequestPayload).subscribe(
            {
                next: () => {
                    this.snackBar.open('Station added!', 'Dismiss', {
                        duration: SNACKBAR_DURATION,
                        panelClass: ['successful-snackbar']
                    });
                },
                error: (error) => {
                    this.snackBar.open(error.error.message, 'Dismiss', {
                            duration: SNACKBAR_DURATION,
                            panelClass: ['failure-snackbar']
                        }
                    );
                },
                complete: () => {
                    this.dialog.close(true);
                }
            });
    }

    discardChanges(): void {
        this.dialog.close(false);
    }

    toggleCalibrationLawReady($event: MatSlideToggleChange) {
        this.isCalibrationLawReady = $event.checked;

        let signedMeteringValuesControl = this.newStationForm.get('signedMeteringValues');
        if (!this.isCalibrationLawReady) {
            signedMeteringValuesControl?.disable();
        } else {
            signedMeteringValuesControl?.enable();
        }
    }

    ngOnDestroy(): void {
        if (this.addNewStationSubscription) {
            this.addNewStationSubscription.unsubscribe();
        }
    }
}
