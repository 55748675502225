import {Injectable} from "@angular/core";
import * as SockJS from "sockjs-client";
import * as Stomp from "stompjs";
import {
    FAILURE_NOTIFICATION_TOPIC,
    STATION_STATUS_CHANGED,
    SUCCESSFUL_NOTIFICATION_TOPIC
} from "../../shared/utils/rest-utils.constants";
import {Subject} from "rxjs";
import {environment} from "../../../environments/environment";

@Injectable({
    providedIn: 'root'
})
export class WebsocketService {

    private ws: any;
    private stompClient: any;
    private successfulMessageReceived = new Subject<any>();
    private failureMessageReceived = new Subject<any>();

    webSocketEndPoint: string = environment.apiURL + '/ws';

    successfulMessageReceived$ = this.successfulMessageReceived.asObservable();
    failureMessageReceived$ = this.failureMessageReceived.asObservable();

    constructor() {
        this._connect();
    }

    _connect()
    {
        this.ws = new SockJS(this.webSocketEndPoint);
        this.stompClient = Stomp.over(this.ws);
        this.stompClient.debug = null;
        this.stompClient.connect({}, (frame: any) => {
            this.stompClient.subscribe(SUCCESSFUL_NOTIFICATION_TOPIC, (sdkEvent: any) => {
                this.sendSuccessfulMessage(sdkEvent.body);
            });
            this.stompClient.subscribe(FAILURE_NOTIFICATION_TOPIC, (sdkEvent: any) => {
                this.sendFailureMessage(sdkEvent.body);
            });
            this.stompClient.subscribe(STATION_STATUS_CHANGED, (sdkEvent: any) => {
                this.sendSuccessfulMessage(sdkEvent.body);
            });
        }, this.errorCallBack);
    }

    sendSuccessfulMessage(payload: any) {
        this.successfulMessageReceived.next({payload});
    }

    sendFailureMessage(payload: any){
        this.failureMessageReceived.next({payload});
    }

    // on error, schedule a reconnection attempt
    errorCallBack(error: any): void {
        console.log("error: " + error);
        setTimeout(() => {
            this._connect();
        }, 5000);
    }

}
