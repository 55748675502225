import {Component, ViewChild} from '@angular/core';
import {MatTableDataSource} from "@angular/material/table";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {MatDialog} from "@angular/material/dialog";
import {UserService} from "../../../core/services/user.service";
import {NewUserDialogComponent} from "./new-user-dialog/new-user-dialog.component";
import {User} from "../../../shared/models/user.model";
import {ConfirmationDialogComponent} from "../../confirmation-dialog/confirmation-dialog.component";
import {DialogInterface} from "../../../shared/interfaces/dialog-interface";
import {Subscription} from "rxjs";

@Component({
    selector: 'app-users-tab',
    templateUrl: './users-tab.component.html',
    styleUrls: ['./users-tab.component.css']
})
export class UsersTabComponent {

    /**
     * Subscriptions used by this instance
     */
    getAllUsersSubscription: Subscription;

    /**
     * Table settings
     */
    displayedColumns: string[] = ['id', 'username', 'actions'];
    dataSource: MatTableDataSource<User>;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;

    constructor(public dialog: MatDialog, private userService: UserService) {
    }

    ngAfterViewInit() {
        if (this.getAllUsersSubscription) {
            this.getAllUsersSubscription.unsubscribe();
        }
        this.getAllUsersSubscription = this.userService.getAllUsers().subscribe((response: User[]) => {
                this.dataSource = new MatTableDataSource(response);
                this.dataSource.paginator = this.paginator;
                this.dataSource.sort = this.sort;
            }
        );
    }

    applyFilter(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.dataSource.filter = filterValue.trim().toLowerCase();

        if (this.dataSource.paginator) {
            this.dataSource.paginator.firstPage();
        }
    }

    refresh(): void {
        if (this.getAllUsersSubscription) {
            this.getAllUsersSubscription.unsubscribe();
        }
        this.getAllUsersSubscription = this.userService.getAllUsers().subscribe(
            (response: User[]) => {
                this.dataSource.data = response;
            }
        )
    }

    openNewUserDialog(): void {
        const dialogRef = this.dialog.open(NewUserDialogComponent, {
            width: '500px',
        });

        dialogRef.afterClosed().subscribe(
            (response: any) => {
                if (response) {
                    this.refresh();
                }
            }
        )
    }

    openDeleteUserDialog(username?: string): void {
        const dialogInterface: DialogInterface = {
            dialogHeader: 'Delete User',
            dialogContent: 'Are you sure you want to delete the selected user',
            cancelButtonLabel: 'Cancel',
            confirmButtonLabel: 'Yes',
            username: username ? username : '',
            deleteUser: true,
        };
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
            width: '300px',
            data: dialogInterface,
        });
        dialogRef.afterClosed().subscribe(
            (response: any) => {
                if (response) {
                    this.refresh();
                }
            }
        )
    }

    getLoggedUser(): string | null {
        return localStorage.getItem('principalName');
    }

    ngOnDestroy() {
        if (this.getAllUsersSubscription) {
            this.getAllUsersSubscription.unsubscribe();
        }
    }
}
