import {Component, OnInit} from '@angular/core';
import {Station} from "../../shared/models/station.model";
import {StationService} from "../../core/services/station.service";
import {Subject, Subscription} from "rxjs";
import {MatSnackBar} from "@angular/material/snack-bar";
import {
    CENTRAL_SYSTEM_URL_DEV_V16,
    CENTRAL_SYSTEM_URL_LOCAL_V16,
    KEYS,
    OCPP_VERSIONS,
    OCPP_VERSIONS_FILTER,
    SIGNED_METERING_VALUES_TYPES,
    SNACKBAR_DURATION,
    VERSIONS_MAP
} from "../../shared/utils/rest-utils.constants";
import * as SockJS from "sockjs-client";
import * as Stomp from "stompjs";
import {environment} from "../../../environments/environment";
import {Environment, EnvService} from "../../core/services/env.service";
import {DataExchangeService} from "../../core/services/data-exchange.service";
import {MatDialog} from "@angular/material/dialog";
import {EditDefaultStationComponent} from "./edit-default-station/edit-default-station.component";
import {NewStationFormComponent} from "./new-station-form/new-station-form.component";
import {debounceTime} from "rxjs/operators";
import {SecurityProfile} from "../../shared/models/security.profile";

@Component({
    selector: 'app-stations-log',
    templateUrl: './stations-log.component.html',
    styleUrls: ['./stations-log.component.css']
})
export class StationsLogComponent implements OnInit {

    /**
     * Subscriptions used by this instance
     */
    stationDeletedSubscription: Subscription;
    getAllPaginatedStationsSubscription: Subscription;
    createDefaultStationSubscription: Subscription;
    keywordChangedSubscription: Subscription;
    idTagStatusSubscription: Subscription;

    /**
     * Used for snackbar
     */
    message: string;

    /**
     * Used for default station's fields
     */
    defaultStationPayload: Station;
    numberOfDefaultStations: number;
    defaultIdentityKey: string = "Default";
    defaultModel: string = "demo";
    defaultVendor: string = "demo";
    defaultSystemUrl: string;
    defaultSocketsNumber: number = 2;
    defaultIsWrongRFID: boolean = false;
    defaultIsCalibrationLawReady = false;
    defaultSignedMeteringValue = SIGNED_METERING_VALUES_TYPES[0];
    defaultOcppVersion = OCPP_VERSIONS[0];
    defaultSecurityProfile = SecurityProfile.NONE;
    defaultSecurityKey = "";
    versions_map = VERSIONS_MAP;
    versions_map_keys = KEYS;

    /**
     *  Used for WS connection
     */
    stompClient: any;
    webSocketEndPoint: string = environment.apiURL + '/ws';
    configurationChangeTopic = "/topic/configurationChange";

    /**
     *  Used for pagination and sorting
     */
    displayedItems: number = 0;
    availableItems: number = 0;
    pageSize: number = 4;
    keyword: string = "";
    isMostRecentSorting: boolean = false;
    isExpansionPanelOpened: boolean = false;
    keywordChanged: Subject<string> = new Subject<string>();
    debounceTime = 500;
    ocppVersion = "";
    versions = OCPP_VERSIONS_FILTER;
    criteria: Station;
    filters = [
        {selected: false, propertyName: 'Name', value: '', fieldName: 'chargingStationIdentityKey'},
        {selected: false, propertyName: 'Model', value: '', fieldName: 'chargePointModel'},
        {selected: false, propertyName: 'Vendor', value: '', fieldName: 'chargePointVendor'},
        {selected: false, propertyName: 'Accepted by CPMS', value: false, fieldName: 'accepted'},
    ];

    stations: Station[] = [];
    localEnv: string = Environment.Local;
    showShortSystemURL: boolean = true;

    constructor(private stationService: StationService, public snackBar: MatSnackBar, public envService: EnvService,
                private messageService: DataExchangeService, public dialog: MatDialog) {}

    ngOnInit(): void {
        this.numberOfDefaultStations = 1;
        this.defaultSystemUrl = (this.localEnv === this.envService._env ? CENTRAL_SYSTEM_URL_LOCAL_V16 : CENTRAL_SYSTEM_URL_DEV_V16);
        this.defaultStationPayload = {
            id: '',
            chargingStationIdentityKey: this.defaultIdentityKey,
            chargePointModel: this.defaultModel,
            chargePointVendor: this.defaultVendor,
            centralSystemUrl: this.defaultSystemUrl,
            numberOfSockets: this.defaultSocketsNumber,
            wrongRFID: this.defaultIsWrongRFID,
            accepted: false,
            pending: false,
            socketList: [],
            calibrationLawReady: this.defaultIsCalibrationLawReady,
            signedMeteringValuesType: this.defaultSignedMeteringValue,
            protocolVersion: this.defaultOcppVersion,
            securityProfile: this.defaultSecurityProfile,
            securityKey: this.defaultSecurityKey
        };
        this.ocppVersion = this.versions[4];

        this.initFilters();

        if (this.getAllPaginatedStationsSubscription) {
            this.getAllPaginatedStationsSubscription.unsubscribe();
        }
        this.getAllPaginatedStationsSubscription = this.stationService.getAllPaginatedStations(0, this.pageSize, this.isMostRecentSorting, "", this.criteria).subscribe((data => {
            this.stations = data.content;
            this.displayedItems += Object.keys(data.content).length;
            this.availableItems = data.totalElements;
        }));

        this.stationDeletedSubscription = this.messageService.stationDeletedAnnounced$.subscribe(message => {
            if (this.displayedItems % this.pageSize != 0 || this.displayedItems == this.availableItems) {
                this.displayedItems--;
            }
            this.refreshStations();
        });

        this.keywordChangedSubscription = this.keywordChanged
        .pipe(
            debounceTime(this.debounceTime),
        )
        .subscribe(() => {
            this.updateFilters();
        });

        this._connect();
    }

    refreshStations(): void {
        let currentPage = Math.ceil(this.displayedItems / this.pageSize);
        if (this.displayedItems === 0) {
            this.stations = [];
            this.availableItems = 0;
            currentPage = 1;
        }
        if (this.getAllPaginatedStationsSubscription) {
            this.getAllPaginatedStationsSubscription.unsubscribe();
        }
        this.getAllPaginatedStationsSubscription = this.stationService.getAllPaginatedStations(0, this.pageSize * currentPage, this.isMostRecentSorting, this.keyword, this.criteria)
        .subscribe((data => {
            this.stations = data.content;
            if (this.stations.length > this.displayedItems) {
                this.displayedItems = this.stations.length;
            }
            this.availableItems = data.totalElements;
        }));
    }

    openAddNewStationDialog(): void {
        const dialogRef = this.dialog.open(NewStationFormComponent, {
            width: '800px',
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.refreshStations();
            }
        });
    }

    showIdTagStatus(idTagStatus: any): void {
        this.snackBar.open(idTagStatus.message + " RFID!", "Dismiss", {
            duration: SNACKBAR_DURATION,
            panelClass: idTagStatus.message === "Valid" ? ['successful-snackbar'] : ['failure-snackbar']
        });
    }

    createDefaultStation(): void {
        if (this.createDefaultStationSubscription) {
            this.createDefaultStationSubscription.unsubscribe()
        }

        this.createDefaultStationSubscription = this.stationService.createDefaultStation(this.numberOfDefaultStations, this.defaultStationPayload)
        .subscribe((newStations: Station[]) => {
                this.availableItems += this.numberOfDefaultStations;
                if (this.displayedItems == 0) {
                    this.displayedItems += Math.min(this.numberOfDefaultStations, this.pageSize);
                } else if (this.displayedItems % this.pageSize != 0) {
                    this.displayedItems += Math.min(this.numberOfDefaultStations, (this.pageSize - this.displayedItems % this.pageSize));
                }
                this.refreshStations();
                if (this.numberOfDefaultStations > 1) {
                    this.message = "Default stations successfully created"

                } else {
                    this.message = "Default station successfully created"
                }
                this.snackBar.open(
                    this.message, "Dismiss", {
                        duration: SNACKBAR_DURATION,
                        panelClass: ['successful-snackbar']
                    }
                )
            },
            error => {
                console.log(error);
                this.snackBar.open('Error creating default station', 'Dismiss', {
                    duration: SNACKBAR_DURATION,
                    panelClass: ['failure-snackbar']
                });
            });
    }

    openEditDialog(): void {
        const dialogRef = this.dialog.open(EditDefaultStationComponent, {
            width: '800px',
            data: {data: this.defaultStationPayload}
        });

        dialogRef.afterClosed().subscribe(result => {
            this.defaultStationPayload = result.data;
        });
    }

    loadMoreStations(): void {
        if (this.availableItems > this.displayedItems) {
            const currentPage = Math.floor(this.displayedItems / this.pageSize);
            if (this.getAllPaginatedStationsSubscription) {
                this.getAllPaginatedStationsSubscription.unsubscribe();
            }
            this.getAllPaginatedStationsSubscription = this.stationService.getAllPaginatedStations(currentPage, this.pageSize, this.isMostRecentSorting, "", this.criteria).subscribe((data => {
                this.stations = this.stations.concat(data.content);
                this.displayedItems += data.content.length;
                this.availableItems = data.totalElements;
            }));
        }
    }

    initFilters(): void {
        this.keyword = "";
        this.criteria = {
            id: '',
            chargingStationIdentityKey: "",
            chargePointModel: "",
            chargePointVendor: "",
            centralSystemUrl: "",
            numberOfSockets: 0,
            pending: false,
            wrongRFID: false,
            accepted: false,
            socketList: [],
            calibrationLawReady: false,
            signedMeteringValuesType: SIGNED_METERING_VALUES_TYPES[0],
            protocolVersion: "ALL"
        };
    }

    onFiltersChange(): void {
        this.keywordChanged.next();
    }

    checkboxChange(): void {
        this.updateFilters();
    }

    toggleSortingStrategy(): void {
        this.isMostRecentSorting = !this.isMostRecentSorting;
        this.updateFilters();
    }

    removeFilter(i: number): void {
        this.filters[i].selected = false;
        this.updateFilters();
    }

    removeKeywordFiltering(): void {
        this.keyword = "";
        this.updateFilters();
    }

    updateFilters(): void {
        this.filters[3].value = this.filters[3].selected;
        for (const filter of this.filters) {
            (this.criteria as any)[filter.fieldName] = filter.selected ? filter.value : undefined;

        }
        this.displayedItems = 0;
        this.refreshStations();
    }

    alterSystemURLText() {
        this.showShortSystemURL = !this.showShortSystemURL;
    }

    _connect(): void {
        let ws = new SockJS(this.webSocketEndPoint);
        this.stompClient = Stomp.over(ws);
        this.stompClient.debug = null;
        this.stompClient.connect({}, (frame: any) => {
            this.stompClient.subscribe(this.configurationChangeTopic, (sdkEvent: any) => {
                this.handleMessage(sdkEvent);
            });
        }, this.errorCallBack);
    }

    handleMessage(message: any): void {
        if (message.body !== '') {
            this.snackBar.open(message.body, "Dismiss", {
                duration: SNACKBAR_DURATION,
                panelClass: ['successful-snackbar']
            });
        }
    }

    // on error, schedule a reconnection attempt
    errorCallBack(error: any): void {
        console.log("error: " + error);
        setTimeout(() => {
            this._connect();
        }, 5000);
    }

    ngOnDestroy(): void {
        if (this.getAllPaginatedStationsSubscription) {
            this.getAllPaginatedStationsSubscription.unsubscribe();
        }
        if (this.createDefaultStationSubscription) {
            this.createDefaultStationSubscription.unsubscribe();
        }
        if (this.idTagStatusSubscription) {
            this.idTagStatusSubscription.unsubscribe();
        }
        if (this.stationDeletedSubscription) {
            this.stationDeletedSubscription.unsubscribe();
        }
        if (this.keywordChangedSubscription) {
            this.keywordChangedSubscription.unsubscribe();
        }
        if (this.stompClient !== null && this.stompClient !== undefined && this.stompClient === 'CONNECTED') {
            this.stompClient.disconnect();
        }
    }

    selectOcppVersion() {
        this.criteria.protocolVersion = this.ocppVersion;
        this.updateFilters();
    }

}
