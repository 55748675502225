<div class="certificates-table">
    <div class="certificates-header">
        <mat-form-field appearance="standard">
            <mat-label>Filter</mat-label>
            <input matInput (keyup)="applyFilter($event)" placeholder="Ex. V2GROOTCERTIFICATE" #input>
        </mat-form-field>
    </div>
    <div class="mat-elevation-z8">
        <table mat-table [dataSource]="dataSource" matSort>
            <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Id</th>
                <td mat-cell *matCellDef="let row"> {{row.id}} </td>
            </ng-container>

            <ng-container matColumnDef="certificateType">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Certificate Type</th>
                <td mat-cell *matCellDef="let row"> {{row.certificateType}} </td>
            </ng-container>

            <ng-container matColumnDef="validFrom">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Valid From</th>
                <td mat-cell *matCellDef="let row"> {{row.validFrom}} </td>
            </ng-container>

            <ng-container matColumnDef="validTo">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Valid To</th>
                <td mat-cell *matCellDef="let row"> {{row.validTo}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

            <!-- Row shown when there is no matching data. -->
            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
            </tr>
        </table>

        <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"
                       aria-label="Select page of certificates"></mat-paginator>
    </div>
</div>
