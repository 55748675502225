<div class="content">
    <div class="page-content">
        <h1 class="page-title">
            <img src="/assets/img/security-check.png" class="gear-icon" alt="">
            {{getPrincipalName()}}
        </h1>

        <mat-tab-group mat-align-tabs="start">
            <!-- RfId's tab -->
            <mat-tab label="RfId's">
                <app-id-tag-tab></app-id-tag-tab>
            </mat-tab>

            <!-- Users' tab -->
            <mat-tab label="Users">
                <app-users-tab></app-users-tab>
            </mat-tab>
        </mat-tab-group>
    </div>
</div>
