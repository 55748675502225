<div>
    <h1 class="dialog-title">{{ dialogData.dialogHeader }}</h1>
    <div mat-dialog-content>
        <p>
            {{dialogData.dialogContent}} {{stationIdentityKey}}?
        </p>
    </div>
    <div class="btn-holder" mat-dialog-actions>
        <button mat-button mat-raised-button
                (click)="closeDialog()"
                class="cancel-button">
            {{ dialogData.cancelButtonLabel }}
        </button>
        <button mat-button mat-raised-button
                (click)="confirmAction()"
                class="action-button">
            {{ dialogData.confirmButtonLabel }}
        </button>
    </div>
</div>
