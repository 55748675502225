<div class="monitoring-table">
    <h1 class="page-title">
        Monitoring
    </h1>
    <div class="mat-elevation-z8 monitoring-table-container">

        <table mat-table matSort [dataSource]="dataSource" #monitoringTable
               matSortActive="responseTimestamp" matSortDirection="desc">
            <ng-container matColumnDef="responseTimestamp">
                <th mat-header-cell mat-sort-header="responseTimestamp" *matHeaderCellDef>Timestamp
                    <div>
                        <mat-form-field floatLabel="never">
                            <input matInput class="form-field" [formControl]="responseTimestampFilter"
                                   placeholder="Filter timestamp...">
                        </mat-form-field>
                    </div>
                </th>
                <td mat-cell *matCellDef="let row">{{row.responseTimestamp}}</td>
            </ng-container>

            <ng-container matColumnDef="chargingStationIdentityKey">
                <th mat-header-cell *matHeaderCellDef>Identity key
                    <div>
                        <mat-form-field floatLabel="never">
                            <input matInput class="form-field" [formControl]="chargingStationIdentityKeyFilter"
                                   placeholder="Filter station...">
                        </mat-form-field>
                    </div>
                </th>
                <td mat-cell *matCellDef="let row">{{row.chargingStationIdentityKey}}</td>
            </ng-container>

            <ng-container matColumnDef="operation">
                <th mat-header-cell *matHeaderCellDef>Operation
                    <div>
                        <mat-form-field floatLabel="never">
                            <input matInput class="form-field" [formControl]="operationFilter"
                                   placeholder="Filter operation...">
                        </mat-form-field>
                    </div>
                </th>
                <td mat-cell *matCellDef="let row">{{row.operation}}</td>
            </ng-container>

            <ng-container matColumnDef="response">
                <th mat-header-cell *matHeaderCellDef>Message
                    <div>
                        <mat-form-field floatLabel="never">
                            <input matInput class="form-field" [formControl]="requestFilter"
                                   placeholder="Filter message...">
                        </mat-form-field>
                    </div>
                </th>
                <td mat-cell *matCellDef="let row">
                    <a class="operations" (click)="openMessageInfoDialog(row.request, 'View Request')">Display
                        request</a>
                    <br>
                    <a class="operations" (click)="openMessageInfoDialog(row.response, 'View Response')">Display
                        response</a>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

        <mat-toolbar>
            <mat-toolbar-row>

                <button [disabled]="this.pageIndex === 0" (click)="previousPage()" matTooltip="Previous page"
                        mat-icon-button class="previous-page icon" alt=""></button>
                <button [disabled]="!this.nextPageExists" (click)="nextPage()" matTooltip="Next page"
                        mat-icon-button class="next-page icon" alt=""></button>

                <div class="drop-collection-button">
                    <button mat-icon-button color="primary" [disabled]="dataSource.data.length === 0"
                            (click)="openDeleteLogEventsDialog()"
                            class="drop-collection-button" mat-tooltip="Delete logs">
                        <mat-icon>delete</mat-icon>
                    </button>
                </div>

                <span class="example-spacer"></span>
                <mat-paginator [length]="pageSize"
                               [pageSize]="pageSize"
                               [hidePageSize]="true"
                               [ngStyle]="{display: 'none'}"
                               aria-label="Select page of logs">

                </mat-paginator>
            </mat-toolbar-row>
        </mat-toolbar>

    </div>
</div>
