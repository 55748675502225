<h1 class="dialog-title">Create new user</h1>

<div mat-dialog-content>
    <form [formGroup]="createUserForm">
        <div class="form-container">
            <div>
                <mat-form-field>
                    <mat-label>Username</mat-label>
                    <input [formControlName]="'username'" matInput placeholder="Username">
                </mat-form-field>
            </div>
            <div>
                <mat-form-field>
                    <mat-label>Password</mat-label>
                    <input type="password" [formControlName]="'password'" matInput placeholder="Password">
                </mat-form-field>
            </div>
            <div class="btn-holder">
                <button mat-button [mat-dialog-close]="true" class="cancel-button">Cancel</button>
                <button mat-flat-button class="action-button" type="submit"
                        (click)="createUser()" [disabled]="!createUserForm.valid">
                    Add
                </button>
            </div>
        </div>
    </form>
</div>
