<h1 class="dialog-title">Edit station</h1>

<div mat-dialog-content>
    <form [formGroup]="updateStationForm">
        <mat-grid-list cols="2" rowHeight="80px">
            <mat-grid-tile>
                <mat-form-field appearance="fill">
                    <mat-label>Identity key</mat-label>
                    <input [formControlName]="'chargingStationIdentityKey'"
                           [readonly]="true" matInput placeholder="Identity key" required>
                </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile>
                <mat-form-field appearance="fill">
                    <mat-label>Model</mat-label>
                    <input [formControlName]="'chargePointModel'" matInput
                           placeholder="Model" required>
                </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile>
                <mat-form-field appearance="fill">
                    <mat-label>Vendor</mat-label>
                    <input [formControlName]="'chargePointVendor'" matInput
                           placeholder="Vendor" required>
                </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile>
                <mat-form-field appearance="fill">
                    <mat-label>Number of sockets</mat-label>
                    <input [formControlName]="'numberOfSockets'" matInput
                           type="number" placeholder="Number of sockets" min="0" required>
                </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile>
                <mat-form-field appearance="fill">
                    <mat-label>Ocpp version</mat-label>
                    <mat-select [(value)]="selectedOcppVersion" [formControlName]="'ocppVersion'">
                        <div>
                            <mat-option
                                *ngFor="let version of versions_map_keys" [value]="version">
                                {{versions_map.get(version)}}
                            </mat-option>
                        </div>
                    </mat-select>
                </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile>
                <mat-form-field appearance="fill">
                    <mat-label>Signed metering values</mat-label>
                    <mat-select [(value)]="selectedBehaviour" [formControlName]="'signedMeteringValues'">
                        <div>
                            <mat-option
                                *ngFor="let behaviour of behaviours" [value]="behaviour">
                                {{behaviour}}
                            </mat-option>
                        </div>
                    </mat-select>
                </mat-form-field>
            </mat-grid-tile>

            <mat-grid-tile *ngIf="selectedOcppVersion==='V16TLS'">
                <mat-form-field appearance="fill">
                    <mat-label>Password</mat-label>
                    <input [type]="hide ? 'password' : 'text'" [formControlName]="'password'" matInput
                           placeholder="Password" required>
                    <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                </mat-form-field>
            </mat-grid-tile>

            <mat-grid-tile class="url-grid-tile">
                <mat-form-field class="system-url-field" appearance="fill">
                    <mat-label>Central System URL</mat-label>
                    <textarea [formControlName]="'centralSystemUrl'" matInput
                              cdkTextareaAutosize
                              cdkAutosizeMinRows="2"
                              cdkAutosizeMaxRows="5"
                              placeholder="Central System URL" required></textarea>
                </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile>
                <div class="toggles-tile">
                    <div class="wrong-RFID-div">
                        <mat-slide-toggle [formControlName]="'wrongRFID'" color="primary">Wrong RFID</mat-slide-toggle>
                        <mat-icon class="wrong-rfid-icon-info"
                                  matTooltip="By enabling this field, remote transactions will use a different RFID than the one specified in the request"
                                  matTooltipPosition="after"
                                  matTooltipClass="wrong-rfid-tooltip">
                            info
                        </mat-icon>
                    </div>
                    <mat-slide-toggle [formControlName]="'calibrationLawReady'" color="primary"
                                      [checked]="isCalibrationLawReady"
                                      (change)="toggleCalibrationLawReady($event)">Calibration Law Ready
                    </mat-slide-toggle>
                    <mat-slide-toggle *ngIf="selectedOcppVersion==='V16TLS' || selectedOcppVersion==='V16'"
                                      [formControlName]="'plugAndCharge'" color="primary"
                                      [checked]="plugAndCharge"
                    >Plug and Charge
                    </mat-slide-toggle>
                </div>
            </mat-grid-tile>
        </mat-grid-list>
        <div class="btn-holder">
            <button mat-button mat-dialog-close class="cancel-button" (click)="discardChanges()">Cancel</button>
            <button mat-flat-button class="action-button" type="submit" [disabled]="!updateStationForm.valid"
                    (click)="updateStation()">Save
                changes
            </button>
        </div>
    </form>
</div>
