import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Station} from "../../../shared/models/station.model";
import {MatSlideToggleChange} from "@angular/material/slide-toggle";
import {
    CENTRAL_SYSTEM_URL_DEV_V16,
    CENTRAL_SYSTEM_URL_DEV_V16TLS,
    CENTRAL_SYSTEM_URL_DEV_V201,
    CENTRAL_SYSTEM_URL_LOCAL_V16,
    CENTRAL_SYSTEM_URL_LOCAL_V16TLS,
    CENTRAL_SYSTEM_URL_LOCAL_V201,
    CENTRAL_SYSTEM_URL_LOCAL_V201TLS,
    CENTRAL_SYSTEM_URL_DEV_V201TLS,
    KEYS_FOR_CREATE,
    OCPP_VERSIONS,
    SIGNED_METERING_VALUES_TYPES,
    VERSIONS_MAP
} from "../../../shared/utils/rest-utils.constants";
import {StationService} from "../../../core/services/station.service";
import {SecurityProfile} from "../../../shared/models/security.profile";
import {Environment, EnvService} from "../../../core/services/env.service";


@Component({
    selector: 'app-edit-default-station',
    templateUrl: './edit-default-station.component.html',
    styleUrls: ['./edit-default-station.component.css']
})
export class EditDefaultStationComponent implements OnInit {

    updateStationForm: FormGroup;
    updateStationRequestPayload: Station;
    station: Station;
    isCalibrationLawReady = false;
    plugAndCharge = false;
    behaviours: string[] = [];
    selectedBehaviour: string = '';
    versions: string[] = [];
    selectedOcppVersion: string = 'V16';
    versions_map = VERSIONS_MAP;
    keys_for_create = KEYS_FOR_CREATE;
    centralSystemURL: String = '';

    localEnv: string = Environment.Local;
    isLocalEnv: boolean = true;

    constructor(public dialogRef: MatDialogRef<EditDefaultStationComponent>, public envService: EnvService,
                @Inject(MAT_DIALOG_DATA) public data: any, private stationService: StationService
    ) {
        this.updateStationRequestPayload = {
            id: '',
            chargingStationIdentityKey: '',
            chargePointModel: '',
            chargePointVendor: '',
            centralSystemUrl: '',
            numberOfSockets: 0,
            pending: false,
            accepted: false,
            wrongRFID: false,
            socketList: [],
            calibrationLawReady: false,
            plugAndCharge: false,
            signedMeteringValuesType: '',
            protocolVersion: '',
            securityProfile: SecurityProfile.NONE,
            securityKey: ''
        }
    }

    ngOnInit(): void {
        if (this.localEnv == this.envService._env) {
            this.isLocalEnv = true;
            this.centralSystemURL = CENTRAL_SYSTEM_URL_LOCAL_V16;
        } else {
            this.isLocalEnv = false;
            this.centralSystemURL = CENTRAL_SYSTEM_URL_DEV_V16;
        }

        this.station = this.data['data'];
        this.isCalibrationLawReady = this.station.calibrationLawReady;
        this.behaviours = SIGNED_METERING_VALUES_TYPES;
        this.selectedBehaviour = this.station.signedMeteringValuesType;
        this.versions = OCPP_VERSIONS;
        this.selectedOcppVersion = this.station.protocolVersion;

        this.updateStationForm = new FormGroup({
            chargingStationIdentityKey: new FormControl({
                value: this.station.chargingStationIdentityKey,
                disabled: true
            }),
            chargePointModel: new FormControl(this.station.chargePointModel, Validators.required),
            chargePointVendor: new FormControl(this.station.chargePointVendor, Validators.required),
            centralSystemUrl: new FormControl(this.station.centralSystemUrl, Validators.required),
            numberOfSockets: new FormControl(this.station.numberOfSockets, [Validators.required, Validators.min(1)]),
            wrongRFID: new FormControl(this.station.wrongRFID, Validators.required),
            password: new FormControl(this.station.securityKey),
            calibrationLawReady: new FormControl(this.isCalibrationLawReady, Validators.required),
            plugAndCharge: new FormControl(this.plugAndCharge),
            signedMeteringValues: new FormControl({
                value: this.selectedBehaviour,
                disabled: !this.isCalibrationLawReady
            }, Validators.required),
            ocppVersion: new FormControl(this.selectedOcppVersion, Validators.required)
        })

    }

    saveChanges(): void {
        this.updateStationRequestPayload.chargingStationIdentityKey = this.updateStationForm.get('chargingStationIdentityKey')?.value;
        this.updateStationRequestPayload.chargePointModel = this.updateStationForm.get('chargePointModel')?.value;
        this.updateStationRequestPayload.chargePointVendor = this.updateStationForm.get('chargePointVendor')?.value;
        this.updateStationRequestPayload.centralSystemUrl = this.updateStationForm.get('centralSystemUrl')?.value;
        this.updateStationRequestPayload.numberOfSockets = this.updateStationForm.get('numberOfSockets')?.value;
        this.updateStationRequestPayload.wrongRFID = this.updateStationForm.get('wrongRFID')?.value;
        this.updateStationRequestPayload.calibrationLawReady = this.updateStationForm.get('calibrationLawReady')?.value;
        this.updateStationRequestPayload.plugAndCharge = this.updateStationForm.get('plugAndCharge')?.value;
        this.updateStationRequestPayload.signedMeteringValuesType = this.updateStationForm.get('signedMeteringValues')?.value;
        this.updateStationRequestPayload.protocolVersion = this.updateStationForm.get('ocppVersion')?.value;
        this.updateStationRequestPayload.securityKey = this.updateStationForm.get('password')?.value;
        this.updateStationRequestPayload = this.stationService.replaceCentralSystemUrlForLocalDockerImage(this.updateStationRequestPayload);

        switch (this.updateStationRequestPayload.protocolVersion) {
            case "V16":
            case "V201": {
                this.updateStationRequestPayload.securityProfile = SecurityProfile.NONE.toString();
                break;
            }
            case "V16TLS":
            case "V201TLS": {
                this.updateStationRequestPayload.securityProfile = SecurityProfile.TWO.toString();
                break;
            }
            default: {
                this.updateStationRequestPayload.securityProfile = SecurityProfile.NONE.toString();
                break;
            }
        }

        this.dialogRef.close({data: this.updateStationRequestPayload});
    }

    discardChanges(): void {
        this.dialogRef.close({data: this.station});
    }

    toggleCalibrationLawReady($event: MatSlideToggleChange) {
        this.isCalibrationLawReady = $event.checked;

        let signedMeteringValuesControl = this.updateStationForm.get('signedMeteringValues');
        if (!this.isCalibrationLawReady) {
            signedMeteringValuesControl?.disable();
        } else {
            signedMeteringValuesControl?.enable();
        }
    }

    setCentralSystemURL() {
        if (!this.isLocalEnv) {
            switch (this.selectedOcppVersion) {
                case "V16":
                    this.centralSystemURL = CENTRAL_SYSTEM_URL_DEV_V16;
                    break;
                case "V201":
                    this.centralSystemURL = CENTRAL_SYSTEM_URL_DEV_V201;
                    break;
                case "V16TLS":
                    this.centralSystemURL = CENTRAL_SYSTEM_URL_DEV_V16TLS;
                    break;
                case "V201TLS":
                    this.centralSystemURL = CENTRAL_SYSTEM_URL_DEV_V201TLS;
                    break;
                default:
                    this.centralSystemURL = CENTRAL_SYSTEM_URL_DEV_V16;
                    break;
            }
        } else {
            switch (this.selectedOcppVersion) {
                case "V16":
                    this.centralSystemURL = CENTRAL_SYSTEM_URL_LOCAL_V16;
                    break;
                case "V201":
                    this.centralSystemURL = CENTRAL_SYSTEM_URL_LOCAL_V201;
                    break;
                case "V16TLS":
                    this.centralSystemURL = CENTRAL_SYSTEM_URL_LOCAL_V16TLS;
                    break;
                case "V201TLS":
                    this.centralSystemURL = CENTRAL_SYSTEM_URL_LOCAL_V201TLS;
                    break;
                default:
                    this.centralSystemURL = CENTRAL_SYSTEM_URL_LOCAL_V16;
                    break;
            }
        }
    }
}

