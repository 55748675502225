import {ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {
    AUTOPILOT_OPERATIONS_V16,
    AUTOPILOT_OPERATIONS_V201,
    AUTOPILOT_OPERATIONS_V16TLS,
    KEYS_FOR_CREATE,
    SNACKBAR_DURATION,
    SOCKET_STATUSES,
    VERSIONS_MAP
} from "../../../shared/utils/rest-utils.constants";
import {Station} from "../../../shared/models/station.model";
import {IdTag} from "../../../shared/models/id-tag";
import {UserService} from "../../../core/services/user.service";
import {MatTable, MatTableDataSource} from "@angular/material/table";
import {Subscription} from "rxjs";
import {StationService} from "../../../core/services/station.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {FormControl, FormGroup, ValidationErrors, Validators} from "@angular/forms";
import {DataExchangeService} from "../../../core/services/data-exchange.service";
import {StationStoreService} from "../../../core/services/station-store.service";
import {AutoPilotTableData} from "../../../shared/interfaces/auto-pilot-table-data";

export interface AutoPilot {
    operationName: string;
    position: number;
    socket?: number;
    stationIdentityKey: string;
}

@Component({
    selector: 'app-auto-pilot-main',
    templateUrl: './auto-pilot-page.component.html',
    styleUrls: ['./auto-pilot-page.component.css']
})
export class AutoPilotPageComponent implements OnInit {

    /**
     * Subscriptions used by this instance
     */
    getAllStationsSubscription: Subscription;
    endOfScheduleSubscription: Subscription;

    /**
     * Table settings
     */
    displayedColumns: string[] = ['position', 'operationName', 'station', 'socket', 'actions'];
    dataSource = new MatTableDataSource<AutoPilotTableData>();
    @ViewChild('autopilot') table: MatTable<AutoPilotTableData>;

    /**
     * Used for displaying error message
     */
    errorMessage = '';
    showErrorMessage = false;

    /**
     * Used for autopilot flow
     */
    firstStation: Station;
    stations: Station[] = [];

    /**
     * Fields used for MatDrawer containing the new operation
     */
    is_table_being_updated: boolean = false;
    is_new_row_being_added: boolean = false;
    rowOperation: AutoPilotTableData;
    rowOperationForm: FormGroup;
    operations: string[] = AUTOPILOT_OPERATIONS_V16;
    idTags: IdTag[] = [];
    statuses: string[] = [];

    /**
     *  Protocol version for the autopilot operations
     */
    versions_map = VERSIONS_MAP;
    versions_map_keys = KEYS_FOR_CREATE;
    selectedOcppVersion: string = 'V16';
    stationsV16: Station[] = [];
    stationsV201: Station[] = [];
    stationsV16TLS: Station[] = [];
    stationsV201TLS: Station[] = [];

    /**
     * Used for adding a new operation to AutoPilot table
     */
    selectedOperation: string;
    selectedStation: Station;
    delay: FormControl;
    selectedSocket: number | undefined;
    maxIndex: number;
    setTimeoutInterval: any;
    isClearButtonDisabled: boolean = false;
    iScheduleButtonDisabled: boolean = false;

    constructor(private userService: UserService, private stationService: StationService,
                private messageService: DataExchangeService, private snackBar: MatSnackBar,
                private readonly changeDetectorRef: ChangeDetectorRef, private stationStore: StationStoreService) {
    }

    ngAfterViewChecked(): void {
        this.changeDetectorRef.detectChanges();
    }

    ngOnInit(): void {
        this.statuses = SOCKET_STATUSES;
        this.userService.getCurrentUserIdTags().subscribe(
            (response: IdTag[]) => {
                this.idTags = response;
            }
        );

        this.dataSource.data = JSON.parse(<string>localStorage.getItem("dataSource"));
        this.delay = new FormControl(10, [Validators.required, Validators.min(5), Validators.max(99)]);
        this.iScheduleButtonDisabled = JSON.parse(<string>sessionStorage.getItem("scheduleButtonDisabled"));
        this.getAllStations();
        this.setMaxOperationIndex();

        this.clearRowOperation();
        this.rowOperationForm = new FormGroup({
            position: new FormControl(this.rowOperation.position),
            operationName: new FormControl(this.rowOperation.operationName, [Validators.required]),
            station: new FormControl(this.rowOperation.station, [Validators.required, this.controlShouldNotBeEmpty]),
            socket: new FormControl(this.rowOperation.socket),
            idTag: new FormControl(this.rowOperation.idTag),
            status: new FormControl(this.rowOperation.status),
        });

        this.handleReceivedEndOfScheduleNotification();

        if (!this.dataSource.data || this.dataSource.data.length === 0) {
            this.setScheduleButtonDisabled(true);
            this.isClearButtonDisabled = true;
            this.dataSource.data = [];
        }
    }

    getAllStations(){
        if (this.getAllStationsSubscription) {
            this.getAllStationsSubscription.unsubscribe();
        }
        this.getAllStationsSubscription = this.stationStore.stationList$.subscribe(data => {
            this.stations = data;
            this.filterAutoPilotTableDataSource();
            this.setMaxOperationIndex();
            this.setScheduleButtonBasedOnData();
            this.filterStations();
        });
    }

    filterStations() {
        this.stationsV16 = this.stations.filter(station => station.protocolVersion == "V16");
        this.stationsV201 = this.stations.filter(station => station.protocolVersion == "V201");
        this.stationsV16TLS = this.stations.filter(station => station.protocolVersion == "V16TLS");
        this.stationsV201TLS = this.stations.filter(status => status.protocolVersion == "V201TLS");

    }

    handleReceivedEndOfScheduleNotification(): void {
        if (this.endOfScheduleSubscription) {
            this.endOfScheduleSubscription.unsubscribe();
        }

        this.endOfScheduleSubscription = this.messageService.endOfScheduleAnnounced$.subscribe(message => {
            if (message.message === "Success") {
                if (this.stations.length) {
                    this.setScheduleButtonDisabled(false);
                } else {
                    this.setScheduleButtonDisabled(true);
                }
                this.snackBar.open("Schedule has ended!", "Dismiss", {
                    duration: SNACKBAR_DURATION,
                    panelClass: ['successful-snackbar']
                });
            } else if (message.message === "Failure") {
                this.showCustomErrorMessage("An error occurred during schedule!");
            }
        });
        this.addNewOperationValidators();
    }

    filterAutoPilotTableDataSource(): void {
        this.dataSource.data = JSON.parse(<string>localStorage.getItem("dataSource"));

        //keep operations that use existing chargingStationsIdentityKeys that exists in stationsList
        if (this.dataSource.data && this.stations) {
            this.dataSource.data = this.dataSource.data.filter(o1 => this.stations.some(o2 => o1.station!.chargingStationIdentityKey === o2.chargingStationIdentityKey));
            localStorage.setItem('dataSource', JSON.stringify(this.dataSource.data));
        }
    }

    setScheduleButtonBasedOnData(): void {
        if (!this.dataSource.data || this.dataSource.data.length === 0) {
            this.setScheduleButtonDisabled(true);
        }
    }

    normalFlow(): void {
        if (this.stations.length) {
            if (this.iScheduleButtonDisabled && this.dataSource.data && this.dataSource.data.length) {
                this.showCustomErrorMessage('Ongoing autopilot flow');
            } else {
                switch (this.selectedOcppVersion) {
                    case "V16":
                        this.firstStation = this.stationsV16[0];
                        break;
                    case "V16TLS":
                        this.firstStation = this.stationsV16TLS[0];
                        break;
                    case "V201":
                        this.firstStation = this.stationsV201[0];
                        break;
                    case "V201TLS":
                        this.firstStation = this.stationsV201TLS[0];
                        break;
                    default:
                        break;
                }
                this.setTableData();
                this.setScheduleButtonDisabled(false);
                this.isClearButtonDisabled = false;
            }
        } else {
            this.setScheduleButtonDisabled(true);
            this.showCustomErrorMessage('No stations available');
        }
    }

    randomFlow(): void {
        if (this.stations.length) {
            if (this.iScheduleButtonDisabled && this.dataSource.data && this.dataSource.data.length) {
                this.showCustomErrorMessage('Ongoing autopilot flow');
            } else {
                const OPERATIONS = [];
                for (let i = 1; i <= 8; i++) {
                    let newOperation = this.generateRandomOperation(i);
                    OPERATIONS.push(newOperation);
                }

                this.maxIndex = 8;
                this.dataSource.data = OPERATIONS;
                localStorage.setItem('dataSource', JSON.stringify(this.dataSource.data));
                this.setScheduleButtonDisabled(false);
                this.isClearButtonDisabled = false;
            }
        } else {
            this.setScheduleButtonDisabled(true);
            this.showCustomErrorMessage('No stations available');
        }
    }

    generateRandomOperation(index: number): AutoPilotTableData {
        const operationIndex = this.getRandomInt(4);
        const operation = this.operations[operationIndex];

        let stationIndex;
        let station: Station;

        /**
         *  Generate a random flow regarding the selected protocol
         */
        switch (this.selectedOcppVersion) {
            case "V16":
                stationIndex = this.getRandomInt(this.stationsV16.length);
                station = this.stationsV16[stationIndex];
                break;
            case "V16TLS":
                stationIndex = this.getRandomInt(this.stationsV16TLS.length);
                station = this.stationsV16TLS[stationIndex];
                break;
            case "V201":
                stationIndex = this.getRandomInt(this.stationsV201.length);
                station = this.stationsV201[stationIndex];
                break;
            case "V201TLS":
                stationIndex = this.getRandomInt(this.stationsV201TLS.length);
                station = this.stationsV201TLS[stationIndex];
                break;
            default:
                stationIndex = this.getRandomInt(this.stationsV16.length);
                station = this.stationsV16[stationIndex];
                break;
        }

        //choose a socket only for operations other than BootNotification
        let socketIndex;
        let socket = undefined;
        if (operationIndex !== 0) {
            socketIndex = this.getRandomInt(station.socketList.length);
            socket = station.socketList[socketIndex].connectorId;
        }

        return {
            position: index,
            operationName: operation,
            station: station,
            socket: socket,
        };
    }

    setTableData() {
        this.dataSource.data = [
            {
                position: 1,
                operationName: 'BootNotification',
                station: this.firstStation
            },
            {
                position: 2,
                operationName: 'StatusNotification',
                station: this.firstStation,
                socket: 1
            },
            {
                position: 3,
                operationName: 'StartTransaction',
                station: this.firstStation,
                socket: 1
            },
            {
                position: 4,
                operationName: 'StopTransaction',
                station: this.firstStation,
                socket: 1
            },
            {
                position: 5,
                operationName: 'BootNotification',
                station: this.firstStation,
            },
            {
                position: 6,
                operationName: 'StatusNotification',
                station: this.firstStation,
                socket: 2
            },
            {
                position: 7,
                operationName: 'StartTransaction',
                station: this.firstStation,
                socket: 2
            },
            {
                position: 8,
                operationName: 'StopTransaction',
                station: this.firstStation,
                socket: 2
            },
        ];
        this.maxIndex = 8;
        localStorage.setItem('dataSource', JSON.stringify(this.dataSource.data));
    }

    clearOperations(): void {
        if (this.iScheduleButtonDisabled && this.dataSource.data && this.dataSource.data.length) {
            this.showCustomErrorMessage('Ongoing autopilot flow');
        } else {
            this.dataSource.data = [];
            localStorage.removeItem('dataSource');
            this.maxIndex = 0;
            this.clearRowOperation();
            this.clearRowOperationForm();
            this.setScheduleButtonDisabled(true);
            this.isClearButtonDisabled = true;

        }
    }

    addNewOperation(): void {
        const newOperation: AutoPilotTableData = {
            position: ++this.maxIndex,
            operationName: this.selectedOperation,
            station: this.selectedStation,
            socket: this.selectedSocket
        };

        if (this.selectedOperation == this.operations[0]) {
            newOperation.socket = undefined;
        }

        if (this.dataSource.data === null || this.dataSource.data.length === 0) {
            this.setScheduleButtonDisabled(false);
            this.dataSource.data = [];
            this.isClearButtonDisabled = false;
        }

        this.dataSource.data.push(newOperation);
        this.table.renderRows();
        localStorage.setItem('dataSource', JSON.stringify(this.dataSource.data));
    }


    runMultipleOperations(): void {
        this.snackBar.open('Schedule started!', 'Dismiss', {
            duration: SNACKBAR_DURATION,
            panelClass: ['info-snackbar']
        });

        this.setScheduleButtonDisabled(true);
        let autoPilotOperations = this.dataSource.data.map((operation) => {
            return ({
                position: operation.position,
                operationName: operation.operationName,
                socket: operation.socket,
                stationIdentityKey: operation.station!.chargingStationIdentityKey,
                status: operation.status || '',
                idTag: operation.idTag?.idTag || ''
            });
        });
        this.stationService.runMultipleOperations(autoPilotOperations, this.delay.value).subscribe();
    }

    disableClearOperationButton(): void {
        this.isClearButtonDisabled = true;
    }

    getScheduleButtonDisabled() {
        return JSON.parse(<string>sessionStorage.getItem("scheduleButtonDisabled"));
    }

    setScheduleButtonDisabled(disabled: boolean) {
        this.iScheduleButtonDisabled = disabled;
        sessionStorage.setItem("scheduleButtonDisabled", JSON.stringify(disabled));
    }

    controlShouldNotBeEmpty(control: FormControl): ValidationErrors | null {
        if (control.value === {}) {
            return {emptyControl: true}
        }
        return null;
    }

    deletedRow(): void {
        if (!this.dataSource.data || this.dataSource.data.length === 0) {
            this.dataSource.data = [];
            this.setScheduleButtonDisabled(true);
        }
        this.setMaxOperationIndex();
        this.messageService.announceUpdatedAutoPilotDataSource();
        localStorage.setItem('dataSource', JSON.stringify(this.dataSource.data));
    }

    addNewOperationValidators(): void {
        this.rowOperationForm.get('operationName')?.valueChanges.subscribe(value => {
            if (value !== 'BootNotification') {
                this.rowOperationForm.get('socket')?.setValue(0);
                this.rowOperationForm.get('socket')?.setValidators(Validators.min(1));
            } else {
                this.rowOperationForm.get('socket')?.setValue(null);
                this.rowOperationForm.get('socket')?.clearValidators();
            }
        });
        this.rowOperationForm.get('station')?.valueChanges.subscribe(value => {
            if (this.rowOperationForm.get('operationName')?.value !== 'BootNotification') {
                this.rowOperationForm.get('socket')?.setValue(0);
            }
        });
    }

    updateTableRow(newTableRow: AutoPilotTableData): void {
        let element = this.dataSource.data.find(row =>
            row.position === newTableRow.position
        )!;
        const index: number = this.dataSource.data.indexOf(element);
        this.dataSource.data[index] = newTableRow;
        localStorage.setItem('dataSource', JSON.stringify(this.dataSource.data));
    }

    setMaxOperationIndex(): void {
        if (!this.dataSource.data) {
            this.maxIndex = 0;
        } else {
            this.maxIndex = Math.max.apply(Math, this.dataSource.data.map(function (o) {
                return o.position;
            }));
            if (this.maxIndex === -Infinity) {
                this.maxIndex = 0;
            }
        }
    }


    dismissUpdateStation(): void {
        this.is_table_being_updated = false;
        this.is_new_row_being_added = false;

        this.clearRowOperation();
        this.clearRowOperationForm();
    }

    createRowOperation(position: number): AutoPilotTableData {
        this.rowOperation.position = position;
        this.rowOperation.operationName = this.rowOperationForm.get('operationName')?.value;
        this.rowOperation.station = this.rowOperationForm.get('station')?.value;
        this.rowOperation.socket = this.rowOperationForm.get('socket')?.value;
        this.rowOperation.idTag = this.rowOperationForm.get('idTag')?.value;
        this.rowOperation.status = this.rowOperationForm.get('status')?.value;

        return this.rowOperation;
    }

    saveOperation(): void {
        let operation = this.createRowOperation(this.maxIndex + 1);
        if (!this.dataSource.data) {
            this.dataSource.data = [];
        }
        this.dataSource.data.push(operation);
        this.messageService.announceUpdatedAutoPilotDataSource();
        localStorage.setItem('dataSource', JSON.stringify(this.dataSource.data));
        this.setScheduleButtonDisabled(false);

        this.is_table_being_updated = false;
        this.is_new_row_being_added = false;
        this.isClearButtonDisabled = false;

        this.clearRowOperation();
        this.clearRowOperationForm();
    }

    updateOperation(): void {
        let element = this.dataSource.data.find(row =>
            row.position === this.rowOperationForm.get('position')?.value
        )!;

        const index: number = this.dataSource.data.indexOf(element);
        this.dataSource.data[index] = this.createRowOperation(element.position);

        this.messageService.announceUpdatedAutoPilotDataSource();
        localStorage.setItem('dataSource', JSON.stringify(this.dataSource.data));
        this.is_table_being_updated = false;
        this.is_new_row_being_added = false;

        this.clearRowOperation();
        this.clearRowOperationForm();
    }

    onAddNewRowOperation($event: any): void {
        let newOperation = {
            position: $event.position,
            operationName: $event.operationName,
            station: $event.station,
            socket: $event.socket ? $event.socket : null,
        };

        if (!this.dataSource.data || this.dataSource.data.length === 0) {
            this.dataSource.data = [];
        }

        this.dataSource.data.push(newOperation);
        this.messageService.announceUpdatedAutoPilotDataSource();
        localStorage.setItem('dataSource', JSON.stringify(this.dataSource.data));
        this.setScheduleButtonDisabled(false);
        this.setMaxOperationIndex();
        this.is_table_being_updated = false;
        this.is_new_row_being_added = false;
        this.isClearButtonDisabled = false;
    }

    onEditRowOperation($event: any): void {
        this.is_table_being_updated = true;
        this.rowOperationForm.setValue({
            position: $event.position,
            operationName: $event.operationName,
            station: $event.station,
            socket: $event.socket ? $event.socket : null,
            idTag: $event.idTag ? $event.idTag : null,
            status: $event.status ? $event.status : null,
        });
    }

    clearRowOperationForm(): void {
        this.rowOperationForm.setValue({
            position: this.rowOperation.position,
            operationName: this.rowOperation.operationName,
            station: this.rowOperation.station,
            socket: this.rowOperation.socket,
            idTag: this.rowOperation.idTag,
            status: this.rowOperation.status,
        });
    }

    clearRowOperation(): void {
        this.setMaxOperationIndex();
        this.rowOperation = {
            position: this.maxIndex + 1,
            operationName: '',
            station: null!,
            socket: null!,
            idTag: null!,
            status: null!
        }
    }

    closeInformativeMessage(): void {
        this.showErrorMessage = false;
        this.errorMessage = '';
    }

    addOperation(): void {
        this.is_table_being_updated = true;
        this.is_new_row_being_added = true;
        this.setMaxOperationIndex();
    }

    showCustomErrorMessage(message: string) {
        this.showErrorMessage = true;
        this.errorMessage = message;

        if (this.setTimeoutInterval) {
            clearTimeout(this.setTimeoutInterval);
        }

        this.setTimeoutInterval = setTimeout(() => {
            this.errorMessage = '';
            this.showErrorMessage = false;
        }, SNACKBAR_DURATION);
    }

    clearSelectedSocket(): void {
        this.selectedSocket = undefined;
    }

    getRandomInt(max: number): number {
        return Math.floor(Math.random() * max);
    }

    keyUp(event: any): boolean {
        if (event.target.value > 99) {
            let length = event.target.value.length;
            event.target.value = event.target.value.slice(0, length - 1);
            return false;
        }
        return true;
    }

    onChange(element: any, $event: any): void {
        const index: number = this.dataSource.data.indexOf(element);

        //If we change the operation type from a BootNotification to another, we should have a value for the socket
        if (!element.socket && element.operationName !== this.operations[0]) {
            element.socket = element.station.socketList[0].connectorId;
        }

        //If we choose the operation to be a BootNotification, the socket value should be undefined
        if (element.operationName === this.operations[0]) {
            element.socket = undefined;
        }

        //If we select a station which has less sockets than previous one, we should choose anothe value for the socket
        if ($event.value.chargingStationIdentityKey && element.socket > $event.value.numberOfSockets) {
            element.socket = element.station.socketList[0].connectorId;
        }

        if (index !== -1) {
            this.dataSource.data[index] = element;
        }
        this.table.renderRows();
        localStorage.setItem('dataSource', JSON.stringify(this.dataSource.data));
    }

    compareStations(s1: Station, s2: Station): boolean {
        if (s1 && s2) {
            return s1.chargingStationIdentityKey === s2.chargingStationIdentityKey;
        }
        return false;
    }

    compareIdTags(i1: IdTag, i2: IdTag): boolean {
        if (i1 && i2) {
            return i1.idTag === i2.idTag;
        }
        return false;
    }

    ngOnDestroy(): void {
        if (this.getAllStationsSubscription) {
            this.getAllStationsSubscription.unsubscribe();
        }
        if (this.endOfScheduleSubscription) {
            this.endOfScheduleSubscription.unsubscribe();
        }
    }

    changeProtocol()
    {
        switch (this.selectedOcppVersion) {
            case 'V16':
                this.operations =   AUTOPILOT_OPERATIONS_V16;
                break;
            case 'V201':
                this.operations =   AUTOPILOT_OPERATIONS_V201;
                break;
            case 'V16TLS':
                this.operations = AUTOPILOT_OPERATIONS_V16TLS;
                break;
            default:
                this.operations =   AUTOPILOT_OPERATIONS_V16;
                break;
        }
    }
}
