import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Station} from "../../../shared/models/station.model";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Subscription} from "rxjs";
import {StationService} from "../../../core/services/station.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {
    KEYS_FOR_CREATE,
    OCPP_VERSIONS,
    SIGNED_METERING_VALUES_TYPES,
    SNACKBAR_DURATION,
    VERSIONS_MAP
} from "../../../shared/utils/rest-utils.constants";
import {MatSlideToggleChange} from "@angular/material/slide-toggle";

@Component({
    selector: 'app-edit-station-dialog',
    templateUrl: './edit-station-dialog.component.html',
    styleUrls: ['./edit-station-dialog.component.css']
})
export class EditStationDialogComponent implements OnInit {

    /**
     * Subscriptions used by this instance
     */
    updateStationSubscription: Subscription;

    /**
     * Used for updating station
     */
    updateStationForm: FormGroup;
    updateStationRequestPayload: Station;
    station: Station;

    isCalibrationLawReady: boolean = false;
    plugAndCharge = false;
    behaviours: string[] = [];
    selectedBehaviour: string;
    versions: string[] = [];
    selectedOcppVersion: string = '';
    versions_map = VERSIONS_MAP;
    versions_map_keys = KEYS_FOR_CREATE;

    /**
     * Used for hiding/showing the password
     */
    hide = true;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public stationService: StationService,
        public updateSnackBar: MatSnackBar,
        public dialog: MatDialogRef<EditStationDialogComponent>
    ) {
        this.updateStationRequestPayload = {
            id: '',
            chargingStationIdentityKey: '',
            chargePointModel: '',
            chargePointVendor: '',
            centralSystemUrl: '',
            numberOfSockets: 0,
            pending: false,
            accepted: false,
            wrongRFID: false,
            socketList: [],
            calibrationLawReady: false,
            plugAndCharge: false,
            signedMeteringValuesType: '',
            protocolVersion: "",
            securityKey: ""
        }
    }

    ngOnInit(): void {
        this.station = this.data['data'];
        this.behaviours = SIGNED_METERING_VALUES_TYPES;
        this.isCalibrationLawReady = this.station.calibrationLawReady;
        this.selectedBehaviour = this.station.signedMeteringValuesType;
        this.versions = OCPP_VERSIONS;
        this.selectedOcppVersion = this.station.protocolVersion;

        this.updateStationForm = new FormGroup({
            chargingStationIdentityKey: new FormControl({
                value: this.station.chargingStationIdentityKey,
                disabled: true
            }),
            chargePointModel: new FormControl(this.station.chargePointModel, Validators.required),
            chargePointVendor: new FormControl(this.station.chargePointVendor, Validators.required),
            centralSystemUrl: new FormControl(this.station.centralSystemUrl, Validators.required),
            numberOfSockets: new FormControl({value: this.station.numberOfSockets, disabled: true}),
            wrongRFID: new FormControl(this.station.wrongRFID, Validators.required),
            calibrationLawReady: new FormControl(this.isCalibrationLawReady, Validators.required),
            plugAndCharge: new FormControl(this.plugAndCharge),
            password: new FormControl(this.station.securityKey),
            signedMeteringValues: new FormControl({
                value: this.selectedBehaviour,
                disabled: !this.isCalibrationLawReady
            }, Validators.required),
            ocppVersion: new FormControl({value: this.selectedOcppVersion, disabled: true}, Validators.required)
        })
    }

    updateStation(): void {
        this.updateStationRequestPayload.chargingStationIdentityKey = this.updateStationForm.get('chargingStationIdentityKey')?.value;
        this.updateStationRequestPayload.chargePointModel = this.updateStationForm.get('chargePointModel')?.value;
        this.updateStationRequestPayload.chargePointVendor = this.updateStationForm.get('chargePointVendor')?.value;
        this.updateStationRequestPayload.centralSystemUrl = this.updateStationForm.get('centralSystemUrl')?.value;
        this.updateStationRequestPayload.numberOfSockets = this.updateStationForm.get('numberOfSockets')?.value;
        this.updateStationRequestPayload.wrongRFID = this.updateStationForm.get('wrongRFID')?.value;
        this.updateStationRequestPayload.calibrationLawReady = this.updateStationForm.get('calibrationLawReady')?.value;
        this.updateStationRequestPayload.plugAndCharge = this.updateStationForm.get('plugAndCharge')?.value;
        this.updateStationRequestPayload.signedMeteringValuesType = this.updateStationForm.get('signedMeteringValues')?.value;
        this.updateStationRequestPayload.protocolVersion = this.updateStationForm.get('ocppVersion')?.value;
        this.updateStationRequestPayload.securityKey = this.updateStationForm.get('password')?.value;
        if (this.updateStationSubscription) {
            this.updateStationSubscription.unsubscribe();
        }
        this.updateStationSubscription = this.stationService.updateStation(this.updateStationRequestPayload).subscribe({
                next: () => {
                    this.updateSnackBar.open('Station was successfully updated!', 'Dismiss', {
                        duration: SNACKBAR_DURATION,
                        panelClass: ['successful-snackbar']
                    });
                },
                error: () => {
                    this.updateSnackBar.open('Something went wrong when updating station!', 'Dismiss', {
                        duration: SNACKBAR_DURATION,
                        panelClass: ['failure-snackbar']
                    });
                },
                complete: () => {
                    this.dialog.close(true);
                }
            }
        )
    }

    discardChanges(): void {
        this.dialog.close(false);
    }

    toggleCalibrationLawReady($event: MatSlideToggleChange) {
        this.isCalibrationLawReady = $event.checked;

        let signedMeteringValuesControl = this.updateStationForm.get('signedMeteringValues');
        if (!this.isCalibrationLawReady) {
            signedMeteringValuesControl?.disable();
        } else {
            signedMeteringValuesControl?.enable();
        }
    }

    ngOnDestroy(): void {
        if (this.updateStationSubscription != null) {
            this.updateStationSubscription.unsubscribe();
        }
    }
}

