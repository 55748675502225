<div>
    <h1 class="dialog-title">{{data.dialogHeader}}</h1>
    <div mat-dialog-content class="modal-body">
        <div class="message-body">
            <pre>{{data.dialogContent ? data.dialogContent : "Waiting for message..."}}</pre>
        </div>

    </div>

    <div class="btn-holder">
        <button mat-button mat-dialog-close class="cancel-button">Close</button>
    </div>
</div>
