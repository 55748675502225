<div class="auto-pilot-main">
    <h1 class="page-title">
        Auto Pilot Mode
    </h1>

    <div class="auto-pilot-component">
        <div class="custom-flows-buttons">
            <button class="flow-button" matTooltip="Normal flow" (click)="normalFlow()" mat-fab color="primary">
                <mat-icon class="normal-flow-icon"></mat-icon>
            </button>
            <button class="flow-button" matTooltip="Random flow" (click)="randomFlow()" mat-fab color="primary">
                <mat-icon class="random-flow-icon"></mat-icon>
            </button>
            <button class="flow-button clear-button" matTooltip="Clear operations" (click)="clearOperations()"
                    mat-fab
                    [disabled]="isClearButtonDisabled">
                <mat-icon class="clear-icon"></mat-icon>
            </button>
            <button class="flow-button new-operation-button" matTooltip="Add new operation" (click)="addOperation()"
                    mat-fab>
                <mat-icon class="new-operation-icon">add</mat-icon>
            </button>

            <mat-form-field class="delay-operations protocol-version " appearance="fill">
                <mat-label>Protocol version</mat-label>
                <mat-select [(value)]="selectedOcppVersion" (selectionChange)="changeProtocol()">
                    <mat-option
                        *ngFor="let version of versions_map_keys" [value]="version">
                        {{ versions_map.get(version)}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field class="delay-operations" appearance="fill">
                <mat-label>Delay between operations</mat-label>
                <input matInput type="number" [formControl]="delay"
                       placeholder="Ex. 10s" (keyup)="keyUp($event)" required>
                <mat-error *ngIf="delay.invalid">
                    Delay must be between 5 and 99 seconds
                </mat-error>
            </mat-form-field>
        </div>

        <div class="error-div" *ngIf="showErrorMessage">
            <div class="error-message">
                <span>{{errorMessage}}</span>
            </div>
            <div class="close-message">
                <button mat-icon-button class="error-message-icon" (click)="closeInformativeMessage()"></button>
            </div>
        </div>

        <mat-drawer-container class="drawer-container" autosize [hasBackdrop]="true">
            <mat-drawer #drawer class="drawer" mode="over" position="end" [opened]="is_table_being_updated"
                        disableClose="true">
                <section class="table-update">
                    <form class="table-update__form" [formGroup]="rowOperationForm">

                        <header class="table-update__header">
                            <h2 class="h3">{{is_new_row_being_added ? 'Add new' : 'Edit'}} operation</h2>
                        </header>
                        <div class="table-update__fields">
                            <div class="row">
                                <mat-form-field appearance="fill" class="mat-select-field">
                                    <mat-label>Operation name</mat-label>
                                    <mat-select [formControlName]="'operationName'"
                                                class="select-field">
                                        <mat-option *ngFor="let operation of operations" [value]="operation">
                                            {{ operation }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>

                                <mat-form-field appearance="fill" class="mat-select-field">
                                    <mat-label>Station</mat-label>

                                    <mat-select *ngIf="selectedOcppVersion == 'V16'" [formControlName]="'station'"
                                                class="select-field"
                                                [compareWith]="compareStations">
                                        <mat-option *ngFor="let station of stationsV16"
                                                    [value]="station">
                                            {{ station.chargingStationIdentityKey }}
                                        </mat-option>
                                    </mat-select>

                                    <mat-select *ngIf="selectedOcppVersion == 'V201'" [formControlName]="'station'"
                                                class="select-field"
                                                [compareWith]="compareStations">
                                        <mat-option *ngFor="let station of stationsV201"
                                                    [value]="station">
                                            {{ station.chargingStationIdentityKey }}
                                        </mat-option>
                                    </mat-select>

                                    <mat-select *ngIf="selectedOcppVersion == 'V16TLS'" [formControlName]="'station'"
                                                class="select-field"
                                                [compareWith]="compareStations">
                                        <mat-option *ngFor="let station of stationsV16TLS"
                                                    [value]="station">
                                            {{ station.chargingStationIdentityKey }}
                                        </mat-option>
                                    </mat-select>

                                    <mat-select *ngIf="selectedOcppVersion == 'V201TLS'" [formControlName]="'station'"
                                                class="select-field"
                                                [compareWith]="compareStations">
                                        <mat-option *ngFor="let station of stationsV201TLS"
                                                    [value]="station">
                                            {{ station.chargingStationIdentityKey }}
                                        </mat-option>
                                    </mat-select>

                                </mat-form-field>

                            </div>
                            <br>
                            <div class="row">
                                <mat-form-field class="mat-select-field"
                                                *ngIf="rowOperationForm.get('operationName')?.value !== 'BootNotification' && rowOperationForm.get('station')?.value !== null"
                                                appearance="fill">
                                    <mat-label>Socket</mat-label>
                                    <mat-select [formControlName]="'socket'" class="select-field">
                                        <mat-option
                                            *ngFor="let socket of rowOperationForm.get('station')?.value.socketList "
                                            [value]="socket.connectorId">
                                            {{ socket.connectorId }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>

                                <mat-form-field class="mat-select-field"
                                                *ngIf="(rowOperationForm.get('operationName')?.value === 'StartTransaction' ||
                                            rowOperationForm.get('operationName')?.value === 'StopTransaction')  && rowOperationForm.get('station')?.value !== null"
                                                appearance="fill">
                                    <mat-label>IdTag</mat-label>
                                    <mat-select [formControlName]="'idTag'"
                                                class="select-field"
                                                [compareWith]="compareIdTags">
                                        <mat-option *ngFor="let idTag of idTags"
                                                    [value]="idTag">
                                            {{ idTag.idTag }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <mat-form-field class="mat-select-field"
                                                *ngIf="rowOperationForm.get('operationName')?.value === 'StatusNotification' && rowOperationForm.get('station')?.value !== null"
                                                appearance="fill">
                                    <mat-label>Status</mat-label>
                                    <mat-select [formControlName]="'status'" class="select-field">
                                        <mat-option *ngFor="let status of statuses"
                                                    [value]="status">
                                            {{ status }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>
                        <footer class="table-update__footer">
                            <div class="buttons">
                                <button mat-flat-button color="primary" class="dismiss-button"
                                        (click)="dismissUpdateStation()">
                                    Dismiss
                                </button>
                                <button mat-flat-button color="primary" class="action-button"
                                        [disabled]="rowOperationForm.invalid"
                                        (click)="is_new_row_being_added ? saveOperation() : updateOperation()">
                                    {{is_new_row_being_added ? 'Save' : 'Update'}}
                                </button>
                            </div>
                        </footer>
                    </form>
                </section>
            </mat-drawer>
            <app-auto-pilot-table [dataSource]="dataSource" [stations]="stations" [maxIndex]="this.maxIndex"
                                  [operations]="operations" (deletedRow)="deletedRow()"
                                  [stationsV16]="stationsV16"
                                  [stationsV16TLS]="stationsV16TLS"
                                  [stationsV201]="stationsV201"
                                  [stationsV201TLS]="stationsV201TLS"
                                  [selectedProtocol]="selectedOcppVersion"
                                  (onNewOperation)="onAddNewRowOperation($event)"
                                  (onRowEdit)="onEditRowOperation($event)"
                                  (disableClearOperationButton)="disableClearOperationButton()">
            </app-auto-pilot-table>

            <div>
                <button mat-flat-button color="primary" class="action-button"
                        (click)="runMultipleOperations()" [disabled]="getScheduleButtonDisabled() || delay === null">
                    Schedule
                </button>
            </div>
        </mat-drawer-container>
    </div>
</div>
