<mat-sidenav-container class="sidenav-container" fullscreen>
    <mat-sidenav #drawer class="sidenav" fixedInViewport
                 [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
                 [mode]="(isHandset$ | async) ? 'over' : 'side'"
                 [opened]="(isHandset$ | async) === false">
        <mat-toolbar>
            <img src="../../../assets/img/Bosch_COLOR_RGB.png" width="135" height="30" class="d-inline-block align-top"
                 alt="">
        </mat-toolbar>
        <mat-nav-list>
            <a mat-list-item routerLink="/user"><i class="fa fa-user" aria-hidden="true"
                                                   style="padding-right:5px;"></i>{{getPrincipalName()}}</a>
            <a mat-list-item routerLink="/stations">Stations</a>
            <a mat-list-item routerLink="/monitoring">Monitoring</a>
            <a mat-list-item routerLink="/autoPilot">Auto Pilot</a>
            <a mat-list-item (click)="logout()">Logout</a>

        </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content id="scrollable-element" (scroll)="onScroll()">
        <button mat-mini-fab class="scroll-button" matTooltip="Scroll to top" matTooltipPosition="before"
                color="primary"
                *ngIf="scrollVisible" (click)="scrollToTop()">
            <mat-icon>arrow_upward</mat-icon>
        </button>
        <mat-toolbar class="toolbar">
            <mat-toolbar-row>
                <button
                    type="button"
                    aria-label="Toggle sidenav"
                    mat-icon-button
                    (click)="drawer.toggle()"
                    *ngIf="isHandset$ | async">
                    <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
                </button>
                <span class="page-title" style="font-family: BoschSans">
        Charging station simulator
      </span>
                <span class="example-spacer"></span>
                <span class="multiple-operations-buttons">
            <button (click)="startAllStations()" [disabled]="stationsListSize === 0"
                    [ngClass]="{'icon-disabled': stationsListSize === 0}"

                    matTooltip="Boot all stations" mat-icon-button class="boot-icon icon" alt=""></button>
            <button (click)="chooseIdTagToBeUsed()" [disabled]="stationsListSize === 0"
                    [ngClass]="{'icon-disabled': stationsListSize === 0}"

                    matTooltip="Start multiple transactions" mat-icon-button class="start-transactions icon"
                    alt=""></button>

            <div
                matTooltip="{{startedMultipleTransactions ? 'Start multiple transactions running!': 'Stop multiple transactions'}}"
                [matTooltipDisabled]="false">
                <button [disabled]="startedMultipleTransactions || stationsListSize === 0"
                        (click)="stopMultipleTransactions()"
                        [ngClass]="{'icon-disabled': startedMultipleTransactions || stationsListSize === 0}"
                        mat-icon-button class="stop-transactions icon" alt=""></button>
            </div>
            <button (click)="stopAllStations()" matTooltip="Turn off all stations" [disabled]="stationsListSize === 0"
                    [ngClass]="{'icon-disabled': stationsListSize === 0}"
                    mat-icon-button class="stop-icon icon" alt=""></button>
            <button (click)="openDeleteAllStationsDialog()" matTooltip="Delete all stations"
                    [disabled]="stationsListSize === 0"
                    [ngClass]="{'icon-disabled': stationsListSize === 0}"
                    mat-icon-button class="delete-icon icon" alt="" onclick="this.blur()"></button>
            </span>
            </mat-toolbar-row>
        </mat-toolbar>

        <div>
            <div class="my-overlay" *ngIf="loading || stationService.isDeleteLogEventsRunning">
                <img src="/assets/img/unplug.png" class="unplug-icon" *ngIf="loading">
                <br>
                <h3 class="info-text">
                    {{loading ? 'Please wait while your stations are turned off..'
                    : 'Please wait while logEvents are deleted..'}}
                </h3>
                <i class="fa fa-spinner fa-spin"></i>
            </div>
            <router-outlet></router-outlet>
        </div>

    </mat-sidenav-content>
</mat-sidenav-container>
