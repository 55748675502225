<div class="login-form">
    <img src="/assets/img/Bosch_COLOR_RGB.png" class="d-inline-block align-top img-center bosch-logo"
         alt="Bosch Sign">
    <img src="../../../assets/img/station22.png" class="d-inline-block align-top wave_picture"
         alt="Wave">

    <div class="card-container">
        <mat-card class="card-content">
            <form [formGroup]="loginForm" (ngSubmit)="login()" class="form-elements">
                <br/>
                <img src="/assets/img/avatar.jpg" class="avatar-picture">
                <h2 class="welcome-title">
                    Welcome back!
                </h2>
                <mat-form-field class="full-width-input">
                    <input matInput placeholder="Username" [formControlName]="'username'" required>
                    <mat-error>
                        Please provide a valid username
                    </mat-error>
                </mat-form-field>

                <mat-form-field class="full-width-input">
                    <input matInput type="password" placeholder="Password" [formControlName]="'password'" required>
                    <mat-error>
                        Please provide a valid password
                    </mat-error>
                </mat-form-field>
                <button mat-raised-button class="login-button" type="submit">Login</button>
            </form>
        </mat-card>
    </div>
</div>
