import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {StationsLogComponent} from "./components/stations-dashboard/stations-log.component";
import {StationPageComponent} from "./components/station-page/station-page.component";
import {LoginPageComponent} from "./components/login-page/login-page.component";
import {ErrorPageComponent} from "./components/error-page/error-page.component";
import {AppLayoutComponent} from "./layout/app-layout/app-layout.component";
import {LoginLayoutComponent} from "./layout/login-layout/login-layout.component";
import {AuthGuardService} from "./core/guards/auth-guard.service";
import {LoggedInAuthGuardService} from "./core/guards/logged-in-auth-guard.service";
import {AdminPageComponent} from "./components/admin-page/admin-page.component";
import {AutoPilotPageComponent} from "./components/auto-pilot/auto-pilot-page/auto-pilot-page.component";
import {MonitoringTabComponent} from "./components/auto-pilot/monitoring-tab/monitoring-tab/monitoring-tab.component";

const routes: Routes = [

    // login route
    {
        path: 'login',
        component: LoginLayoutComponent,
        children: [
            {path: '', component: LoginPageComponent, canActivate: [LoggedInAuthGuardService]}
        ]
    },

    // app layout routes
    {
        path: '',
        component: AppLayoutComponent,
        canActivate: [AuthGuardService],
        children: [
            {path: '', redirectTo: '/login', pathMatch: 'full'},
            {path: 'stations', component: StationsLogComponent},
            {path: 'stations/:chargingStationIdentityKey', component: StationPageComponent},
            {path: 'user', component: AdminPageComponent},
            {path: 'autoPilot', component: AutoPilotPageComponent},
            {path: 'monitoring', component: MonitoringTabComponent},
            {
                path: 'pageNotFound',
                component: ErrorPageComponent,
                data: {errorCode: 404, errorMessage: "This is not the page you are looking for!"}
            },
            {
                path: 'unauthorized',
                component: ErrorPageComponent,
                data: {errorCode: 401, errorMessage: "Unauthorized access!"}
            },
            {path: '**', redirectTo: '/pageNotFound', pathMatch: 'full'}
        ]
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
