import {Component} from '@angular/core';
import * as SockJS from "sockjs-client";
import * as Stomp from "stompjs";
import {WS_TOPIC} from "./shared/utils/rest-utils.constants";
import {environment} from "../environments/environment";
import {MatSnackBar} from "@angular/material/snack-bar";
import {DataExchangeService} from "./core/services/data-exchange.service";

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent {
    title = 'frontend';

    /**
     * Used for WS connection
     */
    stompClient: any;
    webSocketEndPoint: string = environment.apiURL + '/ws';
    endOfScheduleTopic = "/endOfSchedule";
    transactionsTopic = "/transaction";
    monitoringTopic = "/logs";

    constructor(private messageService: DataExchangeService, public snackBar: MatSnackBar, private dataExchangeService: DataExchangeService) {
    }

    ngOnInit(): void {
        this._connect();
    }

    _connect() {
        let ws = new SockJS(this.webSocketEndPoint);
        this.stompClient = Stomp.over(ws);
        this.stompClient.debug = null;
        this.stompClient.connect({}, (frame: any) => {
            this.stompClient.subscribe(WS_TOPIC + this.endOfScheduleTopic, (sdkEvent: any) => {
                this.handleMessage(sdkEvent);
            });
            this.stompClient.subscribe(WS_TOPIC + this.transactionsTopic + "/idTagStatus", (sdkEvent: any) => {
                this.handleIdTagStatus(sdkEvent);
            });
            this.stompClient.subscribe(WS_TOPIC + this.transactionsTopic + "/startMultiple", (sdkEvent: any) => {
                this.handleStartedMultipleTransactionsStatus(sdkEvent);
            });

            this.stompClient.subscribe(WS_TOPIC + this.monitoringTopic, (sdkEvent: any) => {
                this.handleReceivedLog(sdkEvent);
            });
        }, this.errorCallBack);
    }

    handleMessage(message: any) {
        if (message.body === "Success") {
            sessionStorage.setItem("scheduleButtonDisabled", JSON.stringify(false));
        }
        this.dataExchangeService.sendEndOfScheduleNotification(message.body);
    }

    handleReceivedLog(message: any) {
        this.dataExchangeService.announceMonitoringLogReceived(message.body);
    }

    handleIdTagStatus(message: any) {
        this.dataExchangeService.sendIdTagStatusNotification(message.body);
    }

    handleStartedMultipleTransactionsStatus(message: any) {
        this.dataExchangeService.announceStartedMultipleTransactionsStatus(message.body);
    }

    // on error, schedule a reconnection attempt
    errorCallBack(error: any) {
        console.log("error: " + error);
        setTimeout(() => {
            this._connect();
        }, 5000);
    }

}
