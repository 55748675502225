import {environment} from "../../../environments/environment";

export var BASE_URL = environment.apiURL + '/api';

export function setBaseURL(value: string): void {
    BASE_URL = value;
}

export function setEnvironmentUrl(value: string): void {
    environment.apiURL = value;
}

export const WS_TOPIC = "/notification/station";
export const SUCCESSFUL_NOTIFICATION_TOPIC = "/notification/success/station";
export const FAILURE_NOTIFICATION_TOPIC = "/notification/failure/station";

export const STATION_STATUS_CHANGED = "/notification/stationStatusChanged/station";

export const HTTP_METHOD_HEADERS = {
    'headers': {
        'Cache-Control': 'no-cache',
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Access-Control-Allow-Origin': '*'
    }
};

export const SOCKET_STATUSES = ["AVAILABLE", "PREPARING", "CHARGING", "SUSPENDED_EVSE", "SUSPENDED_EV", "FINISHING", "RESERVED", "UNAVAILABLE", "FAULTED"];

export const SOCKET_STATUSES_V201 = ["AVAILABLE", "OCCUPIED", "RESERVED", "UNAVAILABLE", "FAULTED"];

export const SNACKBAR_DURATION = 6000;

export const CALLBACK_DURATION = 4000;

export const SOCKET_STATUS_COLORS: { [key: string]: string } = {
    'AVAILABLE': '#80bc24',
    'PREPARING': '#08bcfc',
    'CHARGING': '#088ccc',
    'OCCUPIED': '#088ccc',
    'SUSPENDED_EVSE': '#ffc454',
    'SUSPENDED_EV': '#ffac14',
    'FINISHING': '#0804fc',
    'RESERVED': '#58247c',
    'UNAVAILABLE': '#d07c84',
    'FAULTED': '#e80414'
};

export const CENTRAL_SYSTEM_URL_LOCAL_V16 = "ws://localhost:1006/ocpp/json/1.6/oC676z_uP-OXf-svfD6mpPhiI4EfxpRGduAjzCxY8Zw=";
export const CENTRAL_SYSTEM_URL_LOCAL_V201 = "ws://localhost:1006/ocpp/json/2.0.1/oC676z_uP-OXf-svfD6mpPhiI4EfxpRGduAjzCxY8Zw=";
export const CENTRAL_SYSTEM_URL_LOCAL_V16TLS = "ws://localhost:1006/ocpp/json/1.6-secured/oC676z_uP-OXf-svfD6mpPhiI4EfxpRGduAjzCxY8Zw=";
export const CENTRAL_SYSTEM_URL_LOCAL_V201TLS = "ws://localhost:1006/ocpp/json/2.0.1-secured/oC676z_uP-OXf-svfD6mpPhiI4EfxpRGduAjzCxY8Zw=";

export const CENTRAL_SYSTEM_URL_DEV_V16 = "wss://station.dev.cpms.bosch.bosch-emobility.com/ocpp/json/1.6/apcKBgJhtXdr-Si_lAODsdV0FLzHbj5Qr9sKHLfdUG0=";
export const CENTRAL_SYSTEM_URL_DEV_V16TLS = "wss://station.dev.cpms.bosch.bosch-emobility.com/ocpp/json/1.6-secured/apcKBgJhtXdr-Si_lAODsdV0FLzHbj5Qr9sKHLfdUG0=";
export const CENTRAL_SYSTEM_URL_DEV_V201 = "wss://station.dev.cpms.bosch.bosch-emobility.com/ocpp/json/2.0.1/apcKBgJhtXdr-Si_lAODsdV0FLzHbj5Qr9sKHLfdUG0=";
export const CENTRAL_SYSTEM_URL_DEV_V201TLS = "wss://station.dev.cpms.bosch.bosch-emobility.com/ocpp/json/2.0.1-secured/apcKBgJhtXdr-Si_lAODsdV0FLzHbj5Qr9sKHLfdUG0=";

export const SIGNED_METERING_VALUES_TYPES = ['NOT_AVAILABLE', 'STOP_TRANSACTION', 'METER_VALUES', 'MIXED', 'CHARGE_POST', 'CHARGE_BOX', 'ALPITRONIC', 'MAHLE', 'MAHLE_WITH_INVALID_DATA', 'MAHLE_WITH_INVALID_DATA_UNPARSABLE'];

export const OCPP_VERSIONS = ["V16", "V16TLS", "V201", "V201TLS"];

export const OCPP_VERSIONS_FILTER = ["V16", "V16TLS", "V201", "V201TLS", "ALL"];

export const VERSIONS_MAP: Map<string, string> = new Map([
    ['V16', '1.6'],
    ['V16TLS', '1.6TLS'],
    ['V201', '2.0.1'],
    ['V201TLS', '2.0.1TLS'],
    ['ALL', 'ALL']
]);

export const KEYS = ['V16', "V16TLS", 'V201', 'V201TLS', 'ALL'];

export const KEYS_FOR_CREATE = ['V16', "V16TLS", 'V201', 'V201TLS'];

// @ts-ignore
// It is a better approach to have separate lists of operations for every protocol,
// because in this way we can add easily new operations for a specific version, without any other implementation changes
export const AUTOPILOT_OPERATIONS_V16 = ['BootNotification', 'StatusNotification', 'StartTransaction', 'StopTransaction'];
// @ts-ignore
export const AUTOPILOT_OPERATIONS_V201 = ['BootNotification', 'StatusNotification', 'StartTransaction', 'StopTransaction'];
// @ts-ignore
export const AUTOPILOT_OPERATIONS_V16TLS = ['BootNotification', 'StatusNotification', 'StartTransaction', 'StopTransaction', 'SecurityEventNotification'];
// @ts-ignore
