import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {MessageDialogInterface} from "../../../interfaces/message-dialog";

@Component({
    selector: 'app-operation-message',
    templateUrl: './operation-message.component.html',
    styleUrls: ['./operation-message.component.css']
})
export class OperationMessageComponent implements OnInit {

    constructor(@Inject(MAT_DIALOG_DATA) public data: MessageDialogInterface) {
    }

    ngOnInit(): void {
    }

}
