<div class="content">
    <div class="grid-container">
        <mat-grid-list rowHeight="255px" cols="2" class="mat-grid-list-content">
            <mat-grid-tile (click)=openAddNewStationDialog()>
                <mat-card class="dashboard-card new-station-card plus-card">
                    <img src="/assets/img/plus-sign.png" class="plus-icon" alt="">
                </mat-card>
            </mat-grid-tile>
            <mat-grid-tile>
                <mat-card class="dashboard-card new-station-card faded">
                    <mat-card-header>
                        <mat-card-title>
                            Station #{{defaultIdentityKey}}
                        </mat-card-title>
                        <i class="fa fa-edit action-icon edit-icon"
                           (click)="openEditDialog()"></i>

                    </mat-card-header>
                    <mat-card-content class="dashboard-card-content">
                        <div class="station-info">
                            <div class="station-attribute">
                                <div class="attribute-name"> Charge point model:</div>
                                <div class="attribute-value">{{defaultStationPayload.chargePointModel}}</div>
                            </div>
                            <div class="station-attribute">
                                <div class="attribute-name"> Charge point vendor:</div>
                                <div class="attribute-value"> {{defaultStationPayload.chargePointVendor}}</div>
                            </div>
                            <div class="station-attribute">
                                <div class="attribute-name"> Ocpp version:</div>
                                <div *ngIf="defaultStationPayload.protocolVersion == 'V16'" class="attribute-value">
                                    1.6
                                </div>
                                <div *ngIf="defaultStationPayload.protocolVersion == 'V201'" class="attribute-value">
                                    2.0.1
                                </div>
                                <div *ngIf="defaultStationPayload.protocolVersion == 'V16TLS'" class="attribute-value">
                                    1.6TLS
                                </div>
                            </div>
                            <div class="station-attribute">
                                <div class="attribute-name"> System URL:</div>
                                <div class="attribute-value system-url-value"
                                     (click)="alterSystemURLText()"
                                     [ngClass]="{'show-less': showShortSystemURL, 'show-more': !showShortSystemURL}">
                                    {{defaultStationPayload.centralSystemUrl}}</div>
                            </div>
                            <div class="station-attribute">
                                <div class="attribute-name"> No. sockets:</div>
                                <div class="attribute-value"> {{defaultStationPayload.numberOfSockets}}</div>
                            </div>
                        </div>
                    </mat-card-content>
                    <div class="actions">
                        <mat-form-field class="default-select">
                            <mat-label>No. stations</mat-label>
                            <input [(ngModel)]="numberOfDefaultStations" matInput type="number"
                                   placeholder="Number of default Stations" min="1" required>
                        </mat-form-field>
                        <button mat-flat-button class="default-station-button" (click)="createDefaultStation()">
                            Create default station(s)
                        </button>
                    </div>
                </mat-card>
            </mat-grid-tile>
        </mat-grid-list>

        <mat-expansion-panel class="expansion-panel" (opened)="isExpansionPanelOpened=true"
                             (closed)="isExpansionPanelOpened=false">
            <mat-expansion-panel-header>
                <mat-panel-title class="section-title">
                    {{isExpansionPanelOpened ? "Search by keyword" : "Filter stations"}}
                    <mat-chip-list class="active-chips" *ngIf="!isExpansionPanelOpened">
                        <mat-chip class="sorting-chip" matTooltip="Toggle sorting strategy"
                                  (click)="toggleSortingStrategy()">
                            Sort: {{isMostRecentSorting ? "Newest to oldest" : "Oldest to newest"}}
                        </mat-chip>
                        <mat-chip *ngIf="keyword && keyword!= 'undefined'" (removed)="removeKeywordFiltering()">
                            Keyword: {{this.keyword}}
                            <mat-icon matChipRemove>cancel</mat-icon>
                        </mat-chip>
                        <ng-container *ngFor="let filter of filters; index as i">
                            <mat-chip class="no-wrap"
                                      *ngIf="filter.selected && filter.value && filter.value!= 'undefined'"> {{filter.propertyName}}
                                : {{filter.value}}
                                <mat-icon matChipRemove (click)="removeFilter(i)">cancel</mat-icon>
                            </mat-chip>
                        </ng-container>
                    </mat-chip-list>
                    <mat-chip *ngIf="!isExpansionPanelOpened" matTooltip="Select version">
                        Ocpp Version: {{ versions_map.get(ocppVersion) }}
                        <mat-select class="ocpp-chip" [(value)]="ocppVersion" (selectionChange)="selectOcppVersion()">
                            <mat-option *ngFor="let version of versions_map_keys" value="{{version}}">
                                {{ versions_map.get(version) }}
                            </mat-option>
                        </mat-select>
                    </mat-chip>


                </mat-panel-title>
                <mat-panel-description class="panel-description">
                    <mat-icon>filter_list</mat-icon>
                </mat-panel-description>
            </mat-expansion-panel-header>
            <mat-form-field class="search-bar" appearance="fill">
                <mat-label>Keyword</mat-label>
                <input type="text" (keydown)="onFiltersChange()" [(ngModel)]="keyword" matInput
                       placeholder="Ex. e2e_smartcharching_1">
                <button *ngIf="keyword" matSuffix mat-icon-button aria-label="Clear" (click)="removeKeywordFiltering()">
                    <mat-icon>close</mat-icon>
                </button>
                <mat-hint align="start"><strong>Search after name, model or vendor</strong></mat-hint>
            </mat-form-field>
            <button mat-flat-button class="sort-button" matTooltip="Toggle sorting strategy" matTooltipPosition="right"
                    (click)="toggleSortingStrategy()"><i
                class="fa fa-sort sort-icon"></i>
                {{isMostRecentSorting ? "Older" : "Newer"}} first
            </button>
            <br><br>
            <h4 class="section-title">Add custom filters</h4>
            <section class="custom-filters-section">
                <ng-container *ngFor="let filter of filters; index as i">
                    <p *ngIf="i != filters.length-1">
                        <mat-checkbox class="custom-filters-checkbox" color="primary" (change)="checkboxChange()"
                                      [(ngModel)]="filters[i].selected"></mat-checkbox>
                        <mat-form-field appearance="fill">
                            <mat-label>{{filter.propertyName}}</mat-label>
                            <input [disabled]="!filters[i].selected" matInput
                                   (keydown)="onFiltersChange()"
                                   [(ngModel)]="filters[i].value">
                        </mat-form-field>

                    </p>
                </ng-container>
                <p>
                    <mat-checkbox class="accepted-filter" color="primary" [(ngModel)]="filters[3].selected"
                                  (change)="checkboxChange()">
                        Accepted by CPMS
                    </mat-checkbox>
                </p>
            </section>
            <mat-chip-list>
                <mat-chip class="sorting-chip" (click)="toggleSortingStrategy()" matTooltip="Toggle sorting strategy">
                    Sort: {{isMostRecentSorting ? "Newest to oldest" : "Oldest to newest"}}
                </mat-chip>

                <mat-chip matTooltip="Select version">
                    Ocpp Version: {{versions_map.get(ocppVersion)}}
                    <mat-select class="ocpp-chip" [(value)]="ocppVersion" (selectionChange)="selectOcppVersion()">
                        <mat-option *ngFor="let version of versions_map_keys" value="{{version}}">
                            {{ versions_map.get(version) }}
                        </mat-option>
                    </mat-select>
                </mat-chip>
                <mat-chip *ngIf="keyword && keyword!= 'undefined'">Keyword: {{this.keyword}}
                    <mat-icon matChipRemove (click)="removeKeywordFiltering()">cancel</mat-icon>
                </mat-chip>
                <ng-container *ngFor="let filter of filters; index as i">
                    <mat-chip class="no-wrap" *ngIf="filter.selected && filter.value && filter.value!= 'undefined'">
                        {{filter.propertyName}}: {{filter.value}}
                        <mat-icon matChipRemove (click)="removeFilter(i)">cancel</mat-icon>
                    </mat-chip>
                </ng-container>
            </mat-chip-list>
        </mat-expansion-panel>

        <mat-grid-list rowHeight="290px" cols="2" class="mat-grid-list-content">
            <mat-grid-tile *ngFor="let station of stations">
                <app-station [station]="station"></app-station>
            </mat-grid-tile>
            <div class="no-stations-info" *ngIf="stations.length === 0">
                <img src="/assets/img/no_stations_to_display.png" class="no-stations-image">
                <br>
                <h3 class="info-text">No stations to display!
                    <span class="create-station-link" (click)=openAddNewStationDialog()>Create a station</span> to
                    start using the simulator!</h3>
            </div>
        </mat-grid-list>
    </div>
    <button mat-flat-button class="more-items-button" (click)="loadMoreStations()"
            *ngIf="availableItems > displayedItems"> Load more..
    </button>
</div>
