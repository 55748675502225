import {Component} from '@angular/core';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {Observable, Subscription} from 'rxjs';
import {map, shareReplay} from 'rxjs/operators';
import {Router} from "@angular/router";
import {UserService} from "../../core/services/user.service";
import {StationService} from "../../core/services/station.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {SNACKBAR_DURATION} from "../../shared/utils/rest-utils.constants";
import {TransactionService} from "../../core/services/transaction.service";
import {MatDialog} from "@angular/material/dialog";
import {IdTagDialogComponent} from "../../components/admin-page/id-tag-tab/id-tag-dialog/id-tag-dialog.component";
import {DialogInterface} from "../../shared/interfaces/dialog-interface";
import {StationStoreService} from "../../core/services/station-store.service";
import {Station} from "../../shared/models/station.model";
import {DataExchangeService} from "../../core/services/data-exchange.service";
import {ConfirmationDialogComponent} from "../../components/confirmation-dialog/confirmation-dialog.component";
import {LogoutDialogComponent} from "../../shared/modals/logout-dialog/logout-dialog.component";

@Component({
    selector: 'app-nav',
    templateUrl: './nav.component.html',
    styleUrls: ['./nav.component.css']
})
export class NavComponent {

    /**
     * Subscriptions to be used by this instance
     */
    startAllStationsSubscription: Subscription;
    stopAllStationsSubscription: Subscription;
    startMultipleTransactionsSubscription: Subscription;
    stopMultipleTransactionsSubscription: Subscription;
    startedMultipleTransactionStatusSubscription: Subscription;
    idTagStatusSubscription: Subscription;
    getStationsListNotificationSubscription: Subscription;
    logoutSubscription: Subscription;
    scrollVisible: boolean | false;
    startedMultipleTransactions: boolean | false;
    idTag: '';
    stationsListSize: number = 0;
    stationsList: Observable<Station[]>;
    loading = false;

    isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
        map(result => result.matches),
        shareReplay()
    );

    constructor(private breakpointObserver: BreakpointObserver, public router: Router, private userService: UserService, private messageService: DataExchangeService,
                public stationService: StationService, private transactionService: TransactionService, public startStopSnackBar: MatSnackBar, public dialog: MatDialog,
                private stationStore: StationStoreService) {

    }

    ngOnInit(): void {
        this.startedMultipleTransactions = localStorage.getItem("startedMultipleTransactions") === 'true';
        this.handleStartedMultipleTransactionsStatus();
        this.handleIdTagStatusReceived();
        this.handleChangedStationsList();
    }

    handleChangedStationsList(): void {
        if (this.getStationsListNotificationSubscription != null) {
            this.getStationsListNotificationSubscription.unsubscribe();
        }

        this.getStationsListNotificationSubscription = this.stationStore.stationListSize$.subscribe(size => {
            this.stationsListSize = size;
        });
    }

    reloadCurrentRoute() {
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigate(['/stations']);
    }

    logout(): void {
        const dialogRef = this.dialog.open(LogoutDialogComponent, {
            width: '700px',
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result === 'Simple') {
                this.logoutSubscription = this.userService.logout().subscribe(() => {
                    localStorage.clear();
                    window.location.replace('/login');
                });
            }
            if (result === 'Extended') {
                this.loading = true;
                this.stationService.stopAllStations().subscribe(() =>{
                    this.logoutSubscription = this.userService.logout().subscribe(() => {
                        localStorage.clear();
                        this.loading = false;
                        window.location.replace('/login');
                    });
                });
            }
        })

    }

    startMultipleTransactions(idTag: string): void {
        this.startedMultipleTransactions = true;
        if (this.startMultipleTransactionsSubscription) {
            this.startMultipleTransactionsSubscription.unsubscribe();
        }
        this.startMultipleTransactionsSubscription = this.transactionService.startMultipleTransactions(idTag).subscribe({
            next: (response) => {
                this.startStopSnackBar.open(response.message, 'Dismiss', {
                    duration: SNACKBAR_DURATION,
                    panelClass: ['info-snackbar']
                });
            },
            error: () => {
                this.startedMultipleTransactions = false;
                this.startStopSnackBar.open('Something went wrong!', 'Dismiss', {
                    duration: SNACKBAR_DURATION,
                    panelClass: ['failure-snackbar']
                });
            },
            complete: () => {
                localStorage.setItem("startedMultipleTransactions", String(this.startedMultipleTransactions));
            }
        });
    }

    stopMultipleTransactions(): void {
        if (this.stopMultipleTransactionsSubscription) {
            this.stopMultipleTransactionsSubscription.unsubscribe();
        }
        this.stopMultipleTransactionsSubscription = this.transactionService.stopMultipleTransactions().subscribe({
            next: (response) => {
                this.startedMultipleTransactions = false;
                this.startStopSnackBar.open(response.message, 'Dismiss', {
                    duration: SNACKBAR_DURATION,
                    panelClass: ['successful-snackbar']
                });
                this.reloadCurrentRoute();
            },
            error: () => {
                this.startStopSnackBar.open('Something went wrong!', 'Dismiss', {
                    duration: SNACKBAR_DURATION,
                    panelClass: ['failure-snackbar']
                });
            },
            complete: () => {
                localStorage.setItem("startedMultipleTransactions", String(this.startedMultipleTransactions));
            }
        });
    }

    stopAllStations(): void {
        if (this.stopAllStationsSubscription) {
            this.stopAllStationsSubscription.unsubscribe();
        }
        this.stopAllStationsSubscription = this.stationService.stopAllStations().subscribe(data => {
                this.startStopSnackBar.open('All the stations have been successfully stopped!', 'Dismiss', {
                    duration: SNACKBAR_DURATION,
                    panelClass: ['successful-snackbar']
                });
                this.reloadCurrentRoute();
            },
            () => {
                this.startStopSnackBar.open('Something went wrong!', 'Dismiss', {
                    duration: SNACKBAR_DURATION,
                    panelClass: ['failure-snackbar']
                });
            });
    }

    startAllStations(): void {
        if (this.startAllStationsSubscription) {
            this.startAllStationsSubscription.unsubscribe();
        }
        this.startAllStationsSubscription = this.stationService.startAllStations().subscribe(data => {
            this.startStopSnackBar.open('All the stations have been successfully started!', 'Dismiss', {
                duration: SNACKBAR_DURATION,
                panelClass: ['successful-snackbar']
            });
            this.reloadCurrentRoute();
        }, () => {
            this.startStopSnackBar.open('Something went wrong!', 'Dismiss', {
                duration: SNACKBAR_DURATION,
                panelClass: ['failure-snackbar']
            })
        })
    }

    chooseIdTagToBeUsed(): void {
        let dialogRef;
        const dialogInterface: DialogInterface = {
            dialogHeader: 'Enter id tag',
            dialogContent: 'Enter id tag to be used for multiple transactions ',
            cancelButtonLabel: 'Cancel',
            confirmButtonLabel: 'Use',
        };
        dialogRef = this.dialog.open(IdTagDialogComponent, {
            width: '500px',
            data: dialogInterface
        });

        dialogRef.afterClosed().subscribe(data => {
            if (data !== undefined && data !== false) {
                this.startMultipleTransactions(data.idTag);
            }
        })
    }

    openDeleteAllStationsDialog() {
        const dialogInterface: DialogInterface = {
            dialogHeader: 'Delete all stations',
            dialogContent: 'Are you sure you want to delete all stations',
            cancelButtonLabel: 'Cancel',
            confirmButtonLabel: 'Yes',
            deleteAllStations: true
        };
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
            width: '300px',
            data: dialogInterface,
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.reloadCurrentRoute();
            }
        });
    }

    onScroll(): void {
        const scrollPosition = document.getElementById('scrollable-element')?.scrollTop;
        this.scrollVisible = !!(scrollPosition && scrollPosition > window.innerHeight / 4);
    }

    scrollToTop(): void {
        const scrollableElement = document.getElementById('scrollable-element');
        scrollableElement!.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
        });
    }

    showIdTagStatus(idTagStatus: any): void {
        this.startedMultipleTransactions = idTagStatus.message === "Valid";
        this.startStopSnackBar.open(idTagStatus.message + " RFID!", "Dismiss", {
            duration: SNACKBAR_DURATION,
            panelClass: idTagStatus.message === "Valid" ? ['successful-snackbar'] : ['failure-snackbar']
        });
    }

    handleStartedMultipleTransactionsStatus(): void {
        if (this.startedMultipleTransactionStatusSubscription) {
            this.startedMultipleTransactionStatusSubscription.unsubscribe();
        }

        this.startedMultipleTransactionStatusSubscription = this.messageService.startedMultipleTransactionsAnnounced$.subscribe(message => {
            this.startedMultipleTransactions = false;
            localStorage.setItem("startedMultipleTransactions", String(this.startedMultipleTransactions));

            if (message.message === "true") {
                this.startStopSnackBar.open("Started multiple transactions!", 'Dismiss', {
                    duration: SNACKBAR_DURATION,
                    panelClass: ['successful-snackbar']
                });
                this.reloadCurrentRoute();
            } else {
                this.startStopSnackBar.open('Something went wrong!', 'Dismiss', {
                    duration: SNACKBAR_DURATION,
                    panelClass: ['failure-snackbar']
                });
            }
        });
    }

    handleIdTagStatusReceived(): void {
        if (this.idTagStatusSubscription) {
            this.idTagStatusSubscription.unsubscribe();
        }

        this.idTagStatusSubscription = this.messageService.idTagStatusAnnounced$.subscribe(idTagStatus => {
            this.showIdTagStatus(idTagStatus);
        });
    }


    getPrincipalName(): string | null {
        return localStorage.getItem('principalName');
    }

    ngOnDestroy(): void {
        if (this.logoutSubscription) {
            this.logoutSubscription.unsubscribe();
        }
        if (this.startAllStationsSubscription) {
            this.startAllStationsSubscription.unsubscribe();
        }
        if (this.stopAllStationsSubscription) {
            this.stopAllStationsSubscription.unsubscribe();
        }
        if (this.startedMultipleTransactionStatusSubscription) {
            this.startedMultipleTransactionStatusSubscription.unsubscribe();
        }
        if (this.idTagStatusSubscription) {
            this.idTagStatusSubscription.unsubscribe();
        }

        if (this.getStationsListNotificationSubscription) {
            this.getStationsListNotificationSubscription.unsubscribe();
        }
    }
}
