
import {environment} from "../../../environments/environment.qa";
import {Injectable} from "@angular/core";


@Injectable({providedIn: 'root'})
export class EnvQa{
    public readonly apiUrl: string;
    public readonly centralSystemUrl: string;
    public readonly environmentSignature: string;
    public readonly production: boolean;

    constructor() {
        this.apiUrl = environment.apiURL;
        this.production = environment.production;
        this.centralSystemUrl = environment.centralSystemUrl;
        this.environmentSignature = environment.environmentSignature;
    }

}
