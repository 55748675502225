<div>
    <h1 class="dialog-title">Send DataTransfer message</h1>
    <div mat-dialog-content class="modal-body">
        <form [formGroup]="myForm">
            <div class="data-transfer-div">
                <div class="grouped-divs">
                    <mat-form-field appearance="fill">
                        <mat-label>VendorId</mat-label>
                        <input [formControlName]="'vendorId'" matInput
                               placeholder="vendorId" required>
                    </mat-form-field>
                    <br>
                    <mat-form-field appearance="fill">
                        <mat-label>MessageId</mat-label>
                        <input [formControlName]="'messageId'" matInput
                               placeholder="vendorId" required>
                    </mat-form-field>
                </div>

                <div class="data-field-div">
                    <mat-form-field appearance="fill" class="data-form-field">
                        <mat-label>Data</mat-label>
                        <textarea (keyup)="onDataChange()" [formControlName]="'data'"
                                  placeholder="Insert your JSON object here..." matInput cols=50 rows=10
                                  class="data-textarea"></textarea>
                    </mat-form-field>
                </div>
            </div>
        </form>

    </div>
    <div class="btn-holder">
        <button mat-button mat-dialog-close class="cancel-button" (click)="discardChanges()">Cancel</button>
        <button mat-flat-button class="action-button" (click)="sendDataTransfer()">
            Send
        </button>
    </div>
</div>
