import {Component, OnInit} from '@angular/core';
import {MatDialogRef} from "@angular/material/dialog";

@Component({
    selector: 'app-logout-dialog',
    templateUrl: './logout-dialog.component.html',
    styleUrls: ['./logout-dialog.component.css']
})
export class LogoutDialogComponent implements OnInit {

    constructor(public dialogRef: MatDialogRef<LogoutDialogComponent>) {
    }

    ngOnInit(): void {
    }

    simpleLogout() {
        this.closeDialog('Simple')
    }

    extendedLogout() {
        this.closeDialog('Extended');
    }

    closeDialog(choice: 'Simple' | 'Extended') {
        this.dialogRef.close(choice);
    }
}
