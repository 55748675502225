import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {Observable} from "rxjs";
import {Router} from "@angular/router";
import {tap} from "rxjs/operators";
import {MatSnackBar} from "@angular/material/snack-bar";
import {SNACKBAR_DURATION} from "../../shared/utils/rest-utils.constants";

@Injectable({
    providedIn: 'root'
})
export class AuthenticationInterceptorService implements HttpInterceptor {

    constructor(private router: Router, private snackBar: MatSnackBar) {
    }

    public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request.clone({
            withCredentials: true,
            headers: request.headers.set('X-Requested-With', 'XMLHttpRequest')
        }))
        .pipe(tap(() => {
            },
            (err) => {
                if (err instanceof HttpErrorResponse) {
                    if (err.status !== 401) {
                        return;
                    }
                    localStorage.clear();
                    if (this.router.url !== '/login') {
                        this.router.navigateByUrl("/login");
                        this.snackBar.open('Your session has expired!', 'OK', {
                            duration: SNACKBAR_DURATION,
                            panelClass: ['failure-snackbar']
                        });
                    }
                }
            }));
    }

}
