<div class="ev-certificates-table">
    <div class="ev-certificates-header">
        <button mat-icon-button matTooltip="Get 15118ISO Certificate" class="get-cert-button" onclick="this.blur()"
                (click)="send15118ISOCertificate()">
            <mat-icon class="get-cert-icon">note_add</mat-icon>
            <label>Install Certificate</label>
        </button>
    </div>
    <div class="mat-elevation-z8">
        <table mat-table [dataSource]="dataSource">
            <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef>Id</th>
                <td mat-cell *matCellDef="let row"> {{row.id}} </td>
            </ng-container>

            <ng-container matColumnDef="Certificate">
                <th mat-header-cell *matHeaderCellDef>Certificate</th>
                <td mat-cell *matCellDef="let row">
                    <a class="operations" (click)="openMessageInfoDialog('View Certificate', row.id)">Display
                        Certificate</a>
                </td>
            </ng-container>

            <ng-container matColumnDef="Actions">
                <th mat-header-cell *matHeaderCellDef>Actions</th>
                <td mat-cell *matCellDef="let row">
                    <button mat-flat-button class="update-button" (click)="update15118ISOCertificate(row.id)">Update
                    </button>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </div>
</div>
