import {AfterViewInit, Component, ViewChild} from '@angular/core';
import {MatTableDataSource} from "@angular/material/table";
import {IdTag} from "../../../shared/models/id-tag";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {MatDialog} from "@angular/material/dialog";
import {UserService} from "../../../core/services/user.service";
import {IdTagDialogComponent} from "./id-tag-dialog/id-tag-dialog.component";
import {DialogInterface} from "../../../shared/interfaces/dialog-interface";
import {Subscription} from "rxjs";
import {ConfirmationDialogComponent} from "../../confirmation-dialog/confirmation-dialog.component";

@Component({
    selector: 'app-id-tag-tab',
    templateUrl: './id-tag-tab.component.html',
    styleUrls: ['./id-tag-tab.component.css']
})
export class IdTagTabComponent implements AfterViewInit {

    /**
     * Subscriptions used by this instance
     */
    getCurrentUserIdTagsSubscription: Subscription;

    /**
     * Table settings
     */
    displayedColumns: string[] = ['id', 'idTag', 'action'];
    dataSource: MatTableDataSource<IdTag>;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;

    constructor(public dialog: MatDialog, private userService: UserService) {
    }

    ngAfterViewInit() {
        if (this.getCurrentUserIdTagsSubscription) {
            this.getCurrentUserIdTagsSubscription.unsubscribe();
        }

        this.getCurrentUserIdTagsSubscription = this.userService.getCurrentUserIdTags().subscribe(
            (response: IdTag[]) => {
                this.dataSource = new MatTableDataSource(response);
                this.dataSource.paginator = this.paginator;
                this.dataSource.sort = this.sort;
            }
        );
    }

    applyFilter(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.dataSource.filter = filterValue.trim().toLowerCase();

        if (this.dataSource.paginator) {
            this.dataSource.paginator.firstPage();
        }
    }

    openDeleteIdTagDialog(id?: string): void {
        let dialogRef;
        const dialogInterface: DialogInterface = {
            dialogHeader: 'Delete Id Tag',
            dialogContent: 'Are you sure you want to delete the selected Id Tag',
            cancelButtonLabel: 'Cancel',
            confirmButtonLabel: 'Yes',
            idTagId: id ? id : '',
            deleteIdTag: true
        };
        dialogRef = this.dialog.open(ConfirmationDialogComponent, {
            width: '300px',
            data: dialogInterface,
        });
        dialogRef.afterClosed().subscribe(
            (response: any) => {
                if (response) {
                    this.refresh();
                }
            }
        );
    }

    openCreateIdTagDialog(): void {
        let dialogRef;

        const dialogInterface: DialogInterface = {
            dialogHeader: 'Create new Id Tag',
            dialogContent: '',
            cancelButtonLabel: 'Cancel',
            confirmButtonLabel: 'Add',
            idTagId: ''
        };
        dialogRef = this.dialog.open(IdTagDialogComponent, {
            width: '500px',
            data: dialogInterface
        });

        dialogRef.afterClosed().subscribe(
            (response: any) => {
                if (response) {
                    this.refresh();
                }
            }
        );
    }

    refresh(): void {
        if (this.getCurrentUserIdTagsSubscription) {
            this.getCurrentUserIdTagsSubscription.unsubscribe();
        }

        this.getCurrentUserIdTagsSubscription = this.userService.getCurrentUserIdTags().subscribe(
            (response: IdTag[]) => {
                this.dataSource.data = response;
            }
        );
    }

    ngOnDestroy(): void {
        if (this.getCurrentUserIdTagsSubscription) {
            this.getCurrentUserIdTagsSubscription.unsubscribe();
        }
    }
}
