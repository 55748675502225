<mat-card class="dashboard-card {{stationStatus === 'Accepted' ? 'dashboard-card-online' : ''}}">
    <div class="mat-stepper-div">
        <div class="div-station-statuses">

            <div class="span-and-status">
                <span class="station-status-span"
                      [ngClass]="isBootNotSent && stationStatus === 'Unknown' ? 'offline-span' : ''"
                ></span>
                <h5 class="station-status"
                    [ngStyle]="{'color': isBootNotSent && stationStatus === 'Unknown' ? 'darkslategrey' : ''}">
                    Offline</h5>
            </div>

            <div class="mat-divider-div">
                <mat-divider class="mat-vertical-divider-between-statuses" vertical></mat-divider>
            </div>

            <div class="span-and-status">
                <div class="station-status-span"
                     [ngClass]="!isBootNotSent ? 'spinner' : ''">
                </div>
                <h5 class="station-status"
                    [ngStyle]="{'color': !isBootNotSent? '#80bc24' : ''}">Boot sent</h5>
            </div>

            <div class="mat-divider-div">
                <mat-divider class="mat-vertical-divider-between-statuses" vertical></mat-divider>
            </div>

            <div class="span-and-status">
                <span class="station-status-span"
                      [ngClass]="{'accepted-span': stationStatus === 'Accepted', 'rejected-span': stationStatus === 'Rejected',
                      'pending-span': stationStatus === 'Pending'}"></span>
                <h5 class="station-status"
                    [ngStyle]="{'color': stationStatus === 'Accepted'? '#005691' : (( stationStatus === 'Rejected') ? 'orange' : ( stationStatus === 'Pending' ? '#D2B55B' : ''))}">
                    {{stationStatus}}
                </h5>
            </div>
        </div>
        <mat-divider class="mat-vertical-divider" vertical></mat-divider>
    </div>

    <div class="mat-card-div">
        <mat-card-header class="header-title">
            <button (click)="stopStation(station.chargingStationIdentityKey)"
                    [disabled]="!station.online"
                    class="header-action-button power-off-button"
                    matTooltip="Close connection" mat-icon-button alt="" onclick="this.blur()">
                <i class="fa fa-power-off"></i>
            </button>

            <button (click)="openDeleteDialog()"
                    class="header-action-button delete-button"
                    matTooltip="Delete station" mat-icon-button alt="" onclick="this.blur()">
                <i class="fa fa-times"></i>
            </button>

            <mat-card-title class="card-title" style="cursor: pointer"
                            routerLink="/stations/{{station.chargingStationIdentityKey}}">
                #{{station.chargingStationIdentityKey}}

            </mat-card-title>
        </mat-card-header>

        <mat-card-content class="dashboard-card-content">
            <div class="station-info">
                <div class="station-attribute">
                    <div class="attribute-name"> Charge point model:</div>
                    <div class="attribute-value">{{station.chargePointModel}}</div>
                </div>
                <div class="station-attribute">
                    <div class="attribute-name"> Charge point vendor:</div>
                    <div class="attribute-value"> {{station.chargePointVendor}}</div>
                </div>
                <div class="station-attribute">
                    <div class="attribute-name"> Ocpp version:</div>
                    {{ test_versions.get(station.protocolVersion)}}
                </div>
                <div class="station-attribute url-attribute">
                    <img src="/assets/img/ref.png" class="attribute-icon" matTooltip="Copy URL"
                         (click)="copiedToClipboard()"
                         [cdkCopyToClipboard]="station.centralSystemUrl">
                    <div class="special-attribute-name"> System URL:</div>
                    <div class="attribute-value system-url-value"
                         (click)="alterSystemURLText()"
                         [ngClass]="{'show-less': showShortSystemURL, 'show-more': !showShortSystemURL}">
                        {{station.centralSystemUrl}}
                    </div>
                </div>
                <div class="station-attribute sockets">
                    <div class="attribute-name"> Sockets:</div>
                    <button *ngFor="let socket of station.socketList" class="socket-icon" mat-mini-fab
                            matTooltip="{{socket.status}}" matTooltipPosition='below'
                            [ngStyle]="{'background-color': getStatusColor(socket)}">
                    </button>
                </div>
            </div>
            <button mat-button class="action-button" [disabled]="isBootDisabled"
                    (click)="sendBootNotification(station.chargingStationIdentityKey)">Boot
            </button>
        </mat-card-content>
    </div>
</mat-card>
