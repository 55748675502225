import {ReplaySubject} from "rxjs";
import {Station} from "../../shared/models/station.model";
import {HttpClient} from "@angular/common/http";
import {BASE_URL, HTTP_METHOD_HEADERS} from "../../shared/utils/rest-utils.constants";
import {Injectable} from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class StationStoreService {

    private _stationsURL = BASE_URL + '/stations';

    private _stationList: ReplaySubject<Station[]> = new ReplaySubject<Station[]>(1);
    private _stationListSize: ReplaySubject<number> = new ReplaySubject<number>(1);

    stationList$ = this._stationList.asObservable();
    stationListSize$ = this._stationListSize.asObservable();

    constructor(private httpClient: HttpClient) {
        this.loadInitialStationData();
    }

    loadInitialStationData(): void {
        this.httpClient.get<Station[]>(this._stationsURL, HTTP_METHOD_HEADERS).subscribe(stationsList => {
            this._stationList.next(stationsList);
            this._stationListSize.next(stationsList.length);
        });
    }

    setStationListData(data: Station[]): void {
        this._stationList.next(data);
        this._stationListSize.next(data.length);
    }

}
