import {Component, OnInit, QueryList, ViewChildren} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Station} from "../../shared/models/station.model";
import {StationService} from "../../core/services/station.service";
import {Subscription} from "rxjs";
import {EditStationDialogComponent} from "./edit-station-dialog/edit-station-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {DialogInterface} from "../../shared/interfaces/dialog-interface";
import {ConfirmationDialogComponent} from "../confirmation-dialog/confirmation-dialog.component";
import * as SockJS from "sockjs-client";
import * as Stomp from "stompjs";
import {environment} from "../../../environments/environment";
import {CALLBACK_DURATION, SNACKBAR_DURATION, VERSIONS_MAP, WS_TOPIC} from "../../shared/utils/rest-utils.constants";
import {MatSnackBar} from "@angular/material/snack-bar";
import {IdTag} from "../../shared/models/id-tag";
import {UserService} from "../../core/services/user.service";
import {Socket} from "../../shared/models/socket.model";
import {SocketComponent} from "./socket/socket.component";
import {DataExchangeService} from "../../core/services/data-exchange.service";
import {MatSlideToggleChange} from "@angular/material/slide-toggle";
import {DataTransferDialogComponent} from "../../shared/modals/data-transfer-dialog/data-transfer-dialog.component";

@Component({
    selector: 'app-station-page',
    templateUrl: './station-page.component.html',
    styleUrls: ['./station-page.component.css', '../stations-dashboard/stations-log.component.css']
})
export class StationPageComponent implements OnInit {

    /**
     * Subscriptions used by this instance
     */
    getStationByChargingStationIdentityKeySubscription: Subscription;
    deleteStationByIdentityKey: Subscription;
    changeSocketStatusSubscription: Subscription;
    stationChangedSubscription: Subscription;
    versions_map = VERSIONS_MAP;

    /**
     *  Used for WS connection
     */
    stompClient: any;
    webSocketEndPoint: string = environment.apiURL + '/ws';

    chargingStationIdentityKey: string;
    showShortSystemURL: boolean = true;
    station: Station;
    latestModifiedChildSocketComponent: SocketComponent;
    idTagResponse: any;
    idTags: IdTag[];
    isCalibrationLawReady: boolean;
    plugAndCharge: boolean | undefined;

    @ViewChildren(SocketComponent)
    private socketComponents: QueryList<SocketComponent>;

    constructor(private router: Router, public route: ActivatedRoute, private stationService: StationService,
                private userService: UserService, public dialog: MatDialog, private snackBar: MatSnackBar,
                private messageService: DataExchangeService) {
        this.chargingStationIdentityKey = <string>this.route.snapshot.paramMap.get('chargingStationIdentityKey');
    }

    ngAfterViewInit() {
        this.userService.getCurrentUserIdTags().subscribe(
            (response: IdTag[]) => {
                this.idTags = response;
            }
        );
    }

    ngOnInit(): void {

        if (this.getStationByChargingStationIdentityKeySubscription) {
            this.getStationByChargingStationIdentityKeySubscription.unsubscribe();
        }
        if (this.changeSocketStatusSubscription) {
            this.changeSocketStatusSubscription.unsubscribe();
        }
        if (this.deleteStationByIdentityKey) {
            this.deleteStationByIdentityKey.unsubscribe();
        }

        this.fetchStation();
        this.stationChangedSubscription = this.messageService.stationChangedAnnounced$.subscribe(station => {
            this.onStationChanged(station);
        });
        this._connect();

    }

    onStationChanged(station: Station): void {
        if (station.chargingStationIdentityKey == this.station.chargingStationIdentityKey) {
            this.station = station;
        }
    }

    fetchStation(): void {
        if (this.getStationByChargingStationIdentityKeySubscription) {
            this.getStationByChargingStationIdentityKeySubscription.unsubscribe();
        }
        this.getStationByChargingStationIdentityKeySubscription = this.stationService.getStationByChargingStationIdentityKey(this.chargingStationIdentityKey).subscribe((data: Station) => {
            this.station = data;
            this.isCalibrationLawReady = data.calibrationLawReady;
            this.plugAndCharge = data.plugAndCharge;
        }, error => {
            console.log(error)
            this.router.navigateByUrl('/unauthorized');
        });
    }

    openEditDialog(): void {
        const dialogRef = this.dialog.open(EditStationDialogComponent, {
            width: '800px',
            data: {data: this.station}
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.ngOnInit();
            }
        });
    }

    reloadSocket(): void {
        if (this.getStationByChargingStationIdentityKeySubscription) {
            this.getStationByChargingStationIdentityKeySubscription.unsubscribe();
        }
        this.getStationByChargingStationIdentityKeySubscription = this.stationService.getStationByChargingStationIdentityKey(this.chargingStationIdentityKey).subscribe((data: Station) => {
            this.station = data;
        }, error => {
            console.log(error);
        });
    }

    openDeleteDialog() {
        const dialogInterface: DialogInterface = {
            dialogHeader: 'Delete station',
            dialogContent: 'Are you sure you want to delete station ',
            cancelButtonLabel: 'Cancel',
            confirmButtonLabel: 'Yes',
            station: this.station,
            deleteStation: true
        };
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
            width: '300px',
            data: dialogInterface,
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.router.navigateByUrl('/stations');
            }
        });
    }

    openDataTransferDialog(): void {
        const dialogRef = this.dialog.open(DataTransferDialogComponent, {
            width: '800px',
            data: this.station,
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.ngOnInit();
            }
        });
    }

    sendStationChangedNotification(station: Station): void {
        this.messageService.announceStationChanged(station);
    }

    updatedLastUsedSocket(socket: Socket): void {
        this.latestModifiedChildSocketComponent = this.socketComponents.find((element) => element.socket === socket)!;
    }

    onSocketStatusChanged(socket: Socket): void {
        this.changeSocketStatusSubscription = this.stationService.changeSocketStatus(this.station.chargingStationIdentityKey, socket.connectorId, socket.status)
        .subscribe((res: Socket) => {
        }, error => {
            this.getStationByChargingStationIdentityKeySubscription = this.stationService.getStationByChargingStationIdentityKey(this.chargingStationIdentityKey).subscribe((data: Station) => {
                this.station = data;
            });
            this.snackBar.open("An error occurred.", "Dismiss", {
                duration: SNACKBAR_DURATION,
                panelClass: ['failure-snackbar']
            });
        });
    }

    changeCalibrationLawReadyForStation($event: MatSlideToggleChange) {
        let stationToBeUpdated = {...this.station};
        stationToBeUpdated.calibrationLawReady = $event.checked;

        const dialogInterface: DialogInterface = {
            dialogHeader: $event.checked ? 'Enable calibration law' : 'Disable calibration law',
            dialogContent: "Are you sure you want to " + ($event.checked ? " enable " : " disable ") +
                "calibration law ready on station ",
            cancelButtonLabel: 'Cancel',
            confirmButtonLabel: 'Yes',
            station: stationToBeUpdated,
            updateStation: true
        };

        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
            width: '500px',
            data: dialogInterface,
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.ngOnInit();
            } else {
                this.isCalibrationLawReady = !$event.checked;
                $event.source.checked = this.isCalibrationLawReady;
            }
        });
    }

    alterSystemURLText() {
        this.showShortSystemURL = !this.showShortSystemURL;
    }

    copyToClipboard(): void {
        this.snackBar.open('Copied to clipboard!', 'Dismiss', {
            duration: SNACKBAR_DURATION,
            panelClass: ['successful-snackbar']
        });
    }

    _connect() {
        let ws = new SockJS(this.webSocketEndPoint);
        this.stompClient = Stomp.over(ws);
        this.stompClient.debug = null;
        this.stompClient.connect({}, (frame: any) => {
            this.stompClient.subscribe(WS_TOPIC + "/transaction/" + this.station.id, (response: any) => {
                this.handleReceivedTransactionNotification(response);
            });
            this.stompClient.subscribe(WS_TOPIC + "/transaction/" + this.station.id + "/idTagStatus", (response: any) => {
                this.handleReceivedIdTagStatus(response);
            });
        }, this.errorCallBack.bind(this));
    }

    handleReceivedTransactionNotification(message: any) {
        setTimeout(() => {
            let latestSocketTransaction = JSON.parse(message.body);
            let socketToRefresh = this.socketComponents.find((element) => element.socket.id == latestSocketTransaction.socketId)!;

            if (latestSocketTransaction.transactionType === 'StartTransaction') {
                socketToRefresh.getLatestSocketTransactionInfo();
            } else {
                socketToRefresh.stoppedLatestSocketTransaction()
            }
            socketToRefresh.changeActiveSpinner(false);
        }, CALLBACK_DURATION);
    }

    handleReceivedIdTagStatus(message: any): void {
        let idTagValidity = message.body;

        message.body === 'Valid' ? this.snackBar.open(idTagValidity + " RFID!", "Dismiss", {
                duration: SNACKBAR_DURATION,
                panelClass: ['successful-snackbar']
            }) :
            this.snackBar.open(idTagValidity + " RFID!", "Dismiss", {
                duration: SNACKBAR_DURATION,
                panelClass: ['failure-snackbar']
            });
        this.latestModifiedChildSocketComponent.changeActiveSpinner(false);
    }

    getCertificateStatus() {
        this.stationService.getCertificateStatus(this.station.chargingStationIdentityKey).subscribe(
            (response) => {
                this.snackBar.open(response.message, "Dismiss", {
                    duration: SNACKBAR_DURATION,
                    panelClass: ['successful-snackbar']
                });
            },
            (response) => {
                this.snackBar.open(response.error.message, "Dismiss", {
                    duration: SNACKBAR_DURATION,
                    panelClass: ['failure-snackbar']
                });

            });
    }

    // on error, schedule a reconnection attempt
    errorCallBack(error: any) {
        console.log("error: " + error);
        setTimeout(() => {
            this._connect();
        }, 5000);
    }

    ngOnDestroy(): void {
        if (this.getStationByChargingStationIdentityKeySubscription) {
            this.getStationByChargingStationIdentityKeySubscription.unsubscribe();
        }
        if (this.changeSocketStatusSubscription) {
            this.changeSocketStatusSubscription.unsubscribe();
        }
        if (this.deleteStationByIdentityKey) {
            this.deleteStationByIdentityKey.unsubscribe();
        }
        if (this.stationChangedSubscription) {
            this.stationChangedSubscription.unsubscribe();
        }
        if (this.stompClient !== null && this.stompClient !== undefined && this.stompClient === 'CONNECTED') {
            this.stompClient.disconnect();
        }
    }

    enablePlugAndCharge($event: MatSlideToggleChange) {
        let stationToBeUpdated = {...this.station};
        stationToBeUpdated.plugAndCharge = $event.checked;

        const dialogInterface: DialogInterface = {
            dialogHeader: $event.checked ? 'Enable plug and charge' : 'Disable plug and charge',
            dialogContent: "Are you sure you want to " + ($event.checked ? " enable " : " disable ") +
                "plug and charge on station ",
            cancelButtonLabel: 'Cancel',
            confirmButtonLabel: 'Yes',
            station: stationToBeUpdated,
            updateStation: true
        };

        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
            width: '500px',
            data: dialogInterface,
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.ngOnInit();
            } else {
                this.plugAndCharge = !$event.checked;
                $event.source.checked = this.plugAndCharge;
            }
        });
    }
}
