import {APP_INITIALIZER, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {NewStationFormComponent} from './components/stations-dashboard/new-station-form/new-station-form.component';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {StationsLogComponent} from './components/stations-dashboard/stations-log.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatButtonModule} from "@angular/material/button";
import {MatTabsModule} from "@angular/material/tabs";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatIconModule} from "@angular/material/icon";
import {MatInputModule} from "@angular/material/input";
import {NavComponent} from './layout/nav/nav.component';
import {LayoutModule} from '@angular/cdk/layout';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatListModule} from '@angular/material/list';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatCardModule} from '@angular/material/card';
import {MatMenuModule} from '@angular/material/menu';
import {MatBadgeModule} from '@angular/material/badge';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {StationPageComponent} from './components/station-page/station-page.component';
import {MatButtonToggleModule} from "@angular/material/button-toggle";
import {EditStationDialogComponent} from './components/station-page/edit-station-dialog/edit-station-dialog.component';
import {MatDialogModule} from "@angular/material/dialog";
import {MatSelectModule} from "@angular/material/select";
import {SocketComponent} from "./components/station-page/socket/socket.component";
import {ConfirmationDialogComponent} from './components/confirmation-dialog/confirmation-dialog.component';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {LoginPageComponent} from './components/login-page/login-page.component';
import {AuthenticationInterceptorService} from "./core/interceptor/authentication-interceptor.service";
import {ErrorPageComponent} from './components/error-page/error-page.component';
import {AppLayoutComponent} from './layout/app-layout/app-layout.component';
import {LoginLayoutComponent} from './layout/login-layout/login-layout.component';
import {AdminPageComponent} from './components/admin-page/admin-page.component';
import {MatTableModule} from "@angular/material/table";
import {MatPaginatorModule} from '@angular/material/paginator';
import {IdTagDialogComponent} from './components/admin-page/id-tag-tab/id-tag-dialog/id-tag-dialog.component';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {StationComponent} from './components/stations-dashboard/station/station.component';
import {NewUserDialogComponent} from './components/admin-page/users-tab/new-user-dialog/new-user-dialog.component';
import {IdTagTabComponent} from './components/admin-page/id-tag-tab/id-tag-tab.component';
import {UsersTabComponent} from './components/admin-page/users-tab/users-tab.component';
import {EnvService} from "./core/services/env.service";
import {DragDropModule} from "@angular/cdk/drag-drop";
import {MonitoringTabComponent} from './components/auto-pilot/monitoring-tab/monitoring-tab/monitoring-tab.component';
import {EditDefaultStationComponent} from "./components/stations-dashboard/edit-default-station/edit-default-station.component";
import {MatSlideToggleModule} from "@angular/material/slide-toggle";
import {MatChipsModule} from "@angular/material/chips";
import {ClipboardModule} from "@angular/cdk/clipboard";
import {OperationMessageComponent} from "./shared/modals/operation-message/operation-message.component";
import {DataTransferDialogComponent} from './shared/modals/data-transfer-dialog/data-transfer-dialog.component';
import {CertificatesTabComponent} from './components/station-page/certificates-tab/certificates-tab.component';
import {AutoPilotTableComponent} from './components/auto-pilot/auto-pilot-page/auto-pilot-table/auto-pilot-table.component';
import {AutoPilotPageComponent} from './components/auto-pilot/auto-pilot-page/auto-pilot-page.component';
import {MatExpansionModule} from "@angular/material/expansion";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {EvCertificatesTabComponent} from './components/station-page/ev-certificates-tab/ev-certificates-tab.component';
import {MatSortModule} from "@angular/material/sort";
import { LogoutDialogComponent } from './shared/modals/logout-dialog/logout-dialog.component';

@NgModule({
    declarations: [
        AppComponent,
        NewStationFormComponent,
        StationsLogComponent,
        NavComponent,
        StationPageComponent,
        EditStationDialogComponent,
        SocketComponent,
        ConfirmationDialogComponent,
        LoginPageComponent,
        ErrorPageComponent,
        AppLayoutComponent,
        LoginLayoutComponent,
        AdminPageComponent,
        IdTagDialogComponent,
        StationComponent,
        NewUserDialogComponent,
        IdTagTabComponent,
        UsersTabComponent,
        EditDefaultStationComponent,
        MonitoringTabComponent,
        OperationMessageComponent,
        AutoPilotTableComponent,
        AutoPilotPageComponent,
        DataTransferDialogComponent,
        CertificatesTabComponent,
        EvCertificatesTabComponent,
        LogoutDialogComponent,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        DragDropModule,
        MatButtonModule,
        MatTabsModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        LayoutModule,
        MatToolbarModule,
        MatTooltipModule,
        MatSidenavModule,
        MatListModule,
        MatGridListModule,
        MatCardModule,
        MatMenuModule,
        MatBadgeModule,
        MatSnackBarModule,
        MatProgressSpinnerModule,
        MatButtonToggleModule,
        FormsModule,
        MatDialogModule,
        MatSelectModule,
        MatAutocompleteModule,
        MatTableModule,
        MatPaginatorModule,
        MatSlideToggleModule,
        ClipboardModule,
        MatPaginatorModule,
        MatChipsModule,
        MatExpansionModule,
        MatCheckboxModule,
        MatSortModule,
    ],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: (envService: EnvService) => () => envService.init(),
            deps: [EnvService],
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthenticationInterceptorService,
            multi: true
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
