<div>
    <mat-table [dataSource]="dataSource" #autopilot
               class="mat-elevation-z8 schedule-table" cdkDropList
               [cdkDropListData]="dataSource.data"
               (cdkDropListDropped)="dropItem($event)" id="autopilot">
        <ng-container matColumnDef="position">
            <mat-header-cell *matHeaderCellDef> No.</mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.position}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="operationName">
            <mat-header-cell *matHeaderCellDef> Operation</mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.operationName}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="station">
            <mat-header-cell *matHeaderCellDef> Station</mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.station.chargingStationIdentityKey}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="socket">
            <mat-header-cell *matHeaderCellDef> Socket</mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.socket}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="actions">
            <mat-header-cell *matHeaderCellDef> Actions</mat-header-cell>
            <mat-cell *matCellDef="let element">
                <i (click)="arrowUp(element)" class="fa fa-arrow-up action-icon"></i>
                <i (click)="arrowDown(element)" class="fa fa-arrow-down action-icon"></i>
                <i title="Edit operation" (click)="openDrawer(element)" class="fa fa-pencil-square-o action-icon"></i>
                <i title="Remove operation" (click)="deleteItem(element)"
                   class="fa fa-times action-icon delete-icon"></i>
            </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;" cdkDrag></mat-row>
    </mat-table>

    <br>

    <mat-table class="new-operation-table">
        <ng-container matColumnDef="position">
            <mat-header-cell class="new-operation-cell" *matHeaderCellDef>
                {{maxIndex + 1}}
            </mat-header-cell>
        </ng-container>
        <ng-container matColumnDef="operationName">
            <mat-header-cell class="new-operation-cell" *matHeaderCellDef>
                <mat-form-field>
                    <mat-label>Operation</mat-label>
                    <mat-select [(value)]="selectedOperation">
                        <mat-option *ngFor="let operation of operations" [value]="operation">
                            {{operation}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </mat-header-cell>
        </ng-container>
        <ng-container matColumnDef="station">
            <mat-header-cell class="new-operation-cell" *matHeaderCellDef>
                <mat-form-field>
                    <mat-label>Station</mat-label>
                    <mat-select *ngIf="selectedProtocol == 'V16'" [(value)]="selectedStation"
                                (selectionChange)="clearSelectedSocket()">
                        <mat-option *ngFor="let station of stationsV16" [value]="station">
                            {{station.chargingStationIdentityKey}}
                        </mat-option>
                    </mat-select>
                    <mat-select *ngIf="selectedProtocol == 'V201'" [(value)]="selectedStation"
                                (selectionChange)="clearSelectedSocket()">
                        <mat-option *ngFor="let station of stationsV201" [value]="station">
                            {{station.chargingStationIdentityKey}}
                        </mat-option>
                    </mat-select>
                    <mat-select *ngIf="selectedProtocol == 'V16TLS'" [(value)]="selectedStation"
                                (selectionChange)="clearSelectedSocket()">
                        <mat-option *ngFor="let station of stationsV16TLS" [value]="station">
                            {{station.chargingStationIdentityKey}}
                        </mat-option>
                    </mat-select>
                    <mat-select *ngIf="selectedProtocol == 'V201TLS'" [(value)]="selectedStation"
                                (selectionChange)="clearSelectedSocket()">
                        <mat-option *ngFor="let station of stationsV201TLS" [value]="station">
                            {{station.chargingStationIdentityKey}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </mat-header-cell>
        </ng-container>
        <ng-container matColumnDef="socket">
            <mat-header-cell class="new-operation-cell" *matHeaderCellDef>
                <mat-form-field>
                    <mat-label>Socket</mat-label>
                    <mat-select [(value)]="selectedSocket"
                                [disabled]="selectedStation == null || selectedOperation=='BootNotification'">
                        <div *ngIf="selectedStation != null">
                            <mat-option
                                *ngFor="let socket of selectedStation.socketList" [value]="socket.connectorId">
                                {{socket.connectorId}}
                            </mat-option>
                        </div>
                    </mat-select>
                </mat-form-field>
            </mat-header-cell>
        </ng-container>
        <ng-container matColumnDef="actions">
            <mat-header-cell class="new-operation-cell" *matHeaderCellDef>
                <button mat-flat-button class="new-operation-button"
                        [disabled]="selectedOperation =='BootNotification' && selectedStation == null ||
                                    selectedOperation !='BootNotification' && (selectedStation == null || selectedSocket == undefined)"
                        (click)="addNewOperation()">
                    Add
                </button>
            </mat-header-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    </mat-table>
</div>
