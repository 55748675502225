import {Component, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {UserService} from "../../core/services/user.service";

@Component({
    selector: 'app-login-page',
    templateUrl: './login-page.component.html',
    styleUrls: ['./login-page.component.css']
})
export class LoginPageComponent implements OnInit {

    loginForm: FormGroup;
    username: string;
    password: string;

    constructor(private userService: UserService, private router: Router) {
    }

    ngOnInit(): void {
        this.initLoginForm();
    }

    initLoginForm(): void {
        this.loginForm = new FormGroup({
            username: new FormControl('', Validators.required),
            password: new FormControl('', Validators.required)
        });
    }

    login(): void {
        this.username = this.loginForm.value.username;
        this.password = this.loginForm.value.password;
        this.userService.login(this.username, this.password).subscribe((res) => {
            localStorage.setItem('principalName', this.username);
            sessionStorage.setItem("scheduleButtonDisabled", JSON.stringify(false));
            this.router.navigateByUrl('/stations');
        }, (error) => {
            this.initLoginForm();
        });
    }

}
