import {Component, OnInit} from '@angular/core';
import {ActivationEnd, Router} from "@angular/router";

@Component({
    selector: 'app-error-page',
    templateUrl: './error-page.component.html',
    styleUrls: ['./error-page.component.css']
})
export class ErrorPageComponent implements OnInit {

    errorCode: number;
    errorMessage: string;

    constructor(private router: Router) {
        this.router.events.subscribe((event) => {
            if (event instanceof ActivationEnd) {
                if (event.snapshot.data['errorCode']) {
                    this.errorCode = event.snapshot.data['errorCode'];
                }
                if (event.snapshot.data['errorMessage']) {
                    this.errorMessage = event.snapshot.data['errorMessage'];
                }
            }
        });
    }

    ngOnInit(): void {
    }

}
