import {Component, Input, OnInit} from '@angular/core';
import {SNACKBAR_DURATION} from "../../../shared/utils/rest-utils.constants";
import {StationService} from "../../../core/services/station.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {MatTableDataSource} from "@angular/material/table";
import {EVCertificate} from "../../../shared/models/ev-certificate";
import {MessageDialogInterface} from "../../../interfaces/message-dialog";
import {OperationMessageComponent} from "../../../shared/modals/operation-message/operation-message.component";
import {MatDialog} from "@angular/material/dialog";

@Component({
    selector: 'app-ev-certificates-tab',
    templateUrl: './ev-certificates-tab.component.html',
    styleUrls: ['./ev-certificates-tab.component.css']
})
export class EvCertificatesTabComponent implements OnInit {

    @Input() stationId: string;

    displayedColumns: string[] = ['id', 'Certificate', 'Actions'];
    dataSource: MatTableDataSource<EVCertificate>;

    constructor(private stationService: StationService, private snackBar: MatSnackBar, public dialog: MatDialog) {
    }

    ngOnInit(): void {
        this.stationService.get15118ISOCertificatesIds(this.stationId).subscribe((response: any) => {
            this.dataSource = new MatTableDataSource<EVCertificate>(response);
        })
    }

    send15118ISOCertificate() {
        this.stationService.send15118ISOCertificates(this.stationId).subscribe((response: any) => {
                if (response.message === "Request sent successfully") {
                    this.snackBar.open('Request sent successfully', 'Dismiss', {
                        duration: SNACKBAR_DURATION,
                        panelClass: ['successful-snackbar']
                    });
                } else {
                    this.snackBar.open('Request not sent', 'Dismiss', {
                        duration: SNACKBAR_DURATION,
                        panelClass: ['failure-snackbar']
                    });
                }
            }
        );
    }

    update15118ISOCertificate(certificateId: string) {
        this.stationService.update15118ISOCertificates(this.stationId, certificateId).subscribe((response: any) => {
                console.log(response.message);
                if (response.message === "EVCertificate updated successfully") {
                    this.snackBar.open('Update request sent successfully', 'Dismiss', {
                        duration: SNACKBAR_DURATION,
                        panelClass: ['successful-snackbar']
                    });
                } else {
                    this.snackBar.open('Request rejected', 'Dismiss', {
                        duration: SNACKBAR_DURATION,
                        panelClass: ['failure-snackbar']
                    });
                }
            }
        );
    }

    openMessageInfoDialog(dialogHeader: string, certificateId: string): void {
        (this.stationService.get15118ISOCertificateToDisplay(certificateId)).subscribe((response: any) => {
                let rowInfo = ((response) ? response.message : "message not received");
                this.showDialog(rowInfo, dialogHeader);
            }
        );
    }

    private showDialog(rowInfo: string, dialogHeader: string) {
        const messageDialog: MessageDialogInterface = {
            dialogHeader: dialogHeader,
            dialogContent: rowInfo,
            cancelButtonLabel: ''
        };
        this.dialog.open(OperationMessageComponent, {
            width: '400px',
            data: messageDialog
        });
    }
}
