import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {BASE_URL, HTTP_METHOD_HEADERS} from "../../shared/utils/rest-utils.constants";
import {Transaction} from "../../shared/models/transaction.model";

@Injectable({
    providedIn: 'root'
})
export class TransactionService {

    constructor(private httpClient: HttpClient) {

    }

    private _stationsURL = BASE_URL + '/stations';
    private _transactionsURL = BASE_URL + '/stations/transactions';

    /**
     * Starts a new transaction
     *
     * @param connectorId
     * @param chargingStationIdentityKey
     * @param idTag
     */
    startNewTransaction(connectorId: number, chargingStationIdentityKey: string, idTag: string): Observable<any> {
        return this.httpClient.post(this._stationsURL + '/' + chargingStationIdentityKey + '/sockets/' + connectorId + '/transactions/' + idTag, HTTP_METHOD_HEADERS);
    }

    /**
     * Stops an ongoing transaction
     *
     * @param transactionId
     * @param idTagStop
     */
    stopTransaction(transactionId: number, idTagStop: string): Observable<any> {
        return this.httpClient.post(this._transactionsURL + "/" + transactionId + "/" + idTagStop, HTTP_METHOD_HEADERS);
    }

    /**
     * Fetches the latest ongoing transaction on a given socket
     *
     * @param chargingStationIdentityKey
     * @param connectorId
     * @returns {Observable<Transaction>}
     */
    getLatestSocketTransaction(chargingStationIdentityKey: string, connectorId: number): Observable<Transaction> {
        return this.httpClient.get<Transaction>(this._transactionsURL + "/latest/" + chargingStationIdentityKey + "/" + connectorId, HTTP_METHOD_HEADERS)
    }

    /**
     * Starts multiple transactions
     *
     * @param idTag
     */
    startMultipleTransactions(idTag: string): Observable<any> {
        return this.httpClient.post(this._transactionsURL + "/startAll/" + idTag, HTTP_METHOD_HEADERS)
    }

    /**
     * Stops all ongoing transactions
     */
    stopMultipleTransactions(): Observable<any> {
        return this.httpClient.post(this._transactionsURL + "/stopAll", HTTP_METHOD_HEADERS)
    }
}
