import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Subscription} from "rxjs";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {UserService} from "../../../../core/services/user.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {SNACKBAR_DURATION} from "../../../../shared/utils/rest-utils.constants";
import {RegistrationInfo} from "../../../../shared/models/registration-info";


@Component({
    selector: 'app-new-user-dialog',
    templateUrl: './new-user-dialog.component.html',
    styleUrls: ['./new-user-dialog.component.css']
})
export class NewUserDialogComponent implements OnInit {

    /**
     * Subscriptions used by this instance
     */
    createUserSubscription: Subscription;

    /**
     * New user related fields
     */
    createUserForm: FormGroup;
    createUserRequestPayload: RegistrationInfo;
    user: RegistrationInfo;

    constructor(public userService: UserService, public dialogRef: MatDialogRef<NewUserDialogComponent>,
                public dialog: MatDialog, public snackBar: MatSnackBar) {
        this.createUserRequestPayload = {
            username: '',
            password: '',
        }
    }

    ngOnInit(): void {
        this.createUserForm = new FormGroup({
            username: new FormControl('', Validators.required),
            password: new FormControl('', Validators.required),
        })
    }

    createUser(): void {
        this.createUserRequestPayload.username = this.createUserForm.get('username')?.value;
        this.createUserRequestPayload.password = this.createUserForm.get('password')?.value;

        if (this.createUserSubscription) {
            this.createUserSubscription.unsubscribe();
        }

        this.createUserSubscription = this.userService.register(this.createUserRequestPayload).subscribe({
                next: () => {
                    this.snackBar.open('User was successfully created!', 'Dismiss', {
                        duration: SNACKBAR_DURATION,
                        panelClass: ['successful-snackbar']
                    });
                    setTimeout(() => this.dialogRef.close(true), 500)
                },
                error: (error) => {
                    if (error.status != 401) {
                        this.snackBar.open('Username already in use!', 'Dismiss', {
                            duration: SNACKBAR_DURATION,
                            panelClass: ['failure-snackbar']
                        });
                    }
                },
                complete: () => {
                    this.dialogRef.close(true);
                }
            }
        )
    }

    ngOnDestroy(): void {
        if (this.createUserSubscription) {
            this.createUserSubscription.unsubscribe();
        }
    }
}
