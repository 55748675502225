import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {StationService} from "../../../core/services/station.service";
import {Station} from "../../models/station.model";
import {Subject, Subscription} from "rxjs";
import {MatSnackBar} from "@angular/material/snack-bar";
import {debounceTime} from "rxjs/operators";
import {SNACKBAR_DURATION} from "../../utils/rest-utils.constants";

@Component({
    selector: 'app-data-transfer-dialog',
    templateUrl: './data-transfer-dialog.component.html',
    styleUrls: ['./data-transfer-dialog.component.css']
})
export class DataTransferDialogComponent implements OnInit {

    dataTransferSubscription: Subscription;
    dataChangedSubscription: Subscription;

    myForm: FormGroup;
    station: Station;

    dataChanged: Subject<string> = new Subject<string>();
    debounceTime = 700;

    constructor(public dialogRef: MatDialogRef<DataTransferDialogComponent>, public formBuilder: FormBuilder,
                public stationService: StationService, @Inject(MAT_DIALOG_DATA) public data: any, private snackBar: MatSnackBar) {
        this.myForm = this.formBuilder.group({
            vendorId: ['', Validators.required],
            messageId: ['', Validators.required],
            data: ['', Validators.required],
        });
    }

    ngOnInit(): void {
        this.station = this.data;
        this.dataChangedSubscription = this.dataChanged
        .pipe(
            debounceTime(this.debounceTime),
        )
        .subscribe(() => {
            this.formatDataJson()
        });
    }

    sendDataTransfer(): void {

        if (this.dataTransferSubscription) {
            this.dataTransferSubscription.unsubscribe();
        }

        this.myForm.patchValue({
            data: this.myForm.value.data.replace(/[\r\n\t]/g, "")
        });

        this.dataTransferSubscription = this.stationService.sendDataTransferMessage(this.myForm.value, this.station.chargingStationIdentityKey).subscribe({
            next: (response) => {
                this.snackBar.open(response.message, "Dismiss", {
                    duration: SNACKBAR_DURATION,
                    panelClass: ['successful-snackbar']
                });
            },
            error: (response) => {
                this.snackBar.open(response.error.message, "Dismiss", {
                    duration: SNACKBAR_DURATION,
                    panelClass: ['failure-snackbar']
                });
                this.dialogRef.close(true);
            },
            complete: () => {
                this.dialogRef.close(true);
            }
        });

    }

    discardChanges(): void {
        this.dialogRef.close(true);
    }

    formatDataJson(): void {
        let dataField = this.myForm.value.data.replace(/\\/g, "");
        try {
            let obj = JSON.parse(dataField);
            let formattedDataField = JSON.stringify(obj, undefined, "\t");
            this.myForm.patchValue({
                data: formattedDataField
            });
        } catch (e) {
        }
    }

    onDataChange(): void {
        this.dataChanged.next();
    }

    ngOnDestroy(): void {
        if (this.dataTransferSubscription) {
            this.dataTransferSubscription.unsubscribe();
        }

        if (this.dataChangedSubscription) {
            this.dataChangedSubscription.unsubscribe();
        }
    }

}
