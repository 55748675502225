import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {MatTable, MatTableDataSource} from "@angular/material/table";
import {Station} from "../../../../shared/models/station.model";
import {Subscription} from "rxjs";
import {CdkDragDrop, moveItemInArray} from "@angular/cdk/drag-drop";
import {DataExchangeService} from "../../../../core/services/data-exchange.service";
import {AutoPilotTableData} from "../../../../shared/interfaces/auto-pilot-table-data";

@Component({
    selector: 'app-auto-pilot-table',
    templateUrl: './auto-pilot-table.component.html',
    styleUrls: ['./auto-pilot-table.component.css']
})
export class AutoPilotTableComponent implements OnInit {

    displayedColumns: string[] = ['position', 'operationName', 'station', 'socket', 'actions'];
    @Output() onRowEdit = new EventEmitter<any>();
    @Output() onNewOperation = new EventEmitter<any>();
    @Output() deletedRow = new EventEmitter<any>();
    @Output() disableClearOperationButton = new EventEmitter<any>();

    @Input() dataSource: MatTableDataSource<AutoPilotTableData>;
    @Input() stations: Station[];
    @Input() stationsV16: Station[];
    @Input() stationsV16TLS: Station[];
    @Input() stationsV201: Station[];
    @Input() stationsV201TLS: Station[];
    @Input() selectedProtocol: String;
    @Input() maxIndex: number;
    @Input() operations: string[];
    @ViewChild('autopilot') table: MatTable<AutoPilotTableData>;

    tableDataSourceChangedSubscription: Subscription;

    selectedOperation: string;
    selectedStation: Station;
    selectedSocket: number | undefined;

    constructor(private messageService: DataExchangeService) {
    }

    ngOnInit(): void {
        this.tableDataSourceChangedSubscription = this.messageService.updatedAutoPilotDataSourceAnnounced$.subscribe(() => {
            this.table.renderRows();
        });
    }

    arrowUp(element: any): void {
        const index = this.dataSource.data.indexOf(element, 0);

        if (index !== 0) {
            const b = this.dataSource.data[index - 1];
            this.dataSource.data[index - 1] = this.dataSource.data[index];
            this.dataSource.data[index] = b;

            this.table.renderRows();
            localStorage.setItem('dataSource', JSON.stringify(this.dataSource.data));
        }
    }

    arrowDown(element: any): void {
        const index = this.dataSource.data.indexOf(element, 0);

        if (index !== this.dataSource.data.length - 1) {
            const b = this.dataSource.data[index + 1];
            this.dataSource.data[index + 1] = this.dataSource.data[index];
            this.dataSource.data[index] = b;

            this.table.renderRows();
            localStorage.setItem('dataSource', JSON.stringify(this.dataSource.data));
        }
    }

    deleteItem(element: AutoPilotTableData): void {
        const index: number = this.dataSource.data.indexOf(element);
        if (index !== -1) {
            this.dataSource.data.splice(index, 1);
        }
        if (!this.dataSource.data || this.dataSource.data.length === 0) {
            this.disableClearOperationButton.emit();
        }
        this.table.renderRows();
        this.deletedRow.emit();
        localStorage.setItem('dataSource', JSON.stringify(this.dataSource.data));
    }

    openDrawer(element: any): void {
        this.onRowEdit.emit({...element});
    }

    dropItem(event: CdkDragDrop<AutoPilotTableData[]>) {
        moveItemInArray(this.dataSource.data, event.previousIndex, event.currentIndex);
        this.dataSource.data = event.container.data;
        localStorage.setItem('dataSource', JSON.stringify(this.dataSource.data));
    }

    addNewOperation(): void {
        this.onNewOperation.emit({
            position: this.maxIndex + 1,
            operationName: this.selectedOperation,
            station: this.selectedStation,
            socket: this.selectedSocket,
        });
    }

    clearSelectedSocket(): void {
        this.selectedSocket = undefined;
    }

    ngOnDestroy(): void {
        if (this.tableDataSourceChangedSubscription) {
            this.tableDataSourceChangedSubscription.unsubscribe();
        }
    }

}
