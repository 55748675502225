import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Station} from "../../../shared/models/station.model";
import {SNACKBAR_DURATION, SOCKET_STATUS_COLORS, VERSIONS_MAP} from "../../../shared/utils/rest-utils.constants";
import {Subscription} from "rxjs";
import {StationService} from "../../../core/services/station.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {environment} from "../../../../environments/environment";
import {DataExchangeService} from "../../../core/services/data-exchange.service";
import {Socket} from "../../../shared/models/socket.model";
import {DialogInterface} from "../../../shared/interfaces/dialog-interface";
import {ConfirmationDialogComponent} from "../../confirmation-dialog/confirmation-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {WebsocketService} from "../../../core/services/websocket.service";
import {WebSocketMessageDTO} from "../../../shared/models/websocket.dto";

@Component({
    selector: 'app-station',
    templateUrl: './station.component.html',
    styleUrls: ['./station.component.css']
})
export class StationComponent implements OnInit {

    /**
     * Subscriptions used by this instance
     */
    sendBootNotificationRequest: Subscription;
    getStationSubscription: Subscription;
    stopStationSubscription: Subscription;
    test_versions = VERSIONS_MAP;
    /**
     * Received from parent
     */
    @Input()
    station: Station;

    /**
     *  Used for WS connection
     */
    stompClient: any;
    webSocketEndPoint: string = environment.apiURL + '/ws';

    @Output() bootChange = new EventEmitter<Station>();
    @Output() resetResponseReceived = new EventEmitter<Station>();

    isBootDisabled: boolean = false;
    isBootNotSent: boolean = true;
    showShortSystemURL: boolean = true;
    stationStatus = '';
    successfulMessageReceived: Subscription;
    failureMessageReceived: Subscription;

    constructor(private stationService: StationService, public snackBar: MatSnackBar, private messageService: DataExchangeService,
                public dialog: MatDialog, private websocketService: WebsocketService) {

    }

    ngOnInit(): void {
        this.setStationStatus();
        this.successfulMessageReceived = this.websocketService.successfulMessageReceived$.subscribe(value => {
            this.handleReceivedMessage(value, true);
        });
        this.failureMessageReceived = this.websocketService.failureMessageReceived$.subscribe(value => {
            this.handleReceivedMessage(value, false);
        })
    }

    fetchStation(): void {
        if (this.getStationSubscription) {
            this.getStationSubscription.unsubscribe();
        }

        this.getStationSubscription = this.stationService.getStationByChargingStationIdentityKey(this.station.chargingStationIdentityKey).subscribe((data: Station) => {
            this.station = data;
            this.isBootNotSent = true;
            this.setStationStatus();
        }, error => {
            this.snackBar.open(error, "Dismiss", {
                duration: SNACKBAR_DURATION,
                panelClass: ['failure-snackbar']
            });
        });

    }

    sendStationChangedNotification(station: Station): void {
        this.messageService.announceStationChanged(station);
    }

    handleReceivedMessage(value: any, isSuccessful: boolean) {
        let webSocketMessageDTO: WebSocketMessageDTO = JSON.parse(value.payload);
        if (this.station.chargingStationIdentityKey == webSocketMessageDTO.chargingStationIdentityKey) {
            this.handleMessage(webSocketMessageDTO.message, isSuccessful);
        }
    }

    handleMessage(message: any, isSuccessful: boolean) {
        this.getStationSubscription = this.stationService.getStationByChargingStationIdentityKey(this.station.chargingStationIdentityKey).subscribe((data: Station) => {
            this.station = data;
            this.isBootNotSent = true;
            this.isBootDisabled = false;
            this.setStationStatus();
            this.sendStationChangedNotification(this.station);
        }, error => {
            console.log(error);
        });
        if (message !== '') {
            if (isSuccessful) {
                this.snackBar.open(message, "Dismiss", {
                    duration: SNACKBAR_DURATION,
                    panelClass: ['successful-snackbar']
                });
            } else {
                this.snackBar.open(message, "Dismiss", {
                    duration: SNACKBAR_DURATION,
                    panelClass: ['failure-snackbar']
                });
            }
        }
    }

    sendBootNotification(stationIdentityKey: string): void {
        if (this.sendBootNotificationRequest) {
            this.sendBootNotificationRequest.unsubscribe()
        }
        this.isBootDisabled = true;
        this.isBootNotSent = false;
        this.stationStatus = 'Unknown';

        // re-enable the button after 30s if no Boot response is received through the websocket
        setTimeout(() => {
            if (this.isBootDisabled) {
                this.isBootDisabled = false;
            }
            this.fetchStation();
        }, 30000);
        this.sendBootNotificationRequest = this.stationService.sendBootNotification(stationIdentityKey).subscribe({
            error: responseError => {
                console.log(responseError);
                this.snackBar.open('Boot notification request failed!', 'Dismiss', {
                    duration: SNACKBAR_DURATION,
                    panelClass: ['failure-snackbar']
                });
            }
        });
    }

    stopStation(chargingStationIdentityKey: string): void {
        if (this.stopStationSubscription) {
            this.stopStationSubscription.unsubscribe()
        }
        this.stopStationSubscription = this.stationService.stopStation(chargingStationIdentityKey).subscribe(() => {
                this.snackBar.open(
                    "Station was successfully stopped!", "Dismiss", {
                        duration: SNACKBAR_DURATION,
                        panelClass: ['successful-snackbar']
                    }
                )
            },
            error => {
                this.snackBar.open('An error occurred while stopping the station!', 'Dismiss', {
                    duration: SNACKBAR_DURATION,
                    panelClass: ['failure-snackbar']
                });
            });
    }

    getStatusColor(socket: Socket): string {
        if (socket.status in SOCKET_STATUS_COLORS) {
            return SOCKET_STATUS_COLORS[socket.status];
        } else {
            return '#000000';
        }
    }

    openDeleteDialog() {
        const dialogInterface: DialogInterface = {
            dialogHeader: 'Delete station',
            dialogContent: 'Are you sure you want to delete station ',
            cancelButtonLabel: 'Cancel',
            confirmButtonLabel: 'Yes',
            station: this.station,
            deleteStation: true
        };
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
            width: '300px',
            data: dialogInterface,
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.messageService.stationDeleted();
            }
        });
    }

    alterSystemURLText() {
        this.showShortSystemURL = !this.showShortSystemURL
    }

    copiedToClipboard(): void {
        this.snackBar.open('Copied to clipboard!', 'Dismiss', {
            duration: SNACKBAR_DURATION,
            panelClass: ['successful-snackbar']
        });
    }

    setStationStatus(): void {
        if (this.station.online) {
            if (this.station.accepted)
                this.stationStatus = 'Accepted';
            else {
                if (this.station.pending)
                    this.stationStatus = 'Pending';
                else
                    this.stationStatus = 'Rejected';
            }
        } else
            this.stationStatus = 'Unknown';
    }

    ngOnDestroy() {
        if (this.sendBootNotificationRequest) {
            this.sendBootNotificationRequest.unsubscribe();
        }
        if (this.getStationSubscription) {
            this.getStationSubscription.unsubscribe();
        }
        if (this.stopStationSubscription) {
            this.stopStationSubscription.unsubscribe();
        }
        if (this.stompClient !== null && this.stompClient !== undefined && this.stompClient === 'CONNECTED') {
            this.stompClient.disconnect();
        }
    }
}
